import PropTypes from "prop-types"
import React, { createContext, useContext, useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"

import {
  INITIAL_TAB_ORDER,
  INITIAL_TAB_ORDER_WITH_TEMPLATES,
  TABS,
  VALIDATED_FIELDS_BY_TAB,
} from "../../constants"
import { ContractsFormContext } from "../ContractsFormContext"
import {
  ContactAndBoat,
  Documents,
  Installments,
  Itinerary,
  PaymentMethods,
  Rate,
  Templates,
} from "../tabPanels"
import AddItems from "../tabPanels/AddItems"
import AdditionalSettings from "../tabPanels/AdditionalSettings"
import {
  AddItemsTab,
  ContactAndBoatTab,
  DocumentsTab,
  InstallmentsTab,
  ItineraryTab,
  PaymentMethodTab,
  RateTab,
  TemplatesTab,
} from "./tabs"

const getTabContent = (tab) => {
  switch (tab) {
    case TABS.installments:
      return {
        tab: <InstallmentsTab />,
        panel: <Installments />,
        validatedFields: VALIDATED_FIELDS_BY_TAB.installments,
      }
    case TABS.itinerary:
      return {
        tab: <ItineraryTab />,
        panel: <Itinerary />,
        validatedFields: VALIDATED_FIELDS_BY_TAB.itinerary,
      }
    case TABS.addItems:
      return {
        tab: <AddItemsTab />,
        panel: <AddItems />,
        validatedFields: VALIDATED_FIELDS_BY_TAB.addItems,
      }
    case TABS.documents:
      return {
        tab: <DocumentsTab />,
        panel: <Documents />,
        validatedFields: VALIDATED_FIELDS_BY_TAB.documents,
      }
    case TABS.rate:
      return {
        tab: <RateTab />,
        panel: <Rate />,
        validatedFields: VALIDATED_FIELDS_BY_TAB.rate,
      }
    case TABS.contactAndBoat:
      return {
        tab: <ContactAndBoatTab />,
        panel: <ContactAndBoat />,
        validatedFields: VALIDATED_FIELDS_BY_TAB.contactAndBoat,
      }
    case TABS.paymentMethod:
      return {
        tab: <PaymentMethodTab />,
        panel: <PaymentMethods />,
        validatedFields: VALIDATED_FIELDS_BY_TAB.paymentMethod,
      }
    case TABS.templatesDocuments:
      return {
        tab: <TemplatesTab />,
        panel: <Templates />,
        validatedFields: VALIDATED_FIELDS_BY_TAB.templatesDocuments,
      }
    case TABS.additionalSettings:
      return {
        tab: <div />,
        panel: <AdditionalSettings />,
        validatedFields: VALIDATED_FIELDS_BY_TAB.additionalSettings,
      }
  }
}

export const WizardContext = createContext({
  currentTabs: [],
  activeTab: TABS.itinerary,
  setCurrentTabs: () => {},
  setActiveTab: () => {},
  getTabContent: () => {},
})

export const WizardContextProvider = (props) => {
  const { watch } = useFormContext()
  const [billingCycle, isGroup] = watch(["billingCycle", "isGroup"])

  const { chargesEnabled, contractSigningUpdatesEnabled, withTemplate } =
    useContext(ContractsFormContext)
  const [currentTabs, setCurrentTabs] = useState(() => {
    let initialTabs =
      contractSigningUpdatesEnabled && withTemplate
        ? INITIAL_TAB_ORDER_WITH_TEMPLATES
        : INITIAL_TAB_ORDER
    if (!chargesEnabled && initialTabs.includes(TABS.addItems)) {
      initialTabs = initialTabs.filter((tab) => tab !== TABS.addItems)
    }
    if (!isGroup) {
      initialTabs = [TABS.contactAndBoat, ...initialTabs]
    }
    return initialTabs
  })
  const [activeTab, setActiveTab] = useState(
    isGroup ? TABS.itinerary : TABS.contactAndBoat
  )

  // adding/removing installments tab based on contract type
  useEffect(() => {
    if (
      billingCycle !== "month_to_month" &&
      !currentTabs.includes(TABS.installments)
    ) {
      // installments tab comes after rate
      const indexOfRateTab = currentTabs.indexOf(TABS.rate)
      const updatedTabs = [...currentTabs]
      updatedTabs.splice(indexOfRateTab + 1, 0, TABS.installments)
      setCurrentTabs(updatedTabs)
    } else if (
      billingCycle === "month_to_month" &&
      currentTabs.includes(TABS.installments)
    ) {
      setCurrentTabs((current) =>
        current.filter((tab) => tab !== TABS.installments)
      )
    }
  }, [billingCycle, currentTabs, setCurrentTabs])

  return (
    <WizardContext.Provider
      value={{
        currentTabs,
        setCurrentTabs,
        activeTab,
        setActiveTab,
        getTabContent,
      }}
    >
      {props.children}
    </WizardContext.Provider>
  )
}

WizardContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export default WizardContextProvider
