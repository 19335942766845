import { Transition } from "@headlessui/react"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useMutation } from "react-query"

import {
  createPaymentMethod,
  deletePaymentMethod,
} from "../../../api/Account/PaymentMethods"
import Button from "../../../components/Button"
import useStripeSetup from "../../../hooks/payment_method_hooks/use_stripe_setup"
import { useToast } from "../../../hooks/use_toast"
import { useTracker } from "../../../hooks/use_tracker"
import AddPaymentMethodModal from "./AddPaymentMethodModal"
import EmptyStateImage from "./EmptyStateImage"
import PaymentMethod from "./PaymentMethod"

const PaymentMethodsContainer = ({ accountPageRefresh, paymentMethods }) => {
  const showToast = useToast()
  const tracker = useTracker()
  const { paymentMethod: newPaymentMethod, error } =
    useStripeSetup(createPaymentMethod)

  const [modalOpen, setModalOpen] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const [currentPaymentMethods, setPaymentMethods] = useState(
    paymentMethods.map((paymentMethod) => ({
      ...paymentMethod,
      deleted: false,
    }))
  )

  const noPaymentMethods = useMemo(() => {
    return currentPaymentMethods.every((paymentMethod) => paymentMethod.deleted)
  }, [currentPaymentMethods])

  const handleError = useCallback(
    (error) => {
      showToast(error.message, { type: "error" })
    },
    [showToast]
  )

  useEffect(() => {
    if (newPaymentMethod) {
      setPaymentMethods((currentPaymentMethods) => [
        ...currentPaymentMethods,
        { ...newPaymentMethod, deleted: false },
      ])

      tracker.trackEvent(
        "account_payment_payment_methods:payment_method_added",
        {
          payment_method_type: newPaymentMethod.type,
        }
      )

      showToast("Payment method successfully added", { type: "success" })
    }
  }, [newPaymentMethod, showToast, tracker])

  useEffect(() => {
    if (error) {
      showToast(error.message, { type: "error" })
    }
  }, [error, showToast])

  const { mutateAsync: deleteMutation } = useMutation((paymentMethodId) =>
    deletePaymentMethod(paymentMethodId)
  )

  const handleCloseModal = () => setModalOpen(false)

  const handleAddPaymentMethod = (event) => {
    event.preventDefault()

    tracker.trackEvent(
      "account_payment_payment_methods:add_new_payment_method_pressed"
    )

    setModalOpen(true)
  }

  const handleDelete = (paymentMethodId) => {
    // Optimistically remove the payment method from the list, we will add it back if the delete fails
    setPaymentMethods(
      currentPaymentMethods.map((paymentMethod) => {
        if (paymentMethod.id === paymentMethodId) {
          return { ...paymentMethod, deleted: true }
        }
        return paymentMethod
      })
    )

    deleteMutation(paymentMethodId)
      .then(() => {
        // We're all good here, inform the user
        showToast("Payment method successfully deleted", { type: "success" })
      })
      .catch((error) => {
        // Whoops, something went wrong, add the payment method back to the list
        setPaymentMethods(
          currentPaymentMethods.map((paymentMethod) => {
            if (paymentMethod.id === paymentMethodId) {
              return { ...paymentMethod, deleted: false }
            }
            return paymentMethod
          })
        )

        // Inform the user
        handleError(error)
      })
  }

  const confirmDelete = (paymentMethodId) => {
    if (
      window.confirm("Are you sure you want to delete this payment method?")
    ) {
      handleDelete(paymentMethodId)
    }
  }

  return (
    <div className="p-2 md:p-0">
      <AddPaymentMethodModal
        isOpen={modalOpen}
        onClose={handleCloseModal}
        setLoading={setModalLoading}
      />
      {!accountPageRefresh && (
        <h3 className="mb-4 text-xl text-gray-700">Payment Methods</h3>
      )}
      <Transition
        enter="transition-opacity duration-300"
        leave="transition-opacity duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        show={noPaymentMethods}
      >
        <div className="mt-6 flex flex-col items-center">
          <EmptyStateImage />
          <h3 className="mb-0 text-xl text-gray-800">No Payment Methods Yet</h3>
          <span className="block text-sm text-gray-800">
            Add one to get started.
          </span>
        </div>
      </Transition>
      <div className="mb-8 grid grid-cols-1 gap-y-4">
        {currentPaymentMethods.map((paymentMethod) => (
          <Transition
            leave="transition-opacity duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            show={!paymentMethod.deleted}
            key={paymentMethod.id}
          >
            <PaymentMethod
              paymentMethod={paymentMethod}
              onDelete={() => confirmDelete(paymentMethod.id)}
              key={paymentMethod.id}
            />
          </Transition>
        ))}
      </div>
      <div className="flex w-full items-center">
        <Button
          type="button"
          variant="primary"
          onClick={handleAddPaymentMethod}
          isLoading={modalLoading}
          disabled={modalOpen}
        >
          Add New Payment Method
        </Button>
      </div>
    </div>
  )
}

PaymentMethodsContainer.propTypes = {
  accountPageRefresh: PropTypes.bool,
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      isEditable: PropTypes.bool.isRequired,
      stripePaymentMethodId: PropTypes.string,
      stripeFingerprintId: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      logo: PropTypes.string,
      reservationCount: PropTypes.number.isRequired,
      status: PropTypes.oneOf([
        "initialized",
        "requires_confirmation",
        "requires_action",
        "requires_reauthorization",
        "processing",
        "succeeded",
        "failed",
        "blocked",
      ]),
    })
  ).isRequired,
}

export default PaymentMethodsContainer
