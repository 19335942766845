import React from "react"
import { createPortal } from "react-dom"
import ReactDOM from "react-dom/client"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"

import ToastContainer from "../components/Toast/ToastContainer"
import { ToastProvider } from "../components/Toast/ToastContext"
import { automountableComponents } from "./automountable-components"

export function automountReactElements() {
  console.log(`module React v${React.version} loaded`) // eslint-disable-line no-console

  const renderReactPortalComponents = () => {
    // Get all tags generated by the `react_component_v2` helper.
    const mountingElements = document.querySelectorAll("[data-react-component]")

    return Array.from(mountingElements).map((mountingElement) => {
      const { name, props = {} } = JSON.parse(
        mountingElement.getAttribute("data-react-component")
      )
      const Component = automountableComponents[name]
      if (!Component) {
        return console.log(`Component ${name} not found`) // eslint-disable-line no-console
      } else {
        return createPortal(<Component {...props} />, mountingElement)
      }
    })
  }

  const renderReactLayout = () => {
    const main = document.getElementById("yield")
    const layoutRoot = document.createElement("div")
    main.appendChild(layoutRoot)

    const queryClient = new QueryClient()

    ReactDOM.createRoot(layoutRoot).render(
      <ToastProvider>
        <QueryClientProvider client={queryClient}>
          {renderReactPortalComponents()}
          <div className="relative flex justify-center">
            <ToastContainer />
          </div>
          <ReactQueryDevtools position="bottom-right" />
        </QueryClientProvider>
      </ToastProvider>
    )
  }

  document.addEventListener("DOMContentLoaded", renderReactLayout, {
    once: true,
  })
}
