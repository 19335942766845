import classNames from "classnames"
import { differenceInCalendarDays, format, isDate, subDays } from "date-fns"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useRef, useState } from "react"
import { Controller, useForm } from "react-hook-form"
import { useInfiniteQuery, useMutation } from "react-query"
import { getPricePerUnitPrecision } from "src/main/Billing/Items/helpers"

import Button from "src/components/Button"
import Divider from "src/components/Divider"
import Form from "src/components/Form"
import Modal from "src/components/Modal"
import ReloadableWidget from "src/components/ReloadableWidget"

import {
  createRecurringProductSale,
  createReservationTxn,
  queryProducts,
} from "src/api/Billing/Items"

import { snakecaseToTitlecase } from "src/utils/string_helpers"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import DueDayDropdown from "../../DueDayDropdown"
import PaymentMethodDropdown from "../../PaymentMethodDropdown"
import {
  DEFAULT_ONE_TIME_PRICING_STRUCTURE,
  DEFAULT_RECURRING_PRICING_STRUCTURE,
} from "../../constants"
import { parseDateValue } from "../../helpers"
import PaymentScheduleDropdown from "../PaymentScheduleDropdown"
import {
  calculateQuantityIncludingMeteredElectric,
  getDueDate,
  getPriceAndTaxAmounts,
  pricePerUnitToDollars,
  toTaxPercent,
} from "../helpers"
import DateField from "./fields/DateField"
import { ItemPricingFields } from "./fields/ItemPricingFields"
import MeterField from "./fields/MeterField"
import MeterReadingFields from "./fields/MeterReadingFields"
import RecurringEndDateField from "./fields/RecurringEndDateField"
import RecurringStartDateField from "./fields/RecurringStartDateField"

const RESERVATION_EXISTS_PRODUCT_CATEGORIES = [
  "metered_electric",
  "long_term_storage",
]
const NON_RECURRING_PRODUCT_CATEGORIES = [
  "metered_electric",
  "long_term_storage",
]

const RESERVATION_DATES_PRICING_STRUCTURE = "per_day"
const PERCENT_OF_STORAGE_PRICING_STRUCTURE = "percent_of_reservation_sale"

const DEFAULT_PAYMENT_METHODS = { cards: [], default: { id: null } }

const AddItemModal = ({
  ledgerId,
  contractEndDate,
  contractStartDate,
  contractQuoteId,
  reservationId,
  paymentMethods,
  onItemAdded,
  isOpen,
  onClose,
  onSubmit,
  initialValues,
  isMonthToMonth,
  hasScheduledInvoice,
  reservationCheckInDate,
  reservationCheckOutDate,
  reservationSale,
}) => {
  const bodyRef = useRef()
  const [expandAdvancedOptions, setExpandAdvancedOptions] = useState(false)
  const [paymentScheduleDisabled, setPaymentScheduleDisabled] = useState(false)

  const isReservationView = reservationId && !contractQuoteId
  const canShowPercentOfStorageType = !isReservationView

  const marinaSlug = getCurrentMarinaSlug()
  const excludedProductCategories = reservationId
    ? []
    : RESERVATION_EXISTS_PRODUCT_CATEGORIES

  const {
    isFetching: isFetchingProducts,
    isLoading: isLoadingProducts,
    isError: isErrorProducts,
    data: productsData,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useInfiniteQuery({
    queryKey: ["products", marinaSlug],
    queryFn: ({ pageParam = 1 }) =>
      queryProducts({
        page: pageParam,
        marinaSlug,
        excludedCategories: excludedProductCategories,
        availability: ["contract_reservation_add_ons"],
      }),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.length) {
        return pages.length + 1
      }
    },
    refetchOnWindowFocus: false,
  })

  const products = useMemo(
    () => (productsData?.pages || []).flatMap((page) => page),
    [productsData]
  )

  const { cards: paymentCards, default: defaultPaymentMethod } =
    paymentMethods ?? DEFAULT_PAYMENT_METHODS

  const defaultPaymentMethodId = paymentMethods
    ? defaultPaymentMethod?.id || "manual"
    : null

  const defaultPaymentSchedule = hasScheduledInvoice
    ? "add_to_next_payment"
    : "custom_due_date"

  const defaultServiceStart =
    contractStartDate && !reservationId ? contractStartDate : new Date()

  const defaultServiceEnd = () => {
    if (reservationId) {
      return new Date()
    } else if (isMonthToMonth && contractStartDate) {
      return contractStartDate
    } else if (!isMonthToMonth && contractEndDate) {
      return contractEndDate
    } else {
      return new Date()
    }
  }

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
    resetField,
    watch,
    setValue,
    clearErrors,
  } = useForm({
    shouldUnregister: true,
    defaultValues: {
      payment_method_id: defaultPaymentMethodId,
      payment_schedule: defaultPaymentSchedule,
      recurring_product: "no",
      recurring_end_selection: isMonthToMonth ? "never" : "onDate",
      start_date: new Date(),
      end_date: new Date(),
      monthly_due_date: 1,
      custom_due_date: new Date(),
      ...initialValues,
      txn: {
        product_sale_attributes: {
          pricing_structure: DEFAULT_ONE_TIME_PRICING_STRUCTURE,
          name: null,
          product_id: "",
          price_per_unit: null,
          quantity: 1,
          tax_percent: null,
          service_start_date: defaultServiceStart,
          service_end_date: defaultServiceEnd(),
          external_sale: false,
          ...(paymentMethods ? {} : { request_external_settlement: false }),
          ...(initialValues?.txn?.product_sale_attributes
            ? initialValues.txn.product_sale_attributes
            : {}),
        },
      },
    },
  })

  // Registering txn.product_sale_attributes.name here so it can be set when the product ID input is changed
  register("txn.product_sale_attributes.name")
  register("payment_schedule")

  const [
    recurringProduct,
    priceType,
    serviceStartDate,
    serviceEndDate,
    startDate,
    endDate,
    paymentSchedule,
    productId,
    precision,
    recurringEndSelection,
    lastMeterReading,
    dateType,
  ] = watch([
    "recurring_product",
    "txn.product_sale_attributes.pricing_structure",
    "txn.product_sale_attributes.service_start_date",
    "txn.product_sale_attributes.service_end_date",
    "start_date",
    "end_date",
    "payment_schedule",
    "txn.product_sale_attributes.product_id",
    "txn.product_sale_attributes.price_precision",
    "recurring_end_selection",
    "metered_electric.last_meter_reading",
    "dateType",
  ])

  const { mutate: mutateProductSale, isLoading: isLoadingMutateProductSale } =
    useMutation({
      queryKey: ["productSaleTxn", reservationId],
      mutationFn: (data) =>
        createReservationTxn({ marinaSlug, reservationId, data }),
      onSuccess: () => {
        onItemAdded()
        reset()
      },
      onError: () => {
        console.log("createReservationTxn error")
      },
    })

  const {
    mutate: mutateRecurringProductSale,
    isLoading: isLoadingMutateRecurringProductSale,
  } = useMutation({
    queryKey: ["recurringProductSale", contractQuoteId],
    mutationFn: (data) => createRecurringProductSale({ data }),
    onSuccess: () => {
      onItemAdded()
      reset()
    },
    onError: () => {
      console.log("createRecurringProductSale error")
    },
  })

  const submitForm = (data) => {
    recurringProduct === "yes"
      ? submitRecurringProductSale(data)
      : submitProductSale(data)
  }

  const submitRecurringProductSale = (data) => {
    const { end_date: endDate } = data
    const { originalAmount, taxAmount, taxRate, amount, pricePerUnit } =
      getPriceAndTaxAmounts(data)
    const requestData = {
      manage_id: marinaSlug,
      payment_method_id: data.payment_method_id,
      recurring_product_sale: {
        ledger_id: ledgerId,
        product_id: data.txn.product_sale_attributes.product_id,
        reservation_id: reservationId,
        due_day: data.monthly_due_date,
        start_date: format(data.start_date, "yyyy-MM-dd"),
        end_date:
          recurringEndSelection === "never"
            ? null
            : format(endDate, "yyyy-MM-dd"),
        name: data.txn.product_sale_attributes.name,
        amount,
        original_amount: originalAmount,
        tax_amount: taxAmount,
        tax_rate: taxRate,
        quantity: data.txn.product_sale_attributes.quantity,
        price_per_unit: pricePerUnit,
        price_precision: data.txn.product_sale_attributes.price_precision,
        pricing_structure: DEFAULT_RECURRING_PRICING_STRUCTURE,
      },
    }
    if (onSubmit) {
      onSubmit(requestData)
      onModalClose()
      return
    }
    mutateRecurringProductSale(requestData)
  }

  const submitProductSale = (data) => {
    const {
      payment_schedule: paymentSchedule,
      custom_due_date: customDueDate,
      txn: {
        product_sale_attributes: {
          service_start_date: serviceStartDate,
          service_end_date: serviceEndDate,
          pricing_structure: pricingStructure,
        },
      },
      dateType,
    } = data

    const serviceStart =
      serviceStartDate || parseDateValue(reservationCheckInDate)
    const serviceEnd =
      serviceEndDate || subDays(parseDateValue(reservationCheckOutDate), 1)

    const quantity = calculateQuantityIncludingMeteredElectric(data)
    const dueDate = getDueDate({ paymentSchedule, customDueDate })
    const parsedDueDate = isDate(dueDate)
      ? format(dueDate, "yyyy-MM-dd")
      : dueDate

    const isPerDay =
      data.txn.product_sale_attributes.pricing_structure ===
      RESERVATION_DATES_PRICING_STRUCTURE
    const isPercentOfReservationSale =
      data.txn.product_sale_attributes.pricing_structure ===
      PERCENT_OF_STORAGE_PRICING_STRUCTURE

    const { originalAmount, taxAmount, taxRate, amount, pricePerUnit } =
      getPriceAndTaxAmounts(
        data,
        isPerDay
          ? differenceInCalendarDays(serviceEnd, serviceStart) + 1
          : quantity,
        reservationSale
      )
    const meteredElectric = isMeteredElectricProduct
      ? {
          metered_electric_attributes: {
            meter_id: data.metered_electric.meter,
            reading: data.metered_electric.current_meter_reading,
            read_at: new Date(),
          },
        }
      : {}

    const pricePrecision = isPercentOfReservationSale
      ? "cents"
      : data.txn.product_sale_attributes.price_precision

    const requestData = {
      manage_id: marinaSlug,
      txn: {
        type: "Billing::ProductSaleTxn",
        amount,
        billing_ledger_id: ledgerId,
        product_sale_attributes: {
          original_amount: originalAmount,
          tax_amount: taxAmount,
          price_per_unit: pricePerUnit,
          price_precision: pricePrecision,
          reservation_id: reservationId,
          tax_rate: taxRate,
          pricing_structure:
            pricingStructure ?? DEFAULT_ONE_TIME_PRICING_STRUCTURE,
          product_id: data.txn.product_sale_attributes.product_id,
          service_start_date: format(serviceStart, "yyyy-MM-dd"),
          service_end_date: format(serviceEnd, "yyyy-MM-dd"),
          external_sale: data.txn.product_sale_attributes.external_sale,
          request_external_settlement:
            data.txn.product_sale_attributes.request_external_settlement,
          name: data.txn.product_sale_attributes.name,
          quantity: quantity ?? 1,
          reservation_dates: isPerDay && dateType === "wholeReservation",
          reservation_sale: false,
          ...meteredElectric,
        },
      },
      invoices: [
        {
          amount,
          payment_method_id: data.payment_method_id,
          due_date: parsedDueDate,
          due_on_signature: paymentSchedule === "due_on_signature",
          add_to_installments: paymentSchedule === "add_to_installments",
        },
      ],
    }

    if (onSubmit) {
      onSubmit(requestData)
      onModalClose()
      return
    }
    mutateProductSale(requestData)
  }

  const onProductIdChange = ({ target: { value } }) => {
    const {
      category,
      name,
      price_per_unit: pricePerUnit,
      price_precision: pricePrecision,
      tax_rate: taxRate,
    } = products.find(({ id }) => id === value)
    const unitPricing = priceType !== PERCENT_OF_STORAGE_PRICING_STRUCTURE
    const pricePerUnitInDollarsString =
      unitPricing && pricePerUnit
        ? pricePerUnitToDollars({
            pricePerUnit,
            precision: pricePrecision,
          }).toFixed(getPricePerUnitPrecision(pricePrecision))
        : null
    const taxPercent = taxRate ? toTaxPercent(taxRate) : 0

    setValue("txn.product_sale_attributes.name", name)
    setValue(
      "txn.product_sale_attributes.price_per_unit",
      pricePerUnitInDollarsString
    )
    setValue("txn.product_sale_attributes.price_precision", pricePrecision)
    setValue("txn.product_sale_attributes.tax_percent", taxPercent)

    if (
      recurringProduct &&
      NON_RECURRING_PRODUCT_CATEGORIES.includes(category)
    ) {
      setValue("recurring_product", "no")
    }
  }

  const onRecurringProductChange = ({ target: { value } }) => {
    if (value === "yes") {
      setValue("payment_schedule", "")
      setExpandAdvancedOptions(false)
    } else {
      resetField("payment_schedule")
    }
  }

  useEffect(() => {
    if (
      dateType === "wholeReservation" &&
      !["reservation_check_in", "reservation_check_out"].includes(
        paymentSchedule
      )
    ) {
      setValue("payment_schedule", "reservation_check_in")
    }
  }, [dateType, setValue, paymentSchedule])

  useEffect(() => {
    // helps reinitialize fields that were previously hidden
    if (dateType === "custom") {
      resetField("txn.product_sale_attributes.service_start_date")
      resetField("txn.product_sale_attributes.service_end_date")
    }
  }, [dateType, resetField])

  const onPricingStructureChange = ({ target: { value } }) => {
    clearErrors("txn.product_sale_attributes.quantity")
    if (value === DEFAULT_ONE_TIME_PRICING_STRUCTURE) {
      resetField("payment_schedule")
      resetField("txn.product_sale_attributes.service_start_date")
      resetField("txn.product_sale_attributes.service_end_date")
    } else if (value === PERCENT_OF_STORAGE_PRICING_STRUCTURE) {
      setValue("txn.product_sale_attributes.quantity", null)
      setValue("txn.product_sale_attributes.price_per_unit", null)
      setValue("recurring_product", "no")
    } else {
      setValue("payment_schedule", "reservation_check_in")
      setValue("dateType", "wholeReservation")
    }
  }

  const onModalClose = () => {
    onClose()
    setExpandAdvancedOptions(false)
    setPaymentScheduleDisabled(false)
  }

  const isRecurring = recurringProduct === "yes"
  const isNonRecurringProduct = NON_RECURRING_PRODUCT_CATEGORIES.includes(
    products.find((product) => product.id === productId)?.category
  )
  const isMeteredElectricProduct =
    products.find((product) => product.id === productId)?.category ===
    "metered_electric"
  const isNotReservationOrIsNotPerDay =
    priceType !== RESERVATION_DATES_PRICING_STRUCTURE || !reservationId

  useEffect(() => {
    if (isMeteredElectricProduct) {
      resetField("payment_schedule")
    }
  }, [isMeteredElectricProduct, resetField, setValue])

  const productByCategory = useMemo(() => {
    return products.reduce((accum, product) => {
      const category = product.product_category?.display_name || "Uncategorized"
      if (!accum[category]) {
        return {
          ...accum,
          [category]: [product],
        }
      }
      accum[category].push(product)
      return accum
    }, {})
  }, [products])

  useEffect(() => {
    if (!isFetchingNextPage && hasNextPage) {
      fetchNextPage()
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage])

  // when the advanced options are open, scroll to the bottom of the modal
  useEffect(() => {
    if (expandAdvancedOptions) {
      bodyRef.current.scrollTo(0, bodyRef.current.scrollHeight)
    }
  }, [expandAdvancedOptions])

  const renderRecurrence = () => {
    if (isNotReservationOrIsNotPerDay) {
      return (
        <div className="flex w-1/2 flex-col">
          <Form.Label htmlFor="recurring-product">Recurrence</Form.Label>
          <Form.Select
            id="recurring-product"
            disabled={priceType === PERCENT_OF_STORAGE_PRICING_STRUCTURE}
            {...register("recurring_product", {
              onChange: onRecurringProductChange,
            })}
          >
            <option key="no" value="no">
              Does not repeat
            </option>
            <option key="yes" value="yes" disabled={isNonRecurringProduct}>
              Repeats monthly
            </option>
          </Form.Select>
        </div>
      )
    }
  }

  const renderMonthlyDueDate = () => {
    return (
      <div className="flex w-1/2 flex-col">
        <DueDayDropdown
          name="monthly_due_date"
          register={register}
          labelText="Monthly due date"
          errors={errors.monthly_due_date}
        />
      </div>
    )
  }

  const renderRecurringStart = () => {
    return (
      <div className="flex w-1/2 flex-col">
        <Form.Label htmlFor="starts-selection">Starts</Form.Label>
        <div className="flex flex-col gap-4">
          <Form.Select id="starts-selection" value={"onDate"} disabled>
            <option key="onDate" value="onDate">
              On Custom Date
            </option>
          </Form.Select>
          <RecurringStartDateField
            endDate={endDate}
            control={control}
            errors={errors}
          />
        </div>
      </div>
    )
  }

  const renderRecurringEnd = () => {
    return (
      <div className="flex w-1/2 flex-col">
        <Form.Label htmlFor="ends-selection">Ends</Form.Label>
        <div className="flex flex-col gap-4">
          <Form.Select
            id="ends-selection"
            disabled={!isMonthToMonth}
            name="recurring_end_selection"
            {...register("recurring_end_selection")}
          >
            {isMonthToMonth ? (
              <option key="never" value="never">
                Never
              </option>
            ) : null}
            <option key="onDate" value="onDate">
              On Custom Date
            </option>
          </Form.Select>
          {recurringEndSelection === "onDate" ? (
            <RecurringEndDateField
              control={control}
              errors={errors}
              startDate={startDate}
            />
          ) : null}
        </div>
      </div>
    )
  }

  const renderRecurringDates = () => {
    return (
      <div className="flex flex-row gap-4">
        {renderRecurringStart()}
        {renderRecurringEnd()}
      </div>
    )
  }

  const renderServiceDates = () => {
    if (isNotReservationOrIsNotPerDay) {
      return (
        <DateField
          control={control}
          errors={errors}
          endDate={serviceEndDate}
          startDate={serviceStartDate}
        />
      )
    }
  }

  const renderPaymentSchedule = () => {
    return (
      <PaymentScheduleDropdown
        register={register}
        errors={errors}
        disabled={paymentScheduleDisabled}
        hasScheduledInvoice={hasScheduledInvoice}
        hasReservation={!!reservationId}
        priceType={priceType}
        dateType={dateType}
        isMonthToMonth={isMonthToMonth}
      />
    )
  }

  const renderPaymentMethod = () => {
    return (
      <PaymentMethodDropdown
        name="payment_method_id"
        disabled={!paymentMethods}
        onlineMethods={paymentCards}
        errors={errors.payment_method_id}
        registerOptions={
          paymentMethods ? { required: "Payment method is required." } : {}
        }
        control={control}
      />
    )
  }

  const renderCustomDueDateFields = () => {
    if (paymentSchedule !== "custom_due_date") {
      return null
    }

    return (
      <div className="flex flex-row gap-4">
        <div className="flex w-1/2 flex-col">
          {reservationId ? renderPaymentMethod() : null}
        </div>
        <div className="flex w-1/2 flex-col">
          <Form.Label htmlFor="custom-due-date">Due date</Form.Label>
          <Controller
            name="custom_due_date"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Form.DatePicker
                id="custom-due-date"
                renderCustomHeader={(props) => (
                  <Form.DatePicker.QuickNavHeader {...props} />
                )}
                {...{ onChange, value }}
                hasErrors={Boolean(errors.custom_due_date)}
              />
            )}
            rules={{
              required: "Custom due date is required",
            }}
          />
          {errors.custom_due_date && (
            <Form.Error>{errors.custom_due_date.message}</Form.Error>
          )}
        </div>
      </div>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onModalClose}
      afterLeave={reset}
      size="mediumFixed"
    >
      <Modal.Header>
        <div>
          <h4 className="m-0 mb-2 text-2xl font-semibold">
            {initialValues ? "Edit" : "Add"} item
          </h4>
          <div className="text-lg">
            <span>Visit </span>
            <a href={`/manage/${marinaSlug}/sales/edit`}>
              {"Sales > Edit Items"}
            </a>
            <span> to create new items</span>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body ref={bodyRef}>
        <Form>
          <ReloadableWidget
            isLoading={
              isLoadingProducts || isFetchingProducts || hasNextPage !== false
            }
            isError={isErrorProducts}
            showOverlay
          >
            <div className="flex flex-col">
              <Form.Label htmlFor="product-id">Product type</Form.Label>
              <Form.Select
                id="product-id"
                {...register("txn.product_sale_attributes.product_id", {
                  required: "Item is required.",
                  onChange: onProductIdChange,
                })}
                hasErrors={Boolean(
                  errors.txn?.product_sale_attributes?.product_id
                )}
              >
                <option disabled value="">
                  Select
                </option>
                {Object.keys(productByCategory).map((category) => {
                  return (
                    <optgroup
                      label={snakecaseToTitlecase(category)}
                      key={category}
                    >
                      {productByCategory[category].map(
                        ({ id, name, is_inventory: isInventory }) => (
                          <option key={id} value={id}>
                            {`${name}${isInventory ? " (inventory item)" : ""}`}
                          </option>
                        )
                      )}
                    </optgroup>
                  )
                })}
              </Form.Select>
              <input
                {...register("txn.product_sale_attributes.price_precision")}
                type="hidden"
              />
              {errors.txn?.product_sale_attributes?.product_id && (
                <Form.Error>
                  {errors.txn.product_sale_attributes.product_id.message}
                </Form.Error>
              )}
            </div>
            {isMeteredElectricProduct && (
              <div className="mt-5">
                <MeterField
                  marinaSlug={marinaSlug}
                  reservationId={reservationId}
                  register={register}
                  setValue={setValue}
                />
              </div>
            )}
            <ItemPricingFields
              errors={errors}
              precision={precision}
              register={register}
              showQuantity={
                !isMeteredElectricProduct &&
                priceType === DEFAULT_ONE_TIME_PRICING_STRUCTURE
              }
              priceType={priceType}
            />
            {isMeteredElectricProduct && (
              <div className="pt-5">
                <MeterReadingFields
                  errors={errors}
                  lastMeterReading={lastMeterReading}
                  register={register}
                />
              </div>
            )}
            <Divider />
            {dateType === "custom" ? (
              <div className="mb-4 flex flex-row gap-4">
                <DateField
                  control={control}
                  errors={errors}
                  endDate={serviceEndDate}
                  startDate={serviceStartDate}
                  showSelector={false}
                  startLabel="Start date"
                  endLabel="End date"
                />
              </div>
            ) : null}
            <div className="flex flex-col gap-4">
              <div className="flex flex-row gap-4">
                {renderRecurrence()}
                {isRecurring ? renderMonthlyDueDate() : renderPaymentSchedule()}
              </div>
              {isRecurring
                ? renderRecurringDates()
                : renderCustomDueDateFields()}
            </div>
            {isRecurring ? null : (
              <>
                <Divider />
                <div className="flex flex-row gap-4">
                  {renderServiceDates()}
                </div>
              </>
            )}
            {recurringProduct !== "yes" && (
              <h5
                className="my-5 text-lg font-semibold"
                onClick={() => setExpandAdvancedOptions(!expandAdvancedOptions)}
              >
                Advanced options
                {expandAdvancedOptions ? (
                  <i className="icon icon-angle-up ml-2 text-xs" />
                ) : (
                  <i className="icon icon-angle-down ml-2 text-xs" />
                )}
              </h5>
            )}
            {/* ADVANCED OPTIONS BELOW */}
            <div
              className={classNames(
                "max-h-0 overflow-hidden transition-[max-height] duration-300 ease-in-out",
                {
                  "max-h-80": expandAdvancedOptions,
                }
              )}
            >
              {!isMeteredElectricProduct ? (
                <div className="flex w-full flex-col pb-4">
                  <Form.Label htmlFor="pricing-structure">
                    Price type
                  </Form.Label>
                  <Form.Select
                    id="pricing-structure"
                    {...register(
                      "txn.product_sale_attributes.pricing_structure",
                      {
                        onChange: onPricingStructureChange,
                      }
                    )}
                    hasErrors={Boolean(
                      errors?.txn?.product_sale_attributes?.pricing_structure
                    )}
                  >
                    <option
                      key="per-unit"
                      value={DEFAULT_ONE_TIME_PRICING_STRUCTURE}
                    >
                      Per unit
                    </option>
                    {reservationId && (
                      <option
                        key="per-day"
                        value={RESERVATION_DATES_PRICING_STRUCTURE}
                      >
                        Per day
                      </option>
                    )}
                    {canShowPercentOfStorageType && (
                      <option
                        key="percent-of-storage"
                        value={PERCENT_OF_STORAGE_PRICING_STRUCTURE}
                        disabled={
                          isMonthToMonth || (reservationId && !reservationSale)
                        }
                      >
                        Percent of storage
                      </option>
                    )}
                  </Form.Select>
                  {errors?.txn?.product_sale_attributes?.pricing_structure && (
                    <Form.Error>
                      {
                        errors.txn.product_sale_attributes.pricing_structure
                          .message
                      }
                    </Form.Error>
                  )}
                </div>
              ) : null}
              <div className="flex flex-row">
                <Form.Checkbox
                  {...register("txn.product_sale_attributes.external_sale")}
                  label="This is an external sale"
                  id="external-sale"
                />

                {/* Only available via contract group and quote, not on a completed
                    contract / reservation. Currently that's determined by the presence of
                    paymentMethods */}
                {!paymentMethods && (
                  <Form.Checkbox
                    {...register(
                      "txn.product_sale_attributes.request_external_settlement"
                    )}
                    label="Settle via external transaction"
                    id="request-external-settlement"
                  />
                )}
              </div>
            </div>
          </ReloadableWidget>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <div className="flex justify-end space-x-2">
          <Button variant="tertiary" onClick={onModalClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            variant="primary"
            isLoading={
              isLoadingMutateProductSale || isLoadingMutateRecurringProductSale
            }
            disabled={
              isLoadingMutateProductSale ||
              isLoadingMutateRecurringProductSale ||
              hasNextPage ||
              isFetchingProducts
            }
            onClick={handleSubmit(submitForm)}
          >
            {initialValues ? "Edit" : "Add"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

const addItemsFormShape = PropTypes.shape({
  payment_method_id: PropTypes.number,
  payment_schedule: PropTypes.string,
  recurring_product: PropTypes.string,
  start_date: PropTypes.object,
  end_date: PropTypes.object,
  recurring_end_selection: PropTypes.string,
  monthly_due_date: PropTypes.number,
  custom_due_date: PropTypes.object,
  txn: PropTypes.shape({
    product_sale_attributes: PropTypes.shape({
      name: PropTypes.string,
      product_id: PropTypes.string,
      price_per_unit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      quantity: PropTypes.number,
      tax_percent: PropTypes.number,
      service_start_date: PropTypes.object,
      service_end_date: PropTypes.object,
      external_sale: PropTypes.bool,
      requestExternalSettlement: PropTypes.bool,
    }),
  }),
})

AddItemModal.propTypes = {
  ledgerId: PropTypes.string,
  contractEndDate: PropTypes.instanceOf(Date),
  contractStartDate: PropTypes.instanceOf(Date),
  contractQuoteId: PropTypes.number,
  reservationId: PropTypes.number,
  paymentMethods: PropTypes.shape({
    default: PropTypes.shape({
      id: PropTypes.number,
    }),
    cards: PropTypes.array,
  }),
  onItemAdded: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  initialValues: addItemsFormShape,
  isMonthToMonth: PropTypes.bool.isRequired,
  hasScheduledInvoice: PropTypes.bool,
  reservationCheckInDate: PropTypes.string,
  reservationCheckOutDate: PropTypes.string,
  reservationSale: PropTypes.shape({
    product_sale: PropTypes.shape({
      original_amount: PropTypes.number,
      discount_amount: PropTypes.number,
    }),
  }),
}

export default AddItemModal
