import PropTypes from "prop-types"
import React, { createContext, useState } from "react"
import Inquiries from "src/main/Inquiries/Inquiries"

import { inchesToFeet } from "src/utils/UnitConversion"
import { snakecaseToCamelCase } from "src/utils/string_helpers"

export const ManageInquiriesContext = createContext(null)
const refreshRate = 300 * 1000 // 5 minutes

const ManageInquiries = ({
  archivedContractGroupsPath,
  inquiries,
  pendingGroupCounts,
  groups,
  pageParam,
  longtermWaitlistEnabled,
  queryFilters,
  inquiryStatuses,
  inquiryTransactionTypes,
  longestLoa,
  contractsEnabled,
  generalInquiriesPath,
}) => {
  const urlParams = new URLSearchParams(window.location.search)
  const page = urlParams.get("page") || pageParam
  const [currentInquiryPage, setCurrentInquiryPage] = useState(parseInt(page))
  const [filters, setFilters] = useState({
    ...queryFilters,
    sortKey: queryFilters.sortKey
      ? snakecaseToCamelCase(queryFilters.sortKey)
      : null,
  })
  const [queryableContractGroups, setQueryableContractGroups] = useState(groups)
  const MIN_LOA = 0
  const MAX_LOA = inchesToFeet(longestLoa)

  return (
    <ManageInquiriesContext.Provider
      value={{
        archivedContractGroupsPath,
        contractsEnabled,
        currentInquiryPage,
        groups,
        inquiries,
        pendingGroupCounts,
        refreshRate,
        setCurrentInquiryPage,
        longtermWaitlistEnabled,
        filters,
        setFilters,
        inquiryStatuses,
        inquiryTransactionTypes,
        MIN_LOA,
        MAX_LOA,
        queryableContractGroups,
        setQueryableContractGroups,
        generalInquiriesPath,
      }}
    >
      <div className="h-[75vh] bg-white px-0 pt-2 lg:px-6">
        <Inquiries />
      </div>
    </ManageInquiriesContext.Provider>
  )
}

ManageInquiries.propTypes = {
  archivedContractGroupsPath: PropTypes.string,
  contractsEnabled: PropTypes.bool.isRequired,
  groups: PropTypes.array.isRequired,
  inquiries: PropTypes.shape({
    totalPages: PropTypes.number.isRequired,
    inquiries: PropTypes.array,
  }),
  longtermWaitlistEnabled: PropTypes.bool.isRequired,
  pageParam: PropTypes.number,
  pendingGroupCounts: PropTypes.array,
  queryFilters: PropTypes.shape({
    search: PropTypes.string,
    statuses: PropTypes.array,
    categories: PropTypes.array,
    loaMin: PropTypes.string,
    loaMax: PropTypes.string,
    sortKey: PropTypes.string,
    sortDirection: PropTypes.string,
  }),
  inquiryStatuses: PropTypes.array,
  inquiryTransactionTypes: PropTypes.array,
  longestLoa: PropTypes.number.isRequired,
  generalInquiriesPath: PropTypes.string.isRequired,
}

export default ManageInquiries
