import PropTypes from "prop-types"
import React from "react"

import { nameToInitials } from "../../main/Chat/helpers"

const ProfileImage = ({ imageUrl, name }) => {
  if (imageUrl) {
    return (
      <img
        className="h-10 w-10 rounded-full"
        src={imageUrl}
        alt={`Profile picture for ${name}`}
        data-design-system="ProfileImage"
      />
    )
  } else {
    return (
      <div
        data-design-system="ProfileImage"
        className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-600 text-white"
      >
        {nameToInitials(name)}
      </div>
    )
  }
}

ProfileImage.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string.isRequired,
}

export default ProfileImage
