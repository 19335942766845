import React, { useEffect, useState } from "react"
import { useFieldArray, useFormContext } from "react-hook-form"

import Form from "src/components/Form"

import { VALIDATED_FIELDS_BY_TAB } from "../../../constants"
import PageActions from "../../wizard/PageActions"
import InstallmentsTable from "./InstallmentsTable"
import { getDefaultInstallmentsWithDueDate } from "./utils"

const INSTALLMENT_QUANTITIES = [...Array(12).keys()].map((i) => ({
  value: i + 1,
  display: i + 1,
}))

const Installments = () => {
  const {
    register,
    formState: { errors },
    setValue,
    trigger,
    watch,
    clearErrors,
  } = useFormContext()
  const { fields, replace } = useFieldArray({
    name: "contractInstallments",
  })
  const watchedInstallments = watch("contractInstallments")
  const firstFieldAmountType = watchedInstallments[0].amountType
  const [installmentQuantity, setInstallmentQuantity] = useState(fields.length)

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchedInstallments[index],
    }
  })

  // adding and removing fields based on installment quantity selection
  const onInstallmentQuantityChange = (newQuantity) => {
    setInstallmentQuantity(newQuantity)
    const difference = newQuantity - watchedInstallments.length
    if (difference === 0) {
      return
    }
    const newInstallments = watchedInstallments.map((field) => ({
      ...field,
      amount: field.amountType === "dollars" ? field.amount : null,
    }))
    if (difference > 0) {
      replace(
        newInstallments.concat(
          Array(difference)
            .fill()
            .reduce(getDefaultInstallmentsWithDueDate(watchedInstallments), [])
        )
      )
    } else {
      replace(newInstallments.slice(0, difference))
    }
    // we want to hide any errors if a user makes any change to installment quantity
    clearErrors(["contractInstallments", "contractInstallments.root"])
  }

  // resetting distribution type if installment quantity is set to 2 or 1
  useEffect(() => {
    if (fields.length < 3 && firstFieldAmountType === "dollars") {
      setValue("installmentDistributionType", "custom")
    }
  }, [fields.length, setValue, firstFieldAmountType])

  const hideDistributionType =
    (installmentQuantity === 2 && firstFieldAmountType === "dollars") ||
    installmentQuantity === 1

  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex flex-row gap-4">
          <div className="w-1/2">
            <Form.Label htmlFor="installment-quantity" required>
              Installments
            </Form.Label>
            <Form.Select
              id="installment-quantity"
              name="installment-quantity"
              value={installmentQuantity}
              onChange={(e) =>
                onInstallmentQuantityChange(Number(e.target.value))
              }
            >
              {INSTALLMENT_QUANTITIES.map(({ value, display }) => (
                <option key={value} value={value}>
                  {display}
                </option>
              ))}
            </Form.Select>
          </div>
          {!hideDistributionType ? (
            <div className="w-1/2">
              <Form.Label htmlFor="distribution-type" required>
                Distribution Type
              </Form.Label>
              <Form.Select
                id="distribution-type"
                name="distribution-type"
                {...register("installmentDistributionType")}
              >
                <option value="custom">Custom</option>
                <option value="even">Even distribution</option>
              </Form.Select>
            </div>
          ) : null}
        </div>
        <InstallmentsTable fields={controlledFields} />
        {errors?.contractInstallments?.root ? (
          <Form.Error>{errors.contractInstallments.root.message}</Form.Error>
        ) : null}
      </div>
      <PageActions
        pageValidation={() => trigger(VALIDATED_FIELDS_BY_TAB.installments)}
      />
    </>
  )
}

export default Installments
