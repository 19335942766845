import { Transition } from "@headlessui/react"
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useMutation } from "react-query"

import {
  cancelPaymentMethodSetup,
  createPaymentMethod,
  initializePaymentMethodSetup,
} from "../../../../api/Account/PaymentMethods"
import { enableAutopay } from "../../../../api/Account/Reservations/Autopay"
import Button from "../../../../components/Button"
import Form from "../../../../components/Form"
import useSetupIntent from "../../../../hooks/payment_method_hooks/use_setup_intent"
import useStripeForm from "../../../../hooks/payment_method_hooks/use_stripe_form"
import { useToast } from "../../../../hooks/use_toast"

const Autopay = ({ paymentMethods, acceptedPaymentMethods, reservationId }) => {
  const [complete, setComplete] = useState(false)
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState("")
  const [paymentMethodsById, setPaymentMethodsById] = useState(
    paymentMethods.reduce((acc, paymentMethod) => {
      acc[paymentMethod.stripePaymentMethodId] = paymentMethod
      return acc
    }, {})
  )
  const showToast = useToast()

  const addPaymentSelected = selectedPaymentMethodId === "add"
  const { activeSetupIntent, stripePromise } = useSetupIntent(
    addPaymentSelected,
    acceptedPaymentMethods,
    false,
    initializePaymentMethodSetup,
    cancelPaymentMethodSetup
  )

  const noPaymentMethodSelected =
    selectedPaymentMethodId === "" || selectedPaymentMethodId === "add"

  const onCreatePaymentMethodSuccess = (paymentMethod) => {
    setPaymentMethodsById((prev) => ({
      ...prev,
      [paymentMethod.stripePaymentMethodId]: paymentMethod,
    }))
    setSelectedPaymentMethodId(paymentMethod.stripePaymentMethodId)
    showToast("Payment method successfully added", {
      type: "success",
      duration: 10,
    })
  }

  const { mutate: enableAutopayMutation, isLoading } = useMutation({
    mutationFn: (stripePaymentMethodId) =>
      enableAutopay({ reservationId, stripePaymentMethodId }),
    onSuccess: ({ reservationUrl }) => {
      setComplete(true)
      window.location.replace(reservationUrl)
    },
    onError: (error) => {
      setComplete(false)
      showToast(error.message, { type: "error", duration: 5 })
    },
  })

  const handleEnableAutopay = (event) => {
    event.preventDefault()
    setComplete(true)
    enableAutopayMutation(selectedPaymentMethodId)
  }

  return (
    <div>
      <h2 className="text-lg font-semibold">Sign up for Autopay</h2>
      <p>
        Simplify your life: Ditch the checkbook and embrace convenience with
        automatic payments!
      </p>
      <Form>
        <Form.Label htmlFor="payment-methods">Select Payment Method</Form.Label>
        <Form.Select
          id="payment-methods"
          value={selectedPaymentMethodId}
          onChange={(event) => setSelectedPaymentMethodId(event.target.value)}
        >
          <option value="" disabled>
            Select a Payment Method
          </option>
          <option value="add">Add New Payment Method</option>
          {Object.values(paymentMethodsById).map((paymentMethod) => (
            <option
              key={paymentMethod.id}
              value={paymentMethod.stripePaymentMethodId}
            >
              {paymentMethod.title} {paymentMethod.subtitle}
            </option>
          ))}
        </Form.Select>
      </Form>
      <Transition
        show={!!addPaymentSelected && !!activeSetupIntent}
        appear={false}
        unmount={true}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: activeSetupIntent?.clientSecret,
          }}
        >
          <AddPaymentMethodForm onSuccess={onCreatePaymentMethodSuccess} />
        </Elements>
      </Transition>
      <div className="mt-4 flex justify-end">
        <Button
          type="submit"
          variant="primary"
          disabled={noPaymentMethodSelected || complete}
          onClick={handleEnableAutopay}
          isLoading={isLoading}
        >
          Enable Autopay
        </Button>
      </div>
    </div>
  )
}

const AddPaymentMethodForm = ({ onSuccess }) => {
  const stripe = useStripe()
  const elements = useElements()
  const [isBankAccount, setIsBankAccount] = useState(false)

  const { handleSubmit, handleChange, isSubmitting, error, complete } =
    useStripeForm({
      stripe,
      elements,
      onSuccess,
      createFn: createPaymentMethod,
    })

  const handleElementChange = (event) => {
    const {
      value: { type },
    } = event

    setIsBankAccount(type === "us_bank_account")
    handleChange(event)
  }

  return (
    <form className="mt-8 flex flex-col space-y-4">
      <PaymentElement onChange={handleElementChange} />
      {complete && error && <Form.Error>{error.message}</Form.Error>}
      {isBankAccount && (
        <div className="space-y-2">
          <div className="text-xs text-gray-600">
            If your bank account isn{"'"}t listed, you can add it manually in
            your{" "}
            <a href="/account/payment" className="font-semibold">
              account settings
            </a>
            . This process may take 1-2 business days, after which you can
            return to complete your autopay setup.
          </div>
        </div>
      )}
      <Button
        type="submit"
        variant="secondary"
        disabled={!complete || error}
        onClick={handleSubmit}
        isLoading={isSubmitting}
        fullWidth={true}
      >
        Save
      </Button>
    </form>
  )
}

Autopay.propTypes = {
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      isEditable: PropTypes.bool.isRequired,
      stripePaymentMethodId: PropTypes.string,
      stripeFingerprintId: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      logo: PropTypes.string,
      reservationCount: PropTypes.number.isRequired,
      status: PropTypes.oneOf([
        "initialized",
        "requires_confirmation",
        "requires_action",
        "requires_reauthorization",
        "processing",
        "succeeded",
        "failed",
        "blocked",
      ]),
    })
  ).isRequired,
  acceptedPaymentMethods: PropTypes.arrayOf(
    PropTypes.oneOf(["card", "us_bank_account"])
  ).isRequired,
  reservationId: PropTypes.string.isRequired,
}

AddPaymentMethodForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
}

export default Autopay
