import classNames from "classnames"
import Cookies from "js-cookie"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import DataTableHeadRow from "src/components/DataTable/DataTableHeadRow"
import Form from "src/components/Form"
import OverflowMenu from "src/components/OverflowMenu"
import Pagination from "src/components/Pagination"
import Table from "src/components/Table"

import { useRowSelection } from "src/hooks/use_row_selection"

const useManageTableColumns = ({ defaultColumns, name }) => {
  const [columns, setColumns] = useState(() => {
    const storedColumns = Cookies.get(`${name}.columns`)
      ? JSON.parse(Cookies.get(`${name}.columns`))
      : null

    if (storedColumns) {
      // Merge user managed stored column definitions with default column definitions
      return defaultColumns.map((defaultColumn) => {
        const storedColumn = storedColumns.find(
          (column) => column.key === defaultColumn.key
        )

        if (storedColumn) {
          // Merge stored column settings with default column settings
          // Functions cannot be stored in cookies, so they need to be redefined based on the column definition when they are fetched
          return {
            ...defaultColumn,
            hide: storedColumn.managedByUser
              ? storedColumn.hide
              : defaultColumn.hide,
            render: defaultColumn.render,
            managedByUser: storedColumn.managedByUser || false,
          }
        } else {
          // If a column is not found in the stored columns, it is a new column added to the table after it has been initialized
          return {
            ...defaultColumn,
            managedByUser: false,
          }
        }
      })
    }

    return defaultColumns.map((column) => ({
      ...column,
      managedByUser: false,
    }))
  })

  useEffect(() => {
    Cookies.set(`${name}.columns`, JSON.stringify(columns))
  }, [columns, name])

  const updateColumns = (updatedColumns) => {
    const columnsWithUserFlag = updatedColumns.map((column) => ({
      ...column,
      managedByUser: true,
    }))
    setColumns(columnsWithUserFlag)
  }

  return [columns, updateColumns]
}

const DataTable = ({
  autoColumnWidth = false,
  bulkActions = [],
  colDefs,
  fullHeight = false,
  hideActions,
  hideSettings,
  infinite = false,
  isLoading = false,
  maxTableWidth = false,
  name,
  numberOfPages,
  sortConfig,
  onPageChange,
  page,
  pagination = false,
  rowConfig,
  rowCount,
  rowData,
  showBlankRows = false,
}) => {
  const addIdsToRows = (data) => {
    return data?.map((row, index) => {
      if (!row.id) {
        return { ...row, id: `row-${index}` }
      }
      return row
    })
  }

  const [columns, setColumns] = useManageTableColumns({
    defaultColumns: colDefs,
    name,
  })
  const [rows, setRows] = useState(addIdsToRows(rowData))

  useEffect(() => {
    setRows(addIdsToRows(rowData))
  }, [rowData])

  const rowIds = rows?.map((row) => row.id)

  const { allRowsSelected, selectedRows, toggleAllRows, toggleRowSelection } =
    useRowSelection({ allRowIds: rowIds })

  const visibleColumnsCount = columns.filter((colDef) => !colDef.hide).length

  const handleColumnCheckBoxChange = (
    columns,
    setColumns,
    colDef,
    defaultColumns
  ) => {
    const newColumns = columns.map((column) => {
      if (column.key === colDef.key) {
        return { ...column, hide: !column.hide }
      }
      return column
    })

    newColumns.sort((a, b) => {
      const aIndex = defaultColumns.findIndex((column) => column.key === a.key)
      const bIndex = defaultColumns.findIndex((column) => column.key === b.key)
      return aIndex - bIndex
    })

    setColumns(newColumns)
  }

  const renderColumnCheckBoxes = () => (
    <div className="w-min">
      <OverflowMenu variant="tableRow" icon="settings-cog text-lg">
        <div className="flex flex-col space-y-4 p-2">
          <span className="font-semibold">Table Columns</span>
          {colDefs.map((colDef) => (
            <Form.Checkbox
              key={colDef.key}
              label={colDef.header}
              name={colDef.key}
              checked={columns.some(
                (column) => column.key === colDef.key && !column.hide
              )}
              onChange={() =>
                handleColumnCheckBoxChange(columns, setColumns, colDef, colDefs)
              }
            />
          ))}
        </div>
      </OverflowMenu>
    </div>
  )

  const renderActions = (actions) => {
    if (actions) {
      return (
        <div className="w-min">
          <OverflowMenu variant="tableRow">
            {actions.map((action, index) => (
              <OverflowMenu.Item
                key={index}
                onClick={action.onClick}
                variant={action.variant}
                href={action.href}
              >
                {action.action}
              </OverflowMenu.Item>
            ))}
          </OverflowMenu>
        </div>
      )
    } else {
      return <div />
    }
  }

  return (
    <div
      className={classNames("overflow-x-auto lg:overflow-x-visible", {
        "h-full": fullHeight,
      })}
      data-design-system="Table"
    >
      <table
        className={classNames("relative rounded border", {
          "table-auto": autoColumnWidth,
          "table-fixed": !autoColumnWidth,
          "w-full": !maxTableWidth,
          "w-max": maxTableWidth,
          "is-infinite group border-separate border-spacing-0 rounded-none border-none":
            infinite,
        })}
      >
        <Table.Head>
          <DataTableHeadRow
            bulkActions={bulkActions}
            allRowsSelected={allRowsSelected}
            selectedRowCount={selectedRows.length}
            onSelectAllToggled={toggleAllRows}
            hideSettings={hideSettings}
            hideActions={hideActions}
            renderColumnCheckBoxes={renderColumnCheckBoxes}
            visibleColumnsCount={visibleColumnsCount}
          >
            {columns.map((colDef, index) => {
              if (colDef.hide) {
                return null
              }
              return (
                <Table.Head.Cell
                  key={`${colDef.key}-${index}`}
                  columnWidth={colDef.width}
                  onClick={
                    colDef.sortable && sortConfig.onColumnSort
                      ? () => sortConfig.onColumnSort(colDef.key)
                      : null
                  }
                  sortDirection={sortConfig?.sortDirection}
                  isSortColumn={sortConfig?.sortKey === colDef.key}
                >
                  {colDef.header}
                </Table.Head.Cell>
              )
            })}
          </DataTableHeadRow>
        </Table.Head>
        <Table.Body
          showBlankRows={showBlankRows}
          isLoading={isLoading}
          rowCount={rowCount}
        >
          {rows?.map((row) => {
            const {
              actions,
              color,
              textColor,
              variant,
              onClick,
              innerRef,
              selectable,
            } = rowConfig || {}

            return (
              <Table.Row
                key={row.id}
                selectable={
                  selectable ? selectable(row) && bulkActions.length > 0 : false
                }
                isSelected={selectedRows.includes(row.id)}
                onSelect={() => toggleRowSelection(row.id)}
                innerRef={innerRef ? innerRef(row) : null}
                onClick={onClick ? () => onClick(row) : null}
                textColor={textColor ? textColor(row) : "default"}
                rowColor={color ? color(row) : "default"}
                variant={variant ? variant(row) : "default"}
              >
                {selectable && !selectable(row) && bulkActions.length > 0 ? (
                  <Table.Cell>
                    <div />
                  </Table.Cell>
                ) : null}
                {columns.map((colDef, colIndex) => {
                  const cellData = row[colDef.key]
                  if (colDef.hide) {
                    return null
                  }
                  return (
                    <Table.Cell
                      key={`${colDef.key}-${colIndex}`}
                      align={colDef.cellAlign}
                    >
                      {colDef.render ? colDef.render(row) : cellData}
                    </Table.Cell>
                  )
                })}
                {!hideActions ? (
                  <Table.Cell align="right">
                    {actions ? renderActions(actions(row)) : <div />}
                  </Table.Cell>
                ) : (
                  !hideSettings && (
                    <Table.Cell>
                      <div />
                    </Table.Cell>
                  )
                )}
              </Table.Row>
            )
          })}
        </Table.Body>
      </table>
      {pagination && numberOfPages > 1 ? (
        <div className="mt-2">
          <Pagination
            numberOfPages={numberOfPages}
            onPageChange={onPageChange}
            page={page}
          />
        </div>
      ) : null}
    </div>
  )
}

DataTable.propTypes = {
  allRowsSelected: PropTypes.bool,
  autoColumnWidth: PropTypes.bool,
  bulkActions: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
  colDefs: PropTypes.arrayOf(
    PropTypes.shape({
      cellAlign: PropTypes.oneOf(["left", "right", "center", "justify"]),
      header: PropTypes.string.isRequired,
      hide: PropTypes.bool,
      key: PropTypes.string.isRequired,
      render: PropTypes.func,
      width: PropTypes.string,
    })
  ),
  fullHeight: PropTypes.bool,
  hideActions: PropTypes.bool,
  hideSettings: PropTypes.bool,
  infinite: PropTypes.bool,
  isLoading: PropTypes.bool,
  maxTableWidth: PropTypes.bool,
  name: PropTypes.string.isRequired,
  numberOfPages: PropTypes.number,
  onPageChange: PropTypes.func,
  onSelectAllToggled: PropTypes.func,
  page: PropTypes.number,
  pagination: PropTypes.bool,
  rowConfig: PropTypes.shape({
    actions: PropTypes.func,
    color: PropTypes.func,
    innerRef: PropTypes.func,
    selectable: PropTypes.func,
    textColor: PropTypes.func,
    variant: PropTypes.func,
    onClick: PropTypes.func,
  }),
  rowCount: PropTypes.number,
  rowData: PropTypes.arrayOf(PropTypes.object.isRequired),
  sortConfig: PropTypes.shape({
    sortDirection: PropTypes.oneOf(["asc", "desc", "ASC", "DESC"]),
    sortKey: PropTypes.string,
    onColumnSort: PropTypes.func,
  }),
  selectedRowCount: PropTypes.number,
  selectedRows: PropTypes.array,
  showBlankRows: PropTypes.bool,
  toggleRowSelection: PropTypes.func,
}

export default DataTable
