import PropTypes from "prop-types"
import React from "react"
import { Controller } from "react-hook-form"

import Form from "src/components/Form"

export const RecurringStartDateField = ({ errors, control, endDate }) => {
  return (
    <div data-testid="recurring-start-date">
      <Controller
        name="start_date"
        control={control}
        maxDate={endDate}
        rules={{ required: "Start date is required." }}
        render={({ field: { onChange, value } }) => (
          <Form.DatePicker
            id="start-date"
            renderCustomHeader={(props) => (
              <Form.DatePicker.QuickNavHeader {...props} />
            )}
            {...{ onChange, value }}
            hasErrors={Boolean(errors.start_date)}
          />
        )}
      />
      {errors.start_date && (
        <Form.Error>{errors.start_date.message}</Form.Error>
      )}
    </div>
  )
}

RecurringStartDateField.propTypes = {
  control: PropTypes.object,
  errors: PropTypes.object,
  endDate: PropTypes.object,
}

export default RecurringStartDateField
