import React, { useContext } from "react"
import { useFormContext } from "react-hook-form"
import { centsToDollars } from "src/main/Billing/Items/helpers"

import Divider from "src/components/Divider"
import Form from "src/components/Form"

import { safePercentageDecimal } from "src/utils/number_helpers"
import { snakeCaseToHumanize } from "src/utils/string_helpers"

import { VALIDATED_FIELDS_BY_TAB } from "../../../constants"
import { ContractsFormContext } from "../../ContractsFormContext"
import DiscountTable from "../../shared/DiscountTable"
import PageActions from "../../wizard/PageActions"

const Rate = () => {
  const {
    register,
    getValues,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext()
  const {
    pricingUrl,
    namedRates,
    pricingStructures,
    showDiscounts,
    longTermStorageProducts,
  } = useContext(ContractsFormContext)

  const billingCycle = getValues("billingCycle")
  const selectedRateId = watch("namedRateId")
  const isNamedRate = selectedRateId !== "custom"

  const updateAndValidate = (name, value) =>
    setValue(name, value, {
      shouldValidate: true,
    })

  const handleRateSelected = (id) => {
    if (id === "custom") {
      setValue("rate.amount", null)
      setValue("rate.taxRate", "")
      setValue("rate.pricingStructure", "")
      setValue("namedRateId", id)
    } else {
      const parsedId = parseInt(id, 10)
      const namedRate = namedRates.find((rate) => rate.id === parsedId)
      updateAndValidate("rate.amount", centsToDollars(namedRate.amount))
      updateAndValidate(
        "rate.taxRate",
        safePercentageDecimal(namedRate.taxRate)
      )
      updateAndValidate("rate.pricingStructure", namedRate.pricingStructure)
      updateAndValidate("namedRateId", parsedId)
    }
  }

  return (
    <>
      <div className="flex flex-col gap-4">
        <div>
          <Form.Label htmlFor="rate">Rate</Form.Label>
          <div className="text-muted mb-2">
            Visit the{" "}
            <a target="_blank" href={pricingUrl} rel="noreferrer">
              Pricing
            </a>{" "}
            settings to create and update installment rates.
          </div>
          <Form.Select
            id="rate"
            value={selectedRateId}
            onChange={(e) => handleRateSelected(e.target.value)}
          >
            <optgroup label="Installment Rate">
              {namedRates.map((rate) => (
                <option key={rate.id} value={rate.id}>
                  {rate.name}
                </option>
              ))}
            </optgroup>
            <optgroup label="Custom Rate">
              <option value="custom">Custom rate</option>
            </optgroup>
          </Form.Select>
        </div>
        <div className="flex w-full flex-row justify-between gap-4">
          <div className="w-1/2">
            <Form.Label htmlFor="pricing-structure" required>
              Pricing Structure
            </Form.Label>
            <Form.Select
              id="pricing-structure"
              disabled={isNamedRate}
              {...register("rate.pricingStructure")}
            >
              <option disabled value="">
                Select a pricing structure
              </option>
              <optgroup key="Monthly" label="Monthly">
                {pricingStructures.monthly.map((option) => (
                  <option key={option.value} value={option.value}>
                    {snakeCaseToHumanize(option.name)}
                  </option>
                ))}
              </optgroup>
              {billingCycle !== "month_to_month" ? (
                <optgroup key="Full Stay" label="Full Stay">
                  {pricingStructures.full_stay.map((option) => (
                    <option key={option.value} value={option.value}>
                      {snakeCaseToHumanize(option.name)}
                    </option>
                  ))}
                </optgroup>
              ) : null}
            </Form.Select>
            {errors?.rate?.pricingStructure ? (
              <Form.Error>{errors.rate.pricingStructure.message}</Form.Error>
            ) : null}
          </div>
          <div className="w-1/4">
            <Form.Label htmlFor="amount" required>
              Amount
            </Form.Label>
            <Form.IconTextField
              disabled={isNamedRate}
              icon="$"
              id="amount"
              {...register("rate.amount")}
              type="number"
              hasErrors={Boolean(errors?.rate?.amount)}
            />
            {errors?.rate?.amount ? (
              <Form.Error>{errors.rate.amount.message}</Form.Error>
            ) : null}
          </div>
          <div className="w-1/4">
            <Form.Label htmlFor="tax-rate">Tax</Form.Label>
            <Form.IconTextField
              disabled={isNamedRate}
              icon="%"
              position="right"
              id="tax-rate"
              {...register("rate.taxRate")}
              type="number"
              hasErrors={Boolean(errors?.rate?.taxRate)}
            />
            {errors?.rate?.taxRate ? (
              <Form.Error>{errors.rate.taxRate.message}</Form.Error>
            ) : null}
          </div>
        </div>
        <div>
          <Form.Label htmlFor="long-term-storage-product-id" required>
            Product Type
          </Form.Label>
          <Form.Select
            id="long-term-storage-product-id"
            {...register("rate.longTermStorageProductId")}
            hasErrors={Boolean(errors?.rate?.longTermStorageProductId)}
          >
            <option value="" disabled />
            {longTermStorageProducts.map((product) => (
              <option key={product.id} value={product.id}>
                {product.name}
              </option>
            ))}
          </Form.Select>
          {errors?.rate?.longTermStorageProductId ? (
            <Form.Error>
              {errors.rate.longTermStorageProductId.message}
            </Form.Error>
          ) : null}
        </div>
      </div>
      {billingCycle !== "month_to_month" && showDiscounts ? (
        <>
          <Divider />
          <DiscountTable />
        </>
      ) : null}
      <PageActions
        pageValidation={() => trigger(VALIDATED_FIELDS_BY_TAB.rate)}
      />
    </>
  )
}

export default Rate
