import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useLayoutEffect, useRef, useState } from "react"
import { useMutation, useQueryClient } from "react-query"

import { useTracker } from "src/hooks/use_tracker"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import { sendMessage } from "../../api/Chat"

const SendBox = ({ source, chatObject, tracking = {} }) => {
  const marinaSlug = getCurrentMarinaSlug()
  const tracker = useTracker()

  const inputRef = useRef(null)
  const [newMessage, setNewMessage] = useState("")
  const isSubmitEnabled = newMessage.trim().length > 0

  const queryClient = useQueryClient()
  const queryKey = ["chat", marinaSlug, chatObject.id]

  useLayoutEffect(() => {
    if (!newMessage) {
      inputRef.current?.focus({ preventScroll: true })
    }
  }, [newMessage])

  useLayoutEffect(() => {
    // Auto expand height of text area to match needed input lines
    // https://stackoverflow.com/a/67960211 - general technique
    // https://stackoverflow.com/a/5346855 - height = auto hack
    inputRef.current.style.height = "auto"
    inputRef.current.style.height = `${inputRef.current.scrollHeight}px`
  }, [newMessage])

  const { mutate, isLoading } = useMutation(
    (data) => sendMessage(marinaSlug, data, source),
    {
      onSuccess: async (newMessage) => {
        // https://react-query-v3.tanstack.com/guides/optimistic-updates
        await queryClient.cancelQueries(queryKey)
        queryClient.setQueryData(queryKey, (old) => [...old, newMessage])

        setNewMessage("")
      },
      onSettled: () => {
        queryClient.invalidateQueries(queryKey)
      },
    }
  )
  const onSubmit = () => {
    const trimmedMessage = newMessage.trim()
    if (!trimmedMessage.length) return

    if (tracking.send) {
      tracker.trackEvent(tracking.send.event, tracking.send.attributes)
    }

    mutate({
      type: chatObject.type,
      id: chatObject.id,
      message: trimmedMessage,
    })
  }

  return (
    <div className="relative">
      <textarea
        className="w-full overflow-hidden rounded border border-gray-600 p-4 pr-12"
        aria-label="Message"
        placeholder="Start a new message"
        rows={1}
        ref={inputRef}
        value={newMessage}
        disabled={isLoading}
        onChange={(e) => setNewMessage(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.stopPropagation()
            onSubmit()
          }
        }}
      />
      <div className="absolute right-0 top-0 flex h-12 w-12">
        <button
          className="h-full w-full rounded bg-transparent"
          disabled={!isSubmitEnabled || isLoading}
          onClick={onSubmit}
        >
          <i
            className={classNames(
              {
                "cursor-not-allowed text-gray-400": !isSubmitEnabled,
                "text-blue-100": isSubmitEnabled,
                "icon icon-send": !isLoading,
                "icon icon-spin icon-spinner": isLoading,
              },
              "justify-center self-center text-lg"
            )}
          />
          <span className="sr-only">Send message</span>
        </button>
      </div>
    </div>
  )
}

SendBox.propTypes = {
  chatObject: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  source: PropTypes.oneOf(["boater", "dockmaster"]),
  tracking: PropTypes.shape({
    send: PropTypes.shape({
      event: PropTypes.string.isRequired,
      attributes: PropTypes.object,
    }),
  }),
}

export default SendBox
