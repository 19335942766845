import { format, parse } from "date-fns"
import Decimal from "decimal.js"
import { PERCENT_OF_STORAGE_PRICING_STRUCTURE } from "src/main/Billing/constants"

const parseOutgoingDate = (dateString) =>
  parse(dateString, "yyyy-MM-dd", new Date())

export const parseDateString = (date) => {
  if (!date) {
    return ""
  }
  return format(parseOutgoingDate(date), "MM/dd/yyyy")
}

const formatPriceByPrecision = (price, precision) => {
  if (precision === "cents") {
    return new Decimal(price).div(100).toNumber()
  }
  if (precision === "hundredths_of_cents") {
    return new Decimal(price).div(10000).toNumber()
  }
}

export const calculateTotalPriceDisplay = (field) => {
  if (field.pricingStructure === PERCENT_OF_STORAGE_PRICING_STRUCTURE) {
    const feePercent = new Decimal(field.quantity).mul(100).toString()
    return `${feePercent}%`
  } else {
    const taxRate = Number(field.taxRate)
    const pricePerUnit = Number(field.pricePerUnit)
    const quantity = Number(field.quantity)
    const total = new Decimal(
      formatPriceByPrecision(pricePerUnit, field.pricePrecision)
    )
      .mul(quantity)
      .mul(1 + taxRate)

    return `$${total.toFixed(2)}`
  }
}

const getSharedFormattedData = (data) => {
  return {
    name: data.name,
    originalAmount: Number(data.original_amount),
    productId: data.product_id,
    taxAmount: Number(data.tax_amount),
    taxRate: Number(data.tax_rate),
    quantity: Number(data.quantity),
    pricePerUnit: Number(data.price_per_unit),
    pricePrecision: data.price_precision,
    startDate: data.start_date,
    endDate: data.end_date,
    externalSale: data.external_sale,
    requestExternalSettlement: data.request_external_settlement,
  }
}

const formatNonRecurring = ({
  invoices,
  txn: { product_sale_attributes: data },
}) => {
  const {
    due_date: dueDate,
    due_on_signature: dueOnSignature,
    add_to_installments: addToInstallments,
  } = invoices[0]
  return {
    ...getSharedFormattedData({
      ...data,
      start_date: data.service_start_date,
      end_date: data.service_end_date,
    }),
    pricingStructure: data.pricing_structure,
    dueDate,
    dueDay: null,
    dueOnSignature,
    addToInstallments,
  }
}
const formatRecurring = ({ recurring_product_sale: data }) => {
  return {
    ...getSharedFormattedData(data),
    amount: Number(data.amount),
    dueDate: null,
    dueDay: Number(data.due_day),
  }
}

export const formatModalData = (data) => {
  if ("recurring_product_sale" in data) {
    return formatRecurring(data)
  } else {
    return formatNonRecurring(data)
  }
}

const paymentSchedule = (item) => {
  if (item.dueDate) {
    return "custom_due_date"
  } else if (item.dueOnSignature) {
    return "due_on_signature"
  } else if (item.addToInstallments) {
    return "add_to_installments"
  } else {
    return ""
  }
}

const recurringEndSelection = (item) => {
  if (item.dueDay) {
    return item.endDate ? "onDate" : "never"
  } else {
    return null
  }
}

export const parseSelectedItem = (item) => {
  if (!item) {
    return undefined
  }
  const startDate = parseOutgoingDate(item.startDate)
  const endDate = item.endDate ? parseOutgoingDate(item.endDate) : null

  const pricingStructure = item.pricingStructure
  const isPercentOfReservationSale =
    pricingStructure === PERCENT_OF_STORAGE_PRICING_STRUCTURE
  const quantity = isPercentOfReservationSale
    ? new Decimal(item.quantity).mul(100).toNumber()
    : Number(item.quantity)

  return {
    payment_method_id: null,
    payment_schedule: paymentSchedule(item),
    recurring_product: item.dueDay ? "yes" : "no",
    monthly_due_date: item.dueDay,
    start_date: item.dueDay ? startDate : null,
    end_date: item.dueDay ? endDate : null,
    recurring_end_selection: recurringEndSelection(item),
    custom_due_date: item.dueDate ? parseOutgoingDate(item.dueDate) : null,
    txn: {
      product_sale_attributes: {
        name: item.name,
        product_id: item.productId,
        tax_percent: new Decimal(item.taxRate).mul(100).toNumber(),
        quantity: quantity,
        price_per_unit: formatPriceByPrecision(
          Number(item.pricePerUnit),
          item.pricePrecision
        ),
        price_precision: item.pricePrecision,
        external_sale: !!item.externalSale,
        request_external_settlement: item.requestExternalSettlement,
        service_start_date: item.dueDay ? null : startDate,
        service_end_date: item.dueDay ? null : endDate,
        pricing_structure: pricingStructure,
      },
    },
  }
}
