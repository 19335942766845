import { yupResolver } from "@hookform/resolvers/yup"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { useMutation } from "react-query"
import TelescopeCtaModal from "src/main/TransientRates/TelescopeCtaModal"

import Button from "src/components/Button"
import Form from "src/components/Form"
import Tooltip from "src/components/Tooltip"

import { createRateStrategy, updateRateStrategy } from "src/api/TransientRates"

import { useToast } from "src/hooks/use_toast"

import { snakeCaseToHumanize } from "src/utils/string_helpers"

import RateModifiers from "../RateModifiers"
import { schema } from "./RateStrategySchema"

const RateStrategyForm = ({
  rateStrategy,
  rateTypes,
  timeframes,
  selectedTimeframe,
  action,
  actionUrl,
  cancelUrl,
  telescopeEnabled,
  telescopeCtaUrl,
  occupancyParams,
}) => {
  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      rateStrategy,
      rateThresholds: rateStrategy.rates.map((rate) => rate.rateThresholds),
      thresholds: rateStrategy.rates[0]?.rateThresholds?.map(
        ({ threshold }) => threshold
      ),
    },
  })
  const convertedModifiers = rateStrategy.modifiers.map((modifier) => {
    const { percentageAmount, fixedAmount, type } = modifier
    return {
      ...modifier,
      fixedAmount:
        type === "TransientRate::Modifier::Beam" ||
        type === "TransientRate::Modifier::MinimumPrice"
          ? fixedAmount
            ? fixedAmount / 100
            : null
          : modifier.fixedAmount,
      percentageAmount: percentageAmount ? percentageAmount * 100 : null,
    }
  })

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modifiers, setModifiers] = useState(convertedModifiers)
  const showToast = useToast()

  const rateFields = useFieldArray({
    control,
    name: "rateStrategy.rates",
  })

  const rateThresholdFields = useFieldArray({
    control,
    name: "rateThresholds",
  })

  const thresholdColFields = useFieldArray({
    control,
    name: "thresholds",
  })

  const { mutate, isLoading } = useMutation({
    mutationFn: (data) => {
      if (action === "new") {
        return createRateStrategy({ data, actionUrl })
      } else {
        return updateRateStrategy({ data, actionUrl })
      }
    },
    onSuccess: (options) => {
      window.location.href = options.redirectUrl
    },
    onError: (error) => {
      showToast(error.message, { type: "error" })
    },
  })

  const onSaveButtonClick = (data) => {
    mergeData(data)
    mutate(data)
  }

  const mergeModifierData = (data) => {
    const newData = modifiers.filter(
      (modifier) => modifier.type !== data.modifier.type
    )
    setModifiers([...newData, data.modifier])
  }

  const mergeData = (data) => {
    // ----------------------- //
    // We have to keep the data in a slightly flatter format because of how
    // the data is structured in the form via the useFieldArray hook.
    // This method zips the data together in a way that the backend is expecting to save it in.
    // ----------------------- //

    // merge thresholds into rateThresholds
    data.thresholds.forEach((threshold, index) => {
      data.rateThresholds.forEach((rateThresholds) => {
        rateThresholds[index].threshold = parseInt(threshold)
      })
    })

    // Remove rateThresholds entries where the threshold is no longer in the thresholds array
    data.rateThresholds = data.rateThresholds.map((rateThresholds) =>
      rateThresholds.filter((rateThreshold) =>
        data.thresholds.includes(rateThreshold.threshold)
      )
    )

    // add rates to be destroyed via AR accepts_nested_attributes_for
    const ratesToDestroy = rateStrategy.rates
      .filter(
        (originalRate) =>
          !data.rateStrategy.rates.some((rate) => originalRate.id === rate.id)
      )
      .map((rate) => {
        rate._destroy = true
        return rate
      })
    data.rateStrategy.rates.push(...ratesToDestroy)
    // merge rateThresholds into rates
    data.rateStrategy.rates.forEach((rate, index) => {
      rate.rateThresholds = data.rateThresholds[index]
    })
    // add modifiers to be destroyed via AR accepts_nested_attributes_for
    const modifiersToDestroy = modifiers
      .filter(
        (modifier) =>
          modifier.id !== null &&
          !modifier.percentageAmount &&
          !modifier.fixedAmount
      )
      .map((mod) => {
        mod._destroy = true
        return mod
      })
    // merge modifiers into rateStrategy.modifiers
    data.rateStrategy.modifiers = modifiers.filter(
      (modifier) => modifier.percentageAmount || modifier.fixedAmount
    )

    data.rateStrategy.modifiers.push(...modifiersToDestroy)
  }

  const addRateRow = () => {
    rateFields.append({
      minLength: "",
      maxLength: "",
      rate: "",
    })
    rateThresholdFields.append()
  }

  const removeRateRow = (index) => {
    if (
      window.confirm(
        "Are you sure you want to delete this rate row? All rates in this row will be removed."
      )
    ) {
      rateFields.remove(index)
      rateThresholdFields.remove(index)
    }
  }

  const addThresholdCol = () => {
    if (!telescopeEnabled) {
      setIsModalOpen(true)
    } else {
      let threshold = 20
      if (hasThresholds()) {
        threshold =
          parseInt(
            getValues(`thresholds.${thresholdColFields.fields.length - 1}`)
          ) + 15
      }
      thresholdColFields.append(threshold)
    }
  }

  const removeThresholdCol = (index) => {
    if (
      window.confirm(
        "Are you sure you want to delete this threshold? All rates in this threshold column will be removed."
      )
    ) {
      thresholdColFields.remove(index)

      // Remove the threshold from the rateThresholds array by detructuring the rateThresholds object into an array,
      // removing the threshold at the index, and then returning the modified array.
      const newThresholds = rateThresholdFields.fields.map((rateThreshold) => {
        const { id, ...rest } = rateThreshold
        const arr = Object.values(rest)
        arr.splice(index, 1)
        return arr
      })
      rateThresholdFields.replace(newThresholds)
    }
  }

  const hasThresholds = () => {
    return thresholdColFields.fields.length > 0 && telescopeEnabled
  }

  const renderThresholdHeaders = () => {
    if (!hasThresholds()) return null

    return (
      <th className="bg-blue-100/50" colSpan={thresholdColFields.fields.length}>
        Occupancy Rates
        <br />
        {occupancyParams.totalOccupancy > 0 && (
          <small className="-mb-1 -mt-0.5 block text-gray-600">
            Percentage based on {occupancyParams.totalOccupancy}{" "}
            {occupancyParams.label} of occupancy
          </small>
        )}
      </th>
    )
  }

  const renderTelescopeCtaModal = () => {
    return (
      <TelescopeCtaModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        ctaUrl={telescopeCtaUrl}
      />
    )
  }

  const renderThresholdHeaderInputs = () => {
    return thresholdColFields.fields.map((field, index) => {
      return (
        <td key={field.id} className="bg-blue-100/50 !align-top">
          <Form.IconTextField
            icon={<span>%</span>}
            position="right"
            {...register(`thresholds.${index}`)}
            hasErrors={!!errors?.thresholds?.[index]}
          />
          {errors?.thresholds?.[index] && (
            <Form.Error>{errors?.thresholds?.[index].message}</Form.Error>
          )}
        </td>
      )
    })
  }

  const renderThresholdFooters = () => {
    if (!hasThresholds()) return null

    return thresholdColFields.fields.map((field, index) => {
      return (
        <td key={field.id} className="bg-blue-50 !align-top">
          <Button
            variant="ghost"
            fullWidth={true}
            onClick={() => removeThresholdCol(index)}
          >
            <i className="icon icon-sf-trashcan" />
          </Button>
        </td>
      )
    })
  }

  const renderRateThresholds = (rateIndex) => {
    if (!hasThresholds()) return null

    return thresholdColFields.fields.map((field, index) => {
      return (
        <td key={field.id} className="bg-blue-50 !align-top">
          <Form.IconTextField
            icon={<span>$</span>}
            position="left"
            {...register(`rateThresholds.${rateIndex}.${index}.rate`)}
            hasErrors={!!errors?.rateThresholds?.[rateIndex]?.[index]?.rate}
          />
          {errors?.rateThresholds?.[rateIndex]?.[index]?.rate && (
            <Form.Error>
              {errors?.rateThresholds?.[rateIndex]?.[index]?.rate?.message}
            </Form.Error>
          )}
        </td>
      )
    })
  }

  const renderRateStrategyTimeframe = () => {
    const formField = (
      <Form.Select
        id="timeframe"
        name="select"
        disabled={rateStrategy.mappingCount > 0}
        {...register("rateStrategy.timeframe")}
        hasErrors={!!errors?.rateStrategy?.timeframe}
      >
        {timeframes.map((timeframe) => (
          <option
            value={timeframe}
            key={timeframe}
            selected={timeframe === selectedTimeframe}
          >
            {snakeCaseToHumanize(timeframe)}
          </option>
        ))}
      </Form.Select>
    )
    if (rateStrategy.mappingCount > 0) {
      return (
        <Tooltip
          text="Time frame cannot be modified when the rate strategy is in use. Unmap the rate strategy from all calendar days to modify the time frame."
          placement="top"
          variant="dark"
          maxWidth="400px"
        >
          {formField}
        </Tooltip>
      )
    } else {
      return formField
    }
  }

  const renderRateStrategies = () => {
    const html = []
    if (hasThresholds()) {
      html.push(
        <tr key="header">
          <td className="!align-top" colSpan="2"></td>
          <td className="!align-top">
            <Form.IconTextField
              value="0"
              icon={<span>%</span>}
              position="right"
              disabled
            />
          </td>
          {renderThresholdHeaderInputs()}
          <td className="!align-top">
            <Button onClick={addThresholdCol}>{`${
              telescopeEnabled ? "Add threshold" : "Enable Telescope"
            } →`}</Button>
          </td>
        </tr>
      )
    }
    rateFields.fields.forEach((field, index) => {
      html.push(
        <tr key={field.id}>
          <td className="!align-top">
            <Form.IconTextField
              icon={<span>ft</span>}
              position="right"
              {...register(`rateStrategy.rates.${index}.minLength`)}
              hasErrors={!!errors?.rateStrategy?.rates?.[index]?.minLength}
            />
            {errors?.rateStrategy?.rates?.[index]?.minLength && (
              <Form.Error>
                {errors?.rateStrategy?.rates?.[index]?.minLength.message}
              </Form.Error>
            )}
          </td>
          <td className="!align-top">
            <Form.IconTextField
              icon={<span>ft</span>}
              position="right"
              {...register(`rateStrategy.rates.${index}.maxLength`)}
              hasErrors={!!errors?.rateStrategy?.rates?.[index]?.maxLength}
            />
            {errors?.rateStrategy?.rates?.[index]?.maxLength && (
              <Form.Error>
                {errors?.rateStrategy?.rates?.[index]?.maxLength.message}
              </Form.Error>
            )}
          </td>
          <td className="!align-top">
            <Form.IconTextField
              icon={<span>$</span>}
              position="left"
              {...register(`rateStrategy.rates.${index}.rate`)}
              hasErrors={!!errors?.rateStrategy?.rates?.[index]?.rate}
            />
            {errors?.rateStrategy?.rates?.[index]?.rate && (
              <Form.Error>
                {errors?.rateStrategy?.rates?.[index]?.rate.message}
              </Form.Error>
            )}
          </td>
          {renderRateThresholds(index)}
          <td className="!align-top">
            {rateFields.fields.length > 1 && (
              <Button onClick={() => removeRateRow(index)}>Delete</Button>
            )}
          </td>
        </tr>
      )
    })
    if (hasThresholds()) {
      html.push(
        <tr key="footer">
          <td className="!align-top">
            <Button onClick={addRateRow}>Add rate row ↓</Button>
          </td>
          <td colSpan="2" className="!align-top"></td>
          {renderThresholdFooters()}
        </tr>
      )
    } else {
      html.push(
        <tr key="footer">
          <td colSpan="100" className="!align-top">
            <Button onClick={addRateRow}>Add rate row ↓</Button>
          </td>
        </tr>
      )
    }
    return html
  }

  return (
    <div className="card p-4 lg:p-12">
      <div className="space-y-12">
        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 lg:grid-cols-4">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Rate Strategy Setup
            </h2>
            <div className="mt-1 text-sm text-gray-600">
              This information is the initial configuration for setting up a
              rate. Actual rates are set up below.
              {!telescopeEnabled && (
                <div className="mt-10 rounded-lg bg-blue-50 p-4 xl:p-10">
                  <p className="mb-2 text-xl font-bold text-blue-900 xl:mb-4 xl:text-3xl">
                    Dockwa Telescope
                  </p>
                  <p>
                    Welcome to the future of pricing! Dockwa Telescope empowers
                    you to adjust your rates based on occupancy, optimizing your
                    marina&#39;s performance effortlessly.
                  </p>
                </div>
              )}
            </div>
          </div>

          <div className="col-span-3 max-w-5xl space-y-8">
            <div>
              <Form.Label htmlFor="strategy-name">Strategy Name</Form.Label>
              <Form.TextField
                id="strategy-name"
                {...register("rateStrategy.name")}
                hasErrors={!!errors?.rateStrategy?.name}
              />
              {errors?.rateStrategy?.name && (
                <Form.Error>{errors?.rateStrategy?.name.message}</Form.Error>
              )}
              <div className="mt-2 text-sm text-gray-600">
                For internal use only. This is the name of the rate strategy.
                (ex. Weekday Rate)
              </div>
            </div>
            <div>
              <Form.Label htmlFor="rate-type">Rate Type</Form.Label>
              <Form.Select
                id="rate-type"
                name="select"
                {...register("rateStrategy.rateType")}
                hasErrors={!!errors?.rateStrategy?.rateType}
              >
                <option value=""></option>
                {rateTypes.map((rateType) => (
                  <option value={rateType} key={rateType}>
                    {snakeCaseToHumanize(rateType)}
                  </option>
                ))}
              </Form.Select>
              {errors?.rateStrategy?.rateType && (
                <Form.Error>
                  {errors?.rateStrategy?.rateType.message}
                </Form.Error>
              )}
              <div className="mt-2 text-sm text-gray-600">
                &quot;By Length&quot; rate is a per foot rate type.
                &quot;Flat&quot; rate is a fixed rate per night/day.
              </div>
            </div>
            <div>
              <Form.Label htmlFor="timeframe">Time frame</Form.Label>
              {renderRateStrategyTimeframe()}
              {errors?.rateStrategy?.timeframe && (
                <Form.Error>
                  {errors?.rateStrategy?.timeframe.message}
                </Form.Error>
              )}
              <div className="mt-2 text-sm text-gray-600">
                Set the time frame for the rate strategy.
              </div>
            </div>
            <div>
              <Form.Label htmlFor="description">
                Description (optional)
              </Form.Label>
              <Form.Textarea
                id="description"
                {...register("rateStrategy.description")}
              />
              <div className="mt-2 text-sm text-gray-600">
                This field is for internal use only, boaters will not see it.
                Use this space to give context about this rate strategy or how
                it should be used.
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 lg:grid-cols-4">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Rate Setup
            </h2>
            <div className="mt-1 text-sm text-gray-600">
              Configure the rate here. You can add min and max lengths to apply
              specific rates to different boat sizes.
            </div>
          </div>

          <div className="lg:col-span-3">
            <table className="table border">
              <thead>
                <tr>
                  <th className="bg-gray-100">
                    Min Length
                    <br />
                    <small className="-mb-1 -mt-0.5 block text-gray-600">
                      Greater than or equal to
                    </small>
                  </th>
                  <th className="bg-gray-100">
                    Max Length
                    <br />
                    <small className="-mb-1 -mt-0.5 block text-gray-600">
                      Less than
                    </small>
                  </th>
                  <th className="bg-gray-100">Rate</th>
                  {renderThresholdHeaders()}
                  <th className="bg-gray-100">
                    {!hasThresholds() && (
                      <Button onClick={addThresholdCol}>{`${
                        telescopeEnabled ? "Add threshold" : "Enable Telescope"
                      } →`}</Button>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>{renderRateStrategies()}</tbody>
            </table>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 lg:grid-cols-4">
          <div>
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Modifiers Setup
            </h2>
            <div className="mt-1 text-sm text-gray-600">
              Configure rate modifiers here. Rate modifiers adjust the rate
              based off of the conditions provided.
            </div>
          </div>

          <RateModifiers
            modifiers={modifiers}
            mergeModifierData={mergeModifierData}
            rateType={getValues("rateStrategy.rateType")}
          />
        </div>

        <div className="flex w-full justify-end pb-12">
          <div className="flex gap-x-4">
            <Button
              variant="primary"
              onClick={handleSubmit(onSaveButtonClick)}
              isLoading={isLoading}
            >
              Save Changes
            </Button>
            <a href={cancelUrl} className="btn btn-secondary">
              Cancel
            </a>
          </div>
        </div>
      </div>
      {renderTelescopeCtaModal()}
    </div>
  )
}

RateStrategyForm.propTypes = {
  rateStrategy: PropTypes.object.isRequired,
  rateTypes: PropTypes.array.isRequired,
  timeframes: PropTypes.array.isRequired,
  selectedTimeframe: PropTypes.string.isRequired,
  action: PropTypes.oneOf(["new", "edit"]).isRequired,
  actionUrl: PropTypes.string.isRequired,
  cancelUrl: PropTypes.string.isRequired,
  telescopeEnabled: PropTypes.bool.isRequired,
  telescopeCtaUrl: PropTypes.string.isRequired,
  occupancyParams: PropTypes.object.isRequired,
}

export default RateStrategyForm
