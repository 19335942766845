import React from "react"

const Xero = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      id="Layer_1"
      x={0}
      y={0}
      width="33"
      height="33"
      viewBox="0 0 95.3 94"
    >
      <style>{".st1{fill:#fff}"}</style>
      <path
        d="M47.6 0C21.3 0 0 21 0 47s21.3 47 47.6 47 47.6-21 47.6-47c.1-26-21.3-47-47.6-47z"
        style={{
          fill: "#13b5ea",
        }}
      />
      <path
        d="M34.9 45c.9-3.3 3.9-5.7 7.3-5.7 3.5 0 6.5 2.3 7.3 5.7H34.9zm17.3 2.1c.5-.6.7-1.4.5-2.2-.6-3-2.3-5.4-4.7-7-1.7-1.1-3.7-1.6-5.7-1.6-2.3 0-4.4.7-6.2 2-2.8 2-4.5 5.3-4.5 8.8 0 .9.1 1.7.3 2.6 1.1 4.2 4.7 7.4 9.1 8 .4.1.8.1 1.3.1.9 0 1.7-.1 2.6-.3 1.1-.5 2.1-1 3.1-1.6.9-.6 1.8-1.4 2.7-2.6l.1-.1c.3-.4.4-.9.4-1.3-.1-.4-.3-.8-.6-1-.3-.2-.7-.4-1-.4-.3 0-.8.1-1.3.7-.1.2-.3.4-.5.6-.6.7-1.3 1.2-2.1 1.7-1.1.6-2.3.9-3.5.9-3.9 0-6.2-2.6-7.2-5l-.3-1.2v-.1h15c1.1 0 2-.4 2.5-1zM74.1 44c-1.5 0-2.7 1.2-2.7 2.7 0 1.5 1.2 2.7 2.7 2.7 1.5 0 2.7-1.2 2.7-2.7 0-1.4-1.2-2.7-2.7-2.7zM64.1 37.9c0-.9-.7-1.5-1.6-1.5h-.4c-1.3 0-2.6.4-3.6 1.2-.2-.6-.8-1.1-1.5-1.1-.9 0-1.5.7-1.5 1.5v17.8c0 .8.7 1.5 1.5 1.5.9 0 1.5-.7 1.5-1.5V44.9c0-3.5.3-5 3.4-5.4h.6c1-.1 1.6-.7 1.6-1.6z"
        className="st1"
      />
      <path
        d="m23.5 46.9 8-8c.3-.3.5-.7.5-1.1 0-.9-.7-1.5-1.6-1.5-.4 0-.8.2-1.1.5l-8 8-8-8c-.3-.3-.7-.4-1.1-.4-.9 0-1.5.7-1.5 1.5 0 .4.2.8.5 1.1l8 8-8 8c-.3.3-.5.7-.5 1.1 0 .8.7 1.5 1.5 1.5.4 0 .8-.2 1.1-.4l8-8 8 7.9c.3.3.7.5 1.1.5.9 0 1.6-.7 1.6-1.5 0-.4-.2-.8-.5-1.1l-8-8.1zM74.1 54.2c-4.1 0-7.5-3.3-7.5-7.5 0-4.1 3.4-7.5 7.5-7.5s7.5 3.3 7.5 7.5-3.4 7.5-7.5 7.5zm0-18.1c-5.9 0-10.7 4.8-10.7 10.6 0 5.9 4.8 10.6 10.7 10.6 5.9 0 10.7-4.8 10.7-10.6S80 36.1 74.1 36.1z"
        className="st1"
      />
    </svg>
  )
}

export default Xero
