import PropTypes from "prop-types"
import React from "react"
import PaymentMethodsContainer from "src/main/Account/PaymentMethods/PaymentMethodsContainer"

import HeadingPageTitle from "src/components/Heading/HeadingPageTitle"

const PaymentMethods = ({ paymentMethods }) => (
  <div>
    <div className="mb-8 mt-1">
      <HeadingPageTitle>Payment Methods</HeadingPageTitle>
    </div>
    <PaymentMethodsContainer
      paymentMethods={paymentMethods}
      accountPageRefresh
    />
  </div>
)

PaymentMethods.propTypes = {
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      isEditable: PropTypes.bool.isRequired,
      stripePaymentMethodId: PropTypes.string,
      stripeFingerprintId: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      logo: PropTypes.string,
      reservationCount: PropTypes.number.isRequired,
      status: PropTypes.oneOf([
        "initialized",
        "requires_confirmation",
        "requires_action",
        "requires_reauthorization",
        "processing",
        "succeeded",
        "failed",
        "blocked",
      ]),
    })
  ),
}
export default PaymentMethods
