import PropTypes from "prop-types"
import React from "react"
import { formatDate } from "src/main/Billing/Items/helpers"

import OverflowMenu from "src/components/OverflowMenu"
import Table from "src/components/Table/index"

import { formattedCentsToDollars } from "src/utils/UnitConversion"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import PaymentMethodDescription from "./PaymentMethodDescription"
import PaymentStatusBadge from "./PaymentStatusBadge"

const PaymentRow = ({
  payment,
  editPayment,
  settlePayment,
  renderMenuItems,
  editable = true,
}) => {
  const {
    id,
    balance,
    total_amount: totalAmount,
    due_date: dueDate,
    settled_at: settledAt,
  } = payment
  const settledDate = settledAt ? formatDate(settledAt) : "-"
  const previewText = Number(balance) === 0 ? "View receipt" : "View invoice"
  const marinaSlug = getCurrentMarinaSlug()
  const previewPath = `/manage/${marinaSlug}/payments/${id}/view`
  const handlePreviewClick = () => {
    window.open(previewPath, "_blank")
  }
  const overflowMenuProps = {
    variant: "tableRow",
    alignEnd: true,
    disabled: !editable,
  }
  if (Number(balance) !== 0) {
    overflowMenuProps.cta = {
      label: "Settle",
      onClick: () => settlePayment(payment),
      fullWidth: false,
      disabled: !editable,
    }
  }

  return (
    <Table.Row key={id} variant="parent" onClick={editPayment(payment)}>
      <Table.Cell>
        <PaymentStatusBadge payment={payment} />
      </Table.Cell>
      <Table.Cell>
        <PaymentMethodDescription paymentMethod={payment.payment_method} />
      </Table.Cell>
      <Table.Cell title={formatDate(dueDate)} />
      <Table.Cell title={settledDate} />
      <Table.Cell title={formattedCentsToDollars(totalAmount)} />
      <Table.Cell>
        <OverflowMenu {...overflowMenuProps}>
          <OverflowMenu.Item label="Open" onClick={editPayment(payment)} />
          <OverflowMenu.Item
            label={previewText}
            onClick={() => handlePreviewClick()}
          />
          {renderMenuItems ? renderMenuItems(payment) : null}
        </OverflowMenu>
      </Table.Cell>
    </Table.Row>
  )
}

PaymentRow.propTypes = {
  payment: PropTypes.shape({
    id: PropTypes.string.isRequired,
    balance: PropTypes.number.isRequired,
    payment_method: PropTypes.object,
    total_amount: PropTypes.number.isRequired,
    due_date: PropTypes.string.isRequired,
    settled_at: PropTypes.string,
    display_status: PropTypes.string.isRequired,
  }),
  editPayment: PropTypes.func.isRequired,
  settlePayment: PropTypes.func.isRequired,
  renderMenuItems: PropTypes.func,
  editable: PropTypes.bool.isRequired,
}

export default PaymentRow
