import { addDays, differenceInDays, format } from "date-fns"
import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import { useMutation, useQueryClient } from "react-query"
import AcceptToContractGroupModal from "src/main/Inquiries/AcceptToContractGroupModal"
import DeclineModal from "src/main/Inquiries/DeclineModal"
import { ManageInquiriesContext } from "src/main/Inquiries/index"
import {
  ContactBoat,
  ContactName,
  ContractGroup,
  SpecialRequest,
} from "src/main/Inquiries/row_components"
import { DockwaLogo } from "src/main/Reservations/DockwaLogo"

import Badge from "src/components/Badge"
import DataTable from "src/components/DataTable"
import Tooltip from "src/components/Tooltip"

import { updateInquiryStatus } from "src/api/Inquiries"

import { useToast } from "src/hooks/use_toast"

import { inchesToFeet } from "src/utils/UnitConversion"
import {
  snakecaseToCamelCase,
  snakecaseToTitlecase,
} from "src/utils/string_helpers"
import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const badgeColor = (status) => {
  switch (status) {
    case "Pending":
      return "yellow"
    case "In Progress":
      return "blue"
    case "Accepted":
      return "blue-inverted"
    case "Expired":
      return "gray"
    case "Completed":
      return "teal"
    case "Canceled":
    case "Declined":
      return "red"
    case "Waitlisted":
      return "orange"
    default:
      return "gray"
  }
}

const renderStatusBadge = (status) => (
  <Badge color={badgeColor(status)} text={status} variant="small" />
)

const renderSource = (data) => {
  const boaterSource = data.source === "boater"

  return (
    <div className="w-min">
      <Tooltip text={data.metaSource} placement="left" variant="dark">
        {boaterSource ? (
          <DockwaLogo />
        ) : (
          <i className="icon icon-trackpad text-2xl text-gray-500" />
        )}
      </Tooltip>
    </div>
  )
}

const renderSubmittedDate = ({ createdAt, status }) => {
  const date = new Date(createdAt)
  const today = new Date()
  const daysUntilExpiration = 8 - differenceInDays(today, date)

  if (daysUntilExpiration > 0 && status === "pending") {
    return (
      <div className="w-min">
        <Tooltip
          text={`Expires in ${daysUntilExpiration} days`}
          placement="left"
          variant="dark"
        >
          <span className="flex items-center text-yellow-700">
            {createdAt}
            <i className="icon icon-md-schedule -mt-0.5 ml-1" />
          </span>
        </Tooltip>
      </div>
    )
  } else if (status === "expired") {
    return (
      <div className="w-min">
        <Tooltip
          text={`Expired on ${format(addDays(date, 8), "MM/dd/yy")}`}
          placement="left"
          variant="dark"
        >
          <span className="flex items-center text-red-700">
            {createdAt}
            <i className="icon icon-md-schedule -mt-0.5 ml-1" />
          </span>
        </Tooltip>
      </div>
    )
  } else {
    return <span>{createdAt}</span>
  }
}

const DEFAULT_COLUMN_DEFINITIONS = [
  {
    key: "status",
    header: "Status",
    render: (data) =>
      data.status ? renderStatusBadge(snakecaseToTitlecase(data.status)) : null,
    width: "95px",
  },
  {
    key: "contactName",
    header: "Captain",
    render: (data) => <ContactName contactName={data.contact.name} />,
    width: "135px",
    sortable: true,
  },
  {
    key: "boatName",
    header: "Boat",
    render: (data) => <ContactBoat boatName={data.contactBoat.name} />,
    width: "135px",
    sortable: true,
  },
  {
    key: "phone",
    header: "Phone",
    render: (data) => data.contact.displayPhone,
    width: "135px",
    hide: true,
  },
  {
    key: "loa",
    header: "LOA",
    render: (data) =>
      data.contactBoat?.lengthOverall &&
      `${inchesToFeet(data.contactBoat?.lengthOverall)}'`,
    width: "55px",
    sortable: true,
  },
  {
    key: "beam",
    header: "Beam",
    render: (data) =>
      data.contactBoat?.beam && `${inchesToFeet(data.contactBoat?.beam)}'`,
    width: "55px",
    sortable: true,
    hide: true,
  },
  {
    key: "draw",
    header: "Draft",
    render: (data) =>
      data.contactBoat?.draw && `${inchesToFeet(data.contactBoat?.draw)}'`,
    width: "55px",
    sortable: true,
    hide: true,
  },
  {
    key: "height",
    header: "Height",
    render: (data) =>
      data.contactBoat?.height && `${inchesToFeet(data.contactBoat?.height)}'`,
    width: "60px",
    sortable: true,
    hide: true,
  },
  {
    key: "createdAt",
    header: "Submitted",
    width: "90px",
    render: (data) => renderSubmittedDate(data),
    sortable: true,
  },
  {
    key: "source",
    header: "Source",
    render: renderSource,
    width: "55px",
  },
  {
    key: "transactionType",
    header: "Category",
    width: "120px",
  },
  {
    key: "startDate",
    header: "Start",
    width: "85px",
    sortable: true,
  },
  {
    key: "endDate",
    header: "End",
    width: "85px",
    sortable: true,
  },
  {
    key: "specialRequest",
    header: "Requests",
    render: (data) => <SpecialRequest specialRequest={data.specialRequest} />,
    width: "130px",
  },
]

const COLUMN_DEFINITIONS_WITH_CONTRACTS = [...DEFAULT_COLUMN_DEFINITIONS]

COLUMN_DEFINITIONS_WITH_CONTRACTS.splice(
  COLUMN_DEFINITIONS_WITH_CONTRACTS.length - 1,
  0,
  {
    key: "contractGroup",
    header: "Contract Group",
    render: (data) =>
      data.contractGroup && (
        <ContractGroup contractGroup={data.contractGroup} />
      ),
    width: "150px",
    sortable: true,
  }
)
const InquiriesTable = ({
  inquiries,
  page,
  numberOfPages,
  onColumnSort,
  onPageChange,
}) => {
  const { longtermWaitlistEnabled, groups, contractsEnabled, filters } =
    useContext(ManageInquiriesContext)
  const [showModal, setShowModal] = useState(false)
  const [selectedContact, setSelectedContact] = useState(null)
  const [selectedInquiry, setSelectedInquiry] = useState(null)
  const [showDeclineModal, setShowDeclineModal] = useState(false)
  const marinaSlug = getCurrentMarinaSlug()
  const queryClient = useQueryClient()
  const showToast = useToast()

  const { sortKey, sortDirection } = filters

  const { mutate: updateStatus, isLoading: updatingInquiryStatus } =
    useMutation((data) => updateInquiryStatus({ data }), {
      onSuccess: (data) => {
        queryClient.invalidateQueries("inquirySearch")
        showToast(data.message, { type: "success" })
        setShowDeclineModal(false)
        if (data.status === "waitlisted") {
          window.location.assign(
            `/manage/${marinaSlug}/waitlists/long_term/entries`
          )
        }
      },
      onError: (error) => {
        showToast(error.message, { type: "error" })
      },
    })

  const rowActions = (data) => {
    const {
      status,
      encodedId,
      contact,
      contactBoat,
      longTermWaitlistEntryId,
      contractQuoteId,
      reservationId,
    } = data
    const pendingOrInProgress = status === "pending" || status === "in_progress"
    const actions = [
      {
        action: "View Customer",
        variant: "link",
        href: `/manage/${marinaSlug}/contacts/${contact.encodedId}`,
      },
    ]

    if (status === "pending") {
      actions.push({
        action: "In Progress",
        onClick: () => updateStatus({ id: encodedId, status: "in_progress" }),
      })
    }

    if (contractsEnabled && pendingOrInProgress) {
      if (groups.length) {
        actions.push({
          action: "Create Contract",
          onClick: () => {
            setShowModal(true)
            setSelectedInquiry(data)
          },
        })
      }
      actions.push({
        action: "Create Reservation",
        variant: "link",
        href: `/manage/${marinaSlug}/reservations/new/contact/${contact.encodedId}/boat/${contactBoat.encodedId}?inquiry_id=${encodedId}`,
      })
    }

    if (longtermWaitlistEnabled && pendingOrInProgress) {
      actions.push({
        action: "Add to Long-term Waitlist",
        onClick: () => updateStatus({ id: encodedId, status: "waitlisted" }),
      })
    }

    if (pendingOrInProgress) {
      actions.push(
        {
          action: "Mark as Complete",
          onClick: () => updateStatus({ id: encodedId, status: "completed" }),
        },
        {
          action: "Decline",
          onClick: () => {
            setShowDeclineModal(true)
            setSelectedContact(encodedId)
          },
        }
      )
    }

    if (longTermWaitlistEntryId) {
      actions.push({
        action: "View Waitlist",
        variant: "link",
        href: `/manage/${marinaSlug}/messages/${longTermWaitlistEntryId}?type=LongTermWaitlistEntry`,
      })
    }

    if (status === "accepted") {
      if (contractQuoteId) {
        actions.push({
          action: "View Contract",
          variant: "link",
          href: `/manage/${marinaSlug}/contacts/${contact.encodedId}#contracts`,
        })
      } else if (reservationId) {
        actions.push({
          action: "View Reservation",
          variant: "link",
          href: `/manage/${marinaSlug}/contacts/${contact.encodedId}#reservations`,
        })
      }
    }

    return actions
  }

  const rowConfig = {
    actions: (data) => rowActions(data),
    onClick: (data) =>
      (window.location.href = `/manage/${marinaSlug}/messages/${data.encodedId}?type=Lead`),
  }

  const sortConfig = {
    sortKey: sortKey ? snakecaseToCamelCase(sortKey) : null,
    sortDirection,
    onColumnSort,
  }

  return (
    <>
      <DataTable
        colDefs={
          contractsEnabled
            ? COLUMN_DEFINITIONS_WITH_CONTRACTS
            : DEFAULT_COLUMN_DEFINITIONS
        }
        name="inquiries"
        numberOfPages={numberOfPages}
        onPageChange={onPageChange}
        page={page}
        pagination
        rowData={inquiries}
        rowConfig={rowConfig}
        sortConfig={sortConfig}
      />
      <AcceptToContractGroupModal
        contractGroups={groups}
        isOpen={showModal}
        selectedInquiry={selectedInquiry}
        contractGroup={selectedInquiry?.contractGroupId}
        setIsOpen={setShowModal}
      />
      <DeclineModal
        isOpen={showDeclineModal}
        setIsOpen={setShowDeclineModal}
        selectedContact={selectedContact}
        updateStatus={updateStatus}
        isLoading={updatingInquiryStatus}
      />
    </>
  )
}

InquiriesTable.propTypes = {
  inquiries: PropTypes.arrayOf(
    PropTypes.shape({
      contact: PropTypes.object.isRequired,
      contactBoat: PropTypes.object.isRequired,
      contractGroupId: PropTypes.string,
      createdAt: PropTypes.string.isRequired,
      encodedId: PropTypes.string.isRequired,
      endDate: PropTypes.string,
      longTermWaitlistEntryId: PropTypes.string,
      marinaId: PropTypes.number.isRequired,
      reservationId: PropTypes.string,
      source: PropTypes.string,
      specialRequest: PropTypes.string,
      startDate: PropTypes.string,
      status: PropTypes.string.isRequired,
      transactionType: PropTypes.string,
      contractQuoteId: PropTypes.number,
    })
  ),
  numberOfPages: PropTypes.number,
  onColumnSort: PropTypes.func,
  onPageChange: PropTypes.func,
  page: PropTypes.number,
}

export default InquiriesTable
