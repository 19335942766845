// Accepts a precision integer and returns a function that validates a number to that precision
export const validatePrecision = (precision) => (number) => {
  const [, decimals] = String(number).split(".")

  if (decimals && decimals.length > precision) {
    return `Specify up to ${precision} decimal places.`
  }
}

export const validateEndDate = ({ start, end }) => {
  if (!end) {
    return
  }

  const endDate = new Date(end)
  const startDate = new Date(start)

  if (
    !start ||
    endDate.setUTCHours(0, 0, 0, 0) < startDate.setUTCHours(0, 0, 0, 0)
  ) {
    return "Must be the same as or later than start date."
  }
}

// Validation for recurring item end date
export const validateRecurringEndDate = ({ end, start }) => {
  if (!start) {
    return
  }
  if (!end) {
    return "End date is required."
  }

  const endDate = new Date(end)
  const startDate = new Date(start)
  const startMonth = startDate.getUTCMonth()
  const startYear = startDate.getUTCFullYear()
  const endYear = endDate.getUTCFullYear()

  const monthAfterStartDate = new Date(start)
  // Setting to first of month to avoid issues with start month having fewer days than end month
  monthAfterStartDate.setUTCDate(1)
  // setUTCMonth uses months 0-11, and setting to a value of 12 will wrap to the following year
  monthAfterStartDate.setUTCMonth(startMonth + 1)

  const endMonth = endDate.getUTCMonth()
  const monthAfterStart = monthAfterStartDate.getUTCMonth()

  const oneYearFromStartDate = new Date(start)
  oneYearFromStartDate.setUTCFullYear(startYear + 1)

  if (
    endYear < startYear ||
    (endYear === startYear && endMonth < monthAfterStart)
  ) {
    return "Must be at least in the month after start date."
  }

  if (
    endDate.setUTCHours(0, 0, 0, 0) >
    oneYearFromStartDate.setUTCHours(0, 0, 0, 0)
  ) {
    return "Must be one year or less than start date."
  }
}

export const validateLaterThanToday = (date) => {
  const today = new Date()

  if (date && date.setUTCHours(0, 0, 0, 0) <= today.setUTCHours(0, 0, 0, 0)) {
    return "Date must be later than today."
  }
}

export const validateTodayOrLater = (date) => {
  const today = new Date()

  if (date && date.setUTCHours(0, 0, 0, 0) < today.setUTCHours(0, 0, 0, 0)) {
    return "Date must be today or later."
  }
}
