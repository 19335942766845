import { useMutation } from "react-query"

import { updateInvoice } from "src/api/Billing/Payments"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

export const useUpdateInvoice = ({ paymentId, onSuccess, onError }) => {
  const marinaSlug = getCurrentMarinaSlug()

  const { mutate, isLoading } = useMutation({
    queryKey: ["invoice", paymentId],
    mutationFn: (data) =>
      updateInvoice({ invoiceId: paymentId, marinaSlug, data }),
    onSuccess,
    onError,
  })

  const submit = (data, options) => {
    const paymentMethodId =
      data.invoice.payment_method_id === "manual"
        ? null
        : Number(data.invoice.payment_method_id)

    const requestData = {
      invoice: {
        payment_method_id: paymentMethodId,
        status: data.invoice.status,
      },
    }

    mutate(requestData, options)
  }

  return { submit, isLoading }
}
