import { Elements } from "@stripe/react-stripe-js"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { useForm } from "react-hook-form"

import Form from "src/components/Form"
import AcceptedPaymentMethods from "src/components/SelectPaymentMethod/AcceptedPaymentMethods"
import AddPaymentMethodForm from "src/components/SelectPaymentMethod/AddPaymentMethodForm"

import {
  cancelPaymentMethodSetup,
  createPaymentMethod,
  initializePaymentMethodSetup,
} from "src/api/Account/PaymentMethods"

import useSetupIntent from "src/hooks/payment_method_hooks/use_setup_intent"
import useStripeSetup from "src/hooks/payment_method_hooks/use_stripe_setup"
import { useToast } from "src/hooks/use_toast"
import { useTracker } from "src/hooks/use_tracker"

const SelectPaymentMethod = ({
  acceptedPaymentMethods,
  paymentMethods,
  paymentMethod,
  setPaymentMethod,
  marinaName,
}) => {
  const { register, setValue } = useForm({
    defaultValues: { paymentMethod: "" },
  })
  const [currentPaymentMethods, setCurrentPaymentMethods] =
    useState(paymentMethods)
  const showToast = useToast()
  const tracker = useTracker()
  const { paymentMethod: newPaymentMethod, error } =
    useStripeSetup(createPaymentMethod)

  const addPaymentSelected = paymentMethod === "add"
  const { activeSetupIntent, stripePromise } = useSetupIntent(
    addPaymentSelected,
    acceptedPaymentMethods,
    false,
    initializePaymentMethodSetup,
    cancelPaymentMethodSetup
  )

  useEffect(() => {
    if (newPaymentMethod) {
      tracker.trackEvent("payment_portal:payment_method_added", {
        marina_name: marinaName,
        payment_method_type: newPaymentMethod.type,
      })
      setValue("paymentMethod", newPaymentMethod?.stripePaymentMethodId)
      setPaymentMethod(newPaymentMethod?.stripePaymentMethodId)
      setCurrentPaymentMethods((prev) => [...prev, newPaymentMethod])
    }
  }, [
    newPaymentMethod,
    marinaName,
    tracker,
    setValue,
    setPaymentMethod,
    setCurrentPaymentMethods,
  ])

  useEffect(() => {
    if (error) {
      showToast(error.message, { type: "error" })
    }
  }, [error, showToast])

  const handleSelectChange = (event) => {
    event.preventDefault()

    const paymentMethodSelected = event.target.value
    setValue("paymentMethod", paymentMethodSelected)
    setPaymentMethod(paymentMethodSelected)
    const isExistingPaymentMethod = paymentMethodSelected !== "add"

    if (isExistingPaymentMethod) {
      const paymentMethodObject = paymentMethods.find(
        (method) => method.stripePaymentMethodId === paymentMethodSelected
      )

      tracker.trackEvent("payment_portal:payment_method_selected", {
        marina_name: marinaName,
        payment_method_type: paymentMethodObject?.type,
      })
    }
  }

  return (
    <div className="flex flex-col space-y-6">
      <div className="flex flex-col space-y-2">
        <span className="text-base font-semibold">Payment Method</span>
        <AcceptedPaymentMethods
          acceptedPaymentMethods={acceptedPaymentMethods}
        />
      </div>
      <div>
        <Form.Select
          {...register("paymentMethod")}
          onChange={handleSelectChange}
        >
          <option value="" disabled>
            Select a payment method
          </option>
          {currentPaymentMethods.map((paymentMethod) => (
            <option
              key={paymentMethod.id}
              value={paymentMethod.stripePaymentMethodId}
            >
              {paymentMethod.title} {paymentMethod.subtitle}
            </option>
          ))}
          <option value="add">Add a new payment method</option>
        </Form.Select>
      </div>
      {addPaymentSelected && activeSetupIntent && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: activeSetupIntent.clientSecret,
          }}
        >
          <AddPaymentMethodForm />
        </Elements>
      )}
    </div>
  )
}

SelectPaymentMethod.propTypes = {
  acceptedPaymentMethods: PropTypes.arrayOf(
    PropTypes.oneOf(["card", "us_bank_account"]).isRequired
  ).isRequired,
  paymentMethods: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      stripePaymentMethodId: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.string.isRequired,
    }).isRequired
  ),
  paymentMethod: PropTypes.string.isRequired,
  marinaName: PropTypes.string.isRequired,
  setPaymentMethod: PropTypes.func.isRequired,
}

export default SelectPaymentMethod
