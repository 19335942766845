import BoatDetails from "src/main/Account/Boats/BoatDetails"
import Inquiries from "src/main/Account/Inquiries"
import PayInvoice from "src/main/Account/PaymentPortal/PayInvoice"
import BillingDetails from "src/main/Account/Reservations/BillingDetails"
import SettingsRouter from "src/main/Account/Settings/SettingsRouter"
import ExportButton from "src/main/Reservations/ExportButton"

import ExampleComponent from "../ExampleComponent"
import AddressForm from "../components/AddressForm"
import AlertBanner from "../components/AlertBanner"
import Boats from "../main/Account/Boats"
import Favorites from "../main/Account/Favorites"
import PaymentMethodsContainer from "../main/Account/PaymentMethods/PaymentMethodsContainer"
import Autopay from "../main/Account/Reservations/Autopay"
import Trips from "../main/Account/Trips"
import AccountingRouter from "../main/Accounting"
import Billing from "../main/Billing"
import CancelContractModal from "../main/Billing/CancelContractModal"
import BoatPhoto from "../main/BoaterProfile/BoatPhoto/BoatPhoto"
import ContactInfoForm from "../main/BoaterProfile/ContactInfoForm"
import HomeportPromptsModal from "../main/BoaterProfile/HomeportPrompts/HomeportPromptsModal"
import HomeportSelectField from "../main/BoaterProfile/HomeportSelectField/HomeportSelectField"
import IncompleteBoatProfileMessage from "../main/BoaterProfile/IncompleteBoatProfileMessage/IncompleteBoatProfileMessage"
import Chat from "../main/Chat/Chat"
import ChatPanel from "../main/Chat/ChatPanel"
import Contacts from "../main/Contact"
import ContactBoatPhoto from "../main/Contact/ContactBoatPhoto"
import ContactWaitlistEntries from "../main/Contact/ContactWaitlistEntries"
import ContractGroup from "../main/Contracts/ContractGroup"
import ContractsForm from "../main/Contracts/ContractsForm/ContractsFormContext"
import ManageContracts from "../main/Contracts/ManageContracts"
import ContractQuoteEntryActionsDropdown from "../main/Contracts/ManageContracts/Components/ContractQuoteEntryActionsDropdown"
import MbmContractsAnnouncementBanner from "../main/Contracts/ManageContracts/Components/MbmContractsAnnouncementBanner"
import DocusealTemplateBuilder from "../main/Contracts/ManageContracts/DocusealTemplateBuilder"
import HomeportPrompt from "../main/Contracts/SignContract/HomeportPrompt"
import SignContractWizardContainer from "../main/Contracts/SignContract/SignContractWizardContainer"
import SignInHouseContract from "../main/Contracts/SignInHouseContract"
import DashboardContainer from "../main/Dashboard/DashboardContainer"
import OptInBanner from "../main/Dashboard/OptInBanner"
import LaunchShow from "../main/DryStack/Boater/LaunchShow"
import LaunchesContainer from "../main/DryStack/Boater/LaunchesContainer"
import LaunchNotificationBadge from "../main/DryStack/MarinaSchedule/LaunchNotificationBadge"
import MarinaScheduleContainer from "../main/DryStack/MarinaSchedule/MarinaScheduleContainer"
import DryStackSettingsContainer from "../main/DryStack/Settings/DryStackSettingsContainer"
import Fuel from "../main/Fuel"
import ManageInquiries from "../main/Inquiries"
import InsightsContainer from "../main/Insights/InsightsContainer"
import Integrations from "../main/Integrations"
import LongTermWaitlistCTA from "../main/LongTermWaitlist/boater/LongTermWaitlistCTA"
import LongTermWaitlistEntryShow from "../main/LongTermWaitlist/boater/LongTermWaitlistEntryShow"
import BoaterLongtermWaitlistFormPage from "../main/LongTermWaitlist/boater/WaitlistForm"
import LongTermWaitlistEntriesRouter from "../main/LongTermWaitlist/marina/LongTermWaitlistEntriesRouter"
import ItemFormContainer from "../main/ManageItems/Form/ItemFormContainer"
import ManageItemsContainer from "../main/ManageItems/ManageItemsContainer"
import MarinaClaimSelector from "../main/MarinaClaimSelector"
import MarinaReviewForm from "../main/MarinaReviewForm"
import MarinaSwitcher from "../main/MarinaSwitcher"
import MeteredElectricContainer from "../main/MeteredElectric/MeteredElectricContainer"
import MeteredElectricSettings from "../main/MeteredElectric/Settings/MeteredElectricSettings"
import PaymentsContainer from "../main/Payments/PaymentsContainer"
import PointOfSaleLayoutEditor from "../main/PointOfSale/LayoutEditor"
import PointOfSale from "../main/PointOfSale/Root"
import Sales from "../main/PointOfSale/Sales"
import SaleDetails from "../main/PointOfSale/Sales/SaleDetails"
import ContactSaleHistoryTable from "../main/PointOfSale/Sales/SaleHistory/ContactSaleHistoryTable"
import PhotoContainer from "../main/PublicProfile/Photos/PhotoContainer"
import PublicProfilePublicationStatus from "../main/PublicProfile/PublicProfilePublicationStatus"
import AccountingIntegrationAnnouncementBanner from "../main/Reports/AccountingIntegrationAnnouncementBanner"
import Reservations from "../main/Reservations"
import ReplaceContractAgreement from "../main/Reservations/ReplaceContractAgreement"
import ReservationSettings from "../main/Reservations/Settings"
import ReviewContainer from "../main/Reviews/ReviewContainer"
import BoaterPayNow from "../main/Settings/General/BoaterPayNow"
import TipSettings from "../main/Settings/Hardware/TipSettings"
import CalendarDaysTable from "../main/TransientRates/CalendarDaysTable"
import RateStrategyForm from "../main/TransientRates/RateStrategy"
import CreatePassword from "../main/User/CreatePassword"
import Login from "../main/User/Login"
import ResendEmailConfirmation from "../main/User/ResendEmailConfirmation"
import ResetPassword from "../main/User/ResetPassword"
import Signup from "../main/User/Signup"
import WaitlistTopNavigation from "../main/Waitlist/TopNavigation"
import HeadlessCmsPage from "../marketing/pages/HeadlessCmsPage"

export const automountableComponents = {
  AccountingIntegrationAnnouncementBanner,
  AccountingRouter,
  AddressForm,
  AlertBanner,
  Autopay,
  Billing,
  BillingDetails,
  BoatDetails,
  BoatPhoto,
  BoaterLongtermWaitlistFormPage,
  BoaterPayNow,
  Boats,
  CalendarDaysTable,
  CancelContractModal,
  Chat,
  ChatPanel,
  ContactBoatPhoto,
  ContactInfoForm,
  Contacts,
  ContactSaleHistoryTable,
  ContactWaitlistEntries,
  ContractQuoteEntryActionsDropdown,
  ContractsForm,
  ContractGroup,
  CreatePassword,
  DashboardContainer,
  DocusealTemplateBuilder,
  DryStackSettingsContainer,
  ExampleComponent,
  ExportButton,
  Favorites,
  Fuel,
  HeadlessCmsPage,
  HomeportPrompt,
  HomeportPromptsModal,
  HomeportSelectField,
  IncompleteBoatProfileMessage,
  Inquiries,
  InsightsContainer,
  Integrations,
  ItemFormContainer,
  LaunchNotificationBadge,
  LaunchShow,
  LaunchesContainer,
  Login,
  LongTermWaitlistCTA,
  LongTermWaitlistEntriesRouter,
  LongTermWaitlistEntryShow,
  ManageInquiries,
  ManageItemsContainer,
  ManageContracts,
  MarinaClaimSelector,
  MarinaReviewForm,
  MarinaScheduleContainer,
  MarinaSwitcher,
  MbmContractsAnnouncementBanner,
  MeteredElectricContainer,
  MeteredElectricSettings,
  OptInBanner,
  PayInvoice,
  PaymentMethodsContainer,
  PaymentsContainer,
  PhotoContainer,
  PointOfSale,
  PointOfSaleLayoutEditor,
  PublicProfilePublicationStatus,
  RateStrategyForm,
  ReplaceContractAgreement,
  ResendEmailConfirmation,
  Reservations,
  ReservationSettings,
  ResetPassword,
  ReviewContainer,
  Sales,
  SaleDetails,
  SettingsRouter,
  SignContractWizardContainer,
  SignInHouseContract,
  Signup,
  TipSettings,
  Trips,
  WaitlistTopNavigation,
}
