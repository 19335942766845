import PropTypes from "prop-types"
import React from "react"

import Table from "src/components/Table/index"

import { formattedCentsToDollars } from "src/utils/UnitConversion"

import { hasDisputedInvoice } from "../helpers"
import ItemOverflowMenu from "./ItemOverflowMenu"
import {
  getEffectiveAmount,
  getEffectiveBalance,
  itemDatesAndSubtext,
} from "./helpers"

const ItemRow = ({
  item,
  onViewingItem,
  onRemovingItem,
  contractQuoteId,
  reservationCheckOutDate,
  editable = true,
}) => {
  const {
    id,
    status,
    product_sale: {
      name,
      service_start_date: startDate,
      service_end_date: endDate,
    },
    child_txns: childTxns,
    invoices,
  } = item

  const [dates, datesSubtext] = itemDatesAndSubtext({
    startDate,
    endDate,
    monthToMonth: false,
    departureDate:
      !contractQuoteId && item.product_sale.reservation_dates
        ? reservationCheckOutDate
        : undefined,
  })

  const returned =
    childTxns &&
    childTxns.some((txn) => txn.type === "Billing::ProductReturnTxn")

  const onRemove = onRemovingItem(item)

  const onViewDetails = onViewingItem(item)

  const disableItemRemoval = hasDisputedInvoice(invoices)

  const effectiveAmount = getEffectiveAmount(item)
  const effectiveBalance = getEffectiveBalance(item)

  return (
    <Table.Row key={id} variant="parent">
      <Table.Cell
        title={name}
        variant={returned ? "strikethrough" : "default"}
      />
      <Table.Cell
        title={dates}
        variant={returned ? "strikethrough" : "default"}
        subtitle={datesSubtext}
      />
      <Table.Cell title={status} />
      <Table.Cell
        title={formattedCentsToDollars(effectiveAmount)}
        variant={returned ? "strikethrough" : "default"}
      />
      <Table.Cell>{formattedCentsToDollars(effectiveBalance)}</Table.Cell>
      <td className="p-0" align="right">
        <ItemOverflowMenu
          isItemReturned={returned}
          onViewDetails={onViewDetails}
          onRemove={onRemove}
          disableItemRemoval={disableItemRemoval}
          editable={editable}
        />
      </td>
    </Table.Row>
  )
}

ItemRow.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
    balance: PropTypes.number.isRequired,
    aggregatedAmount: PropTypes.number,
    aggregatedBalance: PropTypes.number,
    product_sale: PropTypes.shape({
      name: PropTypes.string.isRequired,
      service_start_date: PropTypes.string,
      service_end_date: PropTypes.string.isRequired,
      reservation_dates: PropTypes.bool.isRequired,
    }).isRequired,
    child_txns: PropTypes.array,
    invoices: PropTypes.array,
  }).isRequired,
  onViewingItem: PropTypes.func.isRequired,
  onRemovingItem: PropTypes.func.isRequired,
  contractQuoteId: PropTypes.number,
  reservationCheckOutDate: PropTypes.string,
  editable: PropTypes.bool,
}

export default ItemRow
