import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"

import {
  displayText,
  iconClass,
} from "src/components/SelectPaymentMethod/payment_method_helpers"

import {
  cancelPaymentMethodSetup,
  createPaymentMethod,
  initializePaymentMethodSetup,
} from "../../../../api/Account/PaymentMethods"
import Button from "../../../../components/Button"
import Form from "../../../../components/Form"
import useSetupIntent from "../../../../hooks/payment_method_hooks/use_setup_intent"
import useStripeForm from "../../../../hooks/payment_method_hooks/use_stripe_form"
import useStripeSetup from "../../../../hooks/payment_method_hooks/use_stripe_setup"
import { useToast } from "../../../../hooks/use_toast"
import { useTracker } from "../../../../hooks/use_tracker"
import { SignContractWizardContext } from "../SignContractWizardContext"

const SelectPaymentMethod = () => {
  const { dispatch, state, quote, marina } = useContext(
    SignContractWizardContext
  )
  const showToast = useToast()
  const tracker = useTracker()
  const { paymentMethod: newPaymentMethod, error } =
    useStripeSetup(createPaymentMethod)

  const { paymentMethod, paymentMethods } = state
  const { acceptedPaymentMethods, allowDirectPayment } = quote
  const addPaymentSelected = paymentMethod === "add"
  const manualPaymentSelected = paymentMethod === "manual"
  const { activeSetupIntent, stripePromise } = useSetupIntent(
    addPaymentSelected,
    acceptedPaymentMethods,
    false,
    initializePaymentMethodSetup,
    cancelPaymentMethodSetup
  )

  useEffect(() => {
    if (newPaymentMethod) {
      tracker.trackEvent("contracts_v2:payment_method_added", {
        contract_quote_id: quote.id,
        marina_id: marina.id,
        marina_name: marina.name,
        payment_method_type: newPaymentMethod.type,
      })

      dispatch({
        type: "PAYMENT_METHOD_ADDED",
        payload: newPaymentMethod,
      })
    }
  }, [dispatch, newPaymentMethod, quote, marina, tracker])

  useEffect(() => {
    if (error) {
      showToast(error.message, { type: "error" })
    }
  }, [error, showToast])

  const handleSelectChange = (event) => {
    event.preventDefault()

    const paymentMethodSelected = event.target.value
    const manualPaymentMethodSelected = paymentMethodSelected === "manual"
    const isExistingPaymentMethod =
      !manualPaymentMethodSelected &&
      paymentMethodSelected !== "add" &&
      paymentMethodSelected !== ""

    if (isExistingPaymentMethod) {
      const paymentMethodObject = paymentMethods.find(
        (paymentMethod) =>
          paymentMethod.stripePaymentMethodId === paymentMethodSelected
      )

      tracker.trackEvent("contracts_v2:payment_method_selected", {
        contract_quote_id: quote.id,
        marina_id: marina.id,
        marina_name: marina.name,
        payment_method_type: paymentMethodObject?.type,
      })
    }

    if (manualPaymentMethodSelected) {
      tracker.trackEvent("contracts_v2:payment_method_selected", {
        contract_quote_id: quote.id,
        marina_id: marina.id,
        marina_name: marina.name,
        payment_method_type: "manual",
      })
    }

    dispatch({
      type: "PAYMENT_METHOD_SELECTED",
      payload: paymentMethodSelected,
    })
  }

  return (
    <div className="flex flex-col space-y-6">
      <div>
        <h4 className="mb-2 font-semibold">Payment Method</h4>
        <AcceptedPaymentMethods
          acceptedPaymentMethods={acceptedPaymentMethods}
          allowDirectPayment={allowDirectPayment}
        />
      </div>
      <div>
        <Form.Select onChange={handleSelectChange} value={paymentMethod}>
          <option value="">Select a payment method</option>
          {paymentMethods.map((paymentMethod) => (
            <option
              key={paymentMethod.id}
              value={paymentMethod.stripePaymentMethodId}
            >
              {paymentMethod.title} {paymentMethod.subtitle}
            </option>
          ))}
          <option value="add">Add a new payment method</option>
          {allowDirectPayment && <option value="manual">Cash or Check</option>}
        </Form.Select>
        {manualPaymentSelected && (
          <div className="mt-2 px-2 text-sm text-gray-600">
            Settle with your marina directly after contract signing.
          </div>
        )}
      </div>
      {addPaymentSelected && activeSetupIntent && (
        <Elements
          stripe={stripePromise}
          options={{
            clientSecret: activeSetupIntent.clientSecret,
          }}
        >
          <AddPaymentMethodForm />
        </Elements>
      )}
    </div>
  )
}

const AcceptedPaymentMethods = ({
  acceptedPaymentMethods,
  allowDirectPayment,
}) => {
  return (
    <div className="accepted-payment-methods flex flex-col space-y-4">
      <span>The following payment methods are accepted:</span>
      <div className="flex flex-col">
        {acceptedPaymentMethods.map((paymentMethod) => (
          <div
            key={paymentMethod}
            className="inline-grid grid-cols-[20px_auto] items-center space-x-2"
          >
            <i
              className={`icon ${iconClass(
                paymentMethod
              )} align-self-center justify-self-center text-lg`}
            />{" "}
            <span>{displayText(paymentMethod)}</span>
          </div>
        ))}
        {allowDirectPayment && (
          <div className="inline-grid grid-cols-[20px_auto] items-center space-x-2">
            <i className="icon icon-cash-multiple align-self-center justify-self-center text-lg" />{" "}
            <span>Cash or Check</span>
          </div>
        )}
      </div>
    </div>
  )
}

AcceptedPaymentMethods.propTypes = {
  acceptedPaymentMethods: PropTypes.arrayOf(
    PropTypes.oneOf(["card", "us_bank_account"]).isRequired
  ).isRequired,
  allowDirectPayment: PropTypes.bool.isRequired,
}

const AddPaymentMethodForm = () => {
  const stripe = useStripe()
  const elements = useElements()
  const [isBankAccount, setIsBankAccount] = useState(false)

  const { handleSubmit, handleChange, isSubmitting, error, complete } =
    useStripeForm({ stripe, elements })

  const handleElementChange = (event) => {
    const {
      value: { type },
    } = event

    setIsBankAccount(type === "us_bank_account")
    handleChange(event)
  }

  return (
    <form className="mt-8 flex flex-col space-y-4">
      <PaymentElement onChange={handleElementChange} />
      {complete && error && <Form.Error>{error.message}</Form.Error>}
      {isBankAccount && (
        <div className="space-y-2">
          <div className="text-xs text-gray-600">
            If your bank account isn{"'"}t listed, you can add it manually in
            your{" "}
            <a href="/account/payment" className="font-semibold">
              account settings
            </a>
            . This process may take 1-2 business days, after which you can
            return to complete the signing process.
          </div>
        </div>
      )}
      <Button
        type="submit"
        variant="secondary"
        disabled={!complete || error}
        onClick={handleSubmit}
        isLoading={isSubmitting}
        fullWidth={true}
      >
        Save
      </Button>
    </form>
  )
}

export default SelectPaymentMethod
