import PropTypes from "prop-types"
import React from "react"

import NetSuite from "./Icons/NetSuite.jsx"
import QuickBooks from "./Icons/QuickBooks.jsx"
import SageIntacct from "./Icons/SageIntacct.jsx"
import Xero from "./Icons/Xero.jsx"

const AccountingServiceCard = ({ isLoading, onClick, service }) => {
  const serviceIcon = () => {
    switch (service.serviceId) {
      case "quickbooks":
        return <QuickBooks />
      case "sage-intacct":
        return <SageIntacct />
      case "netsuite":
        return <NetSuite />
      case "xero":
        return <Xero />
      default:
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.0"
            width="30"
            height="30"
          >
            <circle cx="15" cy="15" r="15" fill="#EEEEEE" />
          </svg>
        )
    }
  }

  const renderCardBody = () => {
    if (isLoading) {
      return (
        <div className="whitespace-nowrap">
          <i className="icon icon-spinner icon-spin mr-2" />
          <span>Connecting to {service.name || "Accounting System"}</span>
        </div>
      )
    } else {
      return `Connect to ${service.name || "Accounting System"}`
    }
  }

  return (
    <button
      className="flex w-1/3 cursor-pointer flex-col items-center justify-center space-y-4 rounded border bg-white p-6 hover:border-blue-600 hover:bg-blue-50"
      onClick={onClick}
      name={service}
    >
      {serviceIcon()}
      <div className="text-center text-sm font-semibold">
        {renderCardBody()}
      </div>
    </button>
  )
}

AccountingServiceCard.propTypes = {
  isLoading: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  service: PropTypes.shape({
    name: PropTypes.string.isRequired,
    serviceId: PropTypes.string.isRequired,
  }).isRequired,
}

export default AccountingServiceCard
