import PropTypes from "prop-types"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useMutation } from "react-query"

import AlertBanner from "src/components/AlertBanner"
import Button from "src/components/Button"
import Form from "src/components/Form"
import Modal from "src/components/Modal"
import ReloadableWidget from "src/components/ReloadableWidget"
import { DangerousDeprecatedOldReactAdapterComponent } from "src/components/__dangerous__"

import { cancelContract } from "src/api/Contracts"
import { cancelReservation } from "src/api/Reservation"

import DueDateField from "./DueDateField"
import RefundOptionsDropdown from "./RefundOptionsDropdown"
import { useGetRefundablePaymentMethods } from "./hooks"

export const getMultiplePaymentText = (contractQuoteId) =>
  `More than one payment method was used on this ${
    contractQuoteId ? "contract" : "reservation"
  }. To issue a refund, please remove billing products / services manually.`
export const HAS_DISPUTES_TEXT =
  "Payment(s) are under dispute. To issue a refund, please remove billing products / services manually."

const CancelContractModal = ({
  reservationId,
  contractQuoteId,
  deprecatedButtonTargetId,
  buttonVariant = "secondary",
  hasDisputes = false,
}) => {
  const MULTIPLE_PAYMENT_TEXT = getMultiplePaymentText(contractQuoteId)
  const [isOpen, setIsOpen] = useState(false)

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    setError,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues: {
      isRemovingProducts: true,
      cancellationNote: "",
    },
  })

  // Fetching Payment Methods
  const {
    data: { isMultiplePaymentRequired, refundablePaymentMethods },
    isFetching: isLoadingRefundablePaymentMethods,
    isError: isErrorRefundablePaymentMethods,
  } = useGetRefundablePaymentMethods({
    reservationId,
    options: {
      onSuccess: ({ isMultiplePaymentRequired }) => {
        if (isMultiplePaymentRequired || hasDisputes) {
          setValue("isRemovingProducts", false)
        }
      },
      select: (data) => ({
        refundablePaymentMethods: data,
        isMultiplePaymentRequired: data.length > 1,
      }),
      onError: () => {
        setError("root.serverError", {
          message: "Something went wrong! Please contact support.",
        })
      },
      placeholderData: [],
      enabled: isOpen,
    },
  })

  const onModalClose = () => {
    setIsOpen(false)
  }

  const cannotIssueRefund = isMultiplePaymentRequired || hasDisputes

  const isRemoving = watch("isRemovingProducts")

  const { mutate, isLoading, isSuccess } = useMutation({
    queryKey: ["cancel", reservationId],
    mutationFn: (data) =>
      contractQuoteId
        ? cancelContract(contractQuoteId, data)
        : cancelReservation(reservationId, data),
    onSuccess: ({ redirect_to: redirectTo }) =>
      window.location.assign(new URL(redirectTo, window.location.origin)),
    onError: () => {
      setError("root.serverError", {
        message:
          "There was a problem canceling this contract. Please try again or contact support.",
      })
    },
  })

  const onSubmit = ({ isRemovingProducts, cancellationNote, dueDate }) => {
    const formattedData = {
      cancellation_note: cancellationNote ? cancellationNote.trim() : null,
      return_product_sale_txns: isRemovingProducts,
      refund_due_date: isRemovingProducts ? dueDate : null,
      payment_method_id: cannotIssueRefund
        ? null
        : refundablePaymentMethods[0]?.payment_method_id,
    }
    mutate(formattedData)
  }

  const renderBody = () => {
    return (
      <ReloadableWidget
        isLoading={isLoadingRefundablePaymentMethods}
        isError={isErrorRefundablePaymentMethods}
      >
        <div className="flex flex-col gap-4">
          {cannotIssueRefund ? (
            <AlertBanner type="error">
              {hasDisputes ? HAS_DISPUTES_TEXT : MULTIPLE_PAYMENT_TEXT}
            </AlertBanner>
          ) : null}
          <div>
            <Form.Label htmlFor="cancellation-note">
              Cancellation Note
            </Form.Label>
            <Form.TextField
              id="cancellation-note"
              {...register("cancellationNote")}
            />
          </div>
          <div>
            <Form.Checkbox
              {...register("isRemovingProducts")}
              label="Remove all billing products / services"
              disabled={cannotIssueRefund}
            />
          </div>
          {!cannotIssueRefund &&
          refundablePaymentMethods.length === 1 &&
          isRemoving ? (
            <div className="flex flex-row gap-4">
              <div className="w-1/2">
                <RefundOptionsDropdown
                  name="refundOption"
                  control={control}
                  disabled
                />
              </div>
              <div className="w-1/2">
                <DueDateField
                  required
                  name="dueDate"
                  control={control}
                  error={errors?.dueDate}
                />
              </div>
            </div>
          ) : null}
        </div>
      </ReloadableWidget>
    )
  }

  const renderCancelBtn = () => {
    const btn = (
      <Button variant={buttonVariant} onClick={() => setIsOpen(true)}>
        Cancel {contractQuoteId ? "Contract" : "Reservation"}
      </Button>
    )

    return deprecatedButtonTargetId ? (
      <DangerousDeprecatedOldReactAdapterComponent
        targetId={deprecatedButtonTargetId}
        weAcknowledgeThisTechniqueIsDangerous
      >
        {btn}
      </DangerousDeprecatedOldReactAdapterComponent>
    ) : (
      btn
    )
  }

  return (
    <>
      {renderCancelBtn()}
      <Modal
        isOpen={isOpen}
        onClose={onModalClose}
        afterLeave={reset}
        size="mediumFixed"
      >
        <Modal.Header title="Confirm cancellation" />
        <Modal.Body>
          <Form autocomplete={false}>{renderBody()}</Form>
        </Modal.Body>
        <Modal.Footer>
          <div className="mt-4 flex flex-col gap-4">
            <div className="flex justify-end space-x-2">
              <Button variant="tertiary" onClick={onModalClose}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="danger"
                isLoading={
                  isLoadingRefundablePaymentMethods || isLoading || isSuccess
                }
                disabled={
                  isLoadingRefundablePaymentMethods || isLoading || isSuccess
                }
                onClick={handleSubmit(onSubmit)}
              >
                Cancel {contractQuoteId ? "Contract" : "Reservation"}
              </Button>
            </div>
            {errors?.root?.serverError ? (
              <Form.Error>{errors.root.serverError.message}</Form.Error>
            ) : null}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

CancelContractModal.propTypes = {
  reservationId: PropTypes.number.isRequired,
  contractQuoteId: PropTypes.number,
  hasDisputes: PropTypes.bool,
  buttonVariant: PropTypes.string,
  deprecatedButtonTargetId: PropTypes.string,
}

export default CancelContractModal
