import PropTypes from "prop-types"
import React from "react"

const PaymentMethodDescription = ({ paymentMethod }) => {
  if (!paymentMethod) {
    return "Manual"
  }

  const altText = () => {
    if (paymentMethod.type === "PaymentMethod::Card") {
      return `${paymentMethod.metadata.brand} logo`
    } else {
      return paymentMethod.title
    }
  }

  return (
    <div className="flex flex-row items-center space-x-1">
      <img className="h-5" src={paymentMethod.logo} alt={altText()} />
      <div>{paymentMethod.metadata.last4}</div>
    </div>
  )
}

PaymentMethodDescription.propTypes = {
  paymentMethod: PropTypes.shape({
    logo: PropTypes.string.isRequired,
    metadata: PropTypes.shape({
      brand: PropTypes.string,
      last4: PropTypes.string.isRequired,
    }).isRequired,
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
}

export default PaymentMethodDescription
