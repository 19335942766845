import PropTypes from "prop-types"
import React from "react"

import FormFileSelector from "src/components/Form/FormFileSelector"

import FormCheckbox from "./FormCheckbox"
import FormDatePicker, { QuickNavHeader } from "./FormDatePicker"
import FormDoubleRangeSlider from "./FormDoubleRangeSlider"
import FormError from "./FormError"
import FormIconTextField from "./FormIconTextField"
import FormLabel from "./FormLabel"
import FormRadioButton from "./FormRadioButton"
import FormSearch from "./FormSearch"
import FormSearchOption from "./FormSearchOption"
import FormSelect from "./FormSelect"
import FormSelectCustom from "./FormSelectCustom"
import FormSelectMultiOption from "./FormSelectMultiOption"
import FormSelectRichOption from "./FormSelectRichOption"
import FormSubtext from "./FormSubtext"
import FormTelephoneField from "./FormTelephoneField"
import FormTextField from "./FormTextField"
import FormTextarea from "./FormTextarea"
import FormTimePicker from "./FormTimePicker"

const Form = ({ action, children, method, onSubmit, autocomplete = true }) => {
  const props = {
    action,
    method,
    onSubmit,
    noValidate: true,
    ...(autocomplete ? {} : { autoComplete: "off" }),
  }
  return (
    <form {...props} role="form" data-design-system="Form">
      {children}
    </form>
  )
}

Form.Checkbox = FormCheckbox
Form.DatePicker = FormDatePicker
Form.DatePicker.QuickNavHeader = QuickNavHeader
Form.DoubleRangeSlider = FormDoubleRangeSlider
Form.Error = FormError
Form.IconTextField = FormIconTextField
Form.Label = FormLabel
Form.RadioButton = FormRadioButton
Form.Search = FormSearch
Form.Search.Option = FormSearchOption
Form.Select = FormSelect
Form.Select.Custom = FormSelectCustom
Form.Select.MultiOption = FormSelectMultiOption
Form.Select.RichOption = FormSelectRichOption
Form.Subtext = FormSubtext
Form.Textarea = FormTextarea
Form.TextField = FormTextField
Form.TimePicker = FormTimePicker
Form.TelephoneField = FormTelephoneField
Form.FileSelector = FormFileSelector

Form.propTypes = {
  action: PropTypes.string,
  children: PropTypes.node.isRequired,
  method: PropTypes.string,
  onSubmit: PropTypes.func,
  autocomplete: PropTypes.bool,
}

export default Form
