// This file is the entry point for Webpack, along with any other pack files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within /client and only use these pack files to create entry points.
// Load SCSS in /client/stylesheets/application.scss
import "@rails/actiontext"
import "stylesheets/application.scss"
import "trix"

// Automounter
import { automountReactElements } from "../src/automount/react-automounter"
import consumer from "../src/channels"

automountReactElements()

// ActionCable for client side code that does not have access to consumer

window.consumer = consumer
