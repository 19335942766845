import PropTypes from "prop-types"
import React, { useState } from "react"
import { useForm } from "react-hook-form"

import Button from "src/components/Button"
import Form from "src/components/Form"
import Modal from "src/components/Modal"

const Beam = ({ modifier, onClose, mergeModifierData }) => {
  const { register, setValue, getValues, handleSubmit } = useForm({
    defaultValues: {
      modifier,
    },
  })

  const intialUnits = modifier.fixedAmount ? "fixed" : "percentage"
  const [units, setUnits] = useState(intialUnits)

  const onUnitChange = (event) => {
    setUnits(event)
    setValue("modifier.fixedAmount", null)
    setValue("modifier.percentageAmount", null)
  }

  const onSaveButtonClick = (data) => {
    mergeModifierData(data)
    onClose()
  }

  const onDeleteButtonClick = () => {
    setValue("modifier.fixedAmount", null)
    setValue("modifier.percentageAmount", null)
    mergeModifierData(getValues())
    onClose()
  }

  return (
    <>
      <Modal.Body>
        <div className="w-2/3">
          <div className="flex flex-row gap-x-4">
            <div className="w-2/3">
              <Form.Label>&nbsp;</Form.Label>
              <Form.Select
                onChange={(event) => onUnitChange(event.target.value)}
              >
                <option
                  key="fixed"
                  id="fixed-amount"
                  selected={units === "fixed"}
                  value="fixed"
                >
                  Fixed Charge
                </option>
                <option
                  key="percentage"
                  id="percentage-amount"
                  value="percentage"
                  selected={units === "percentage"}
                >
                  Percentage Based Charge
                </option>
              </Form.Select>
            </div>
            <div className="w-1/3">
              <Form.Label htmlFor="beam">Beam</Form.Label>
              <Form.IconTextField
                icon={<span>{units === "fixed" ? "$" : "%"}</span>}
                position={units === "fixed" ? "left" : "right"}
                id="beam"
                {...register(
                  `modifier.${
                    units === "fixed" ? "fixedAmount" : "percentageAmount"
                  }`
                )}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="mt-5 flex justify-between">
          <Button variant="tertiary" onClick={onDeleteButtonClick}>
            Delete
          </Button>
          <div className="flex justify-end space-x-2">
            <Button variant="secondary" onClick={onClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSubmit(onSaveButtonClick)}>
              Save Changes
            </Button>
          </div>
        </div>
      </Modal.Footer>
    </>
  )
}

Beam.propTypes = {
  modifier: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  mergeModifierData: PropTypes.func.isRequired,
}

export default Beam
