import { capitalize } from "lodash"
import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import ContractGroupModal from "src/main/Contact/ContractGroupModal"
import { ContactsContext } from "src/main/Contact/index"

import DataTable from "src/components/DataTable"
import Tooltip from "src/components/Tooltip"

import { useWindowSize } from "src/hooks/use_window_size"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

const SUPPLEMENTARY_COLORS = [
  "bg-supplementary-cyan-600",
  "bg-supplementary-green-600",
  "bg-supplementary-orange-600",
  "bg-supplementary-violet-500",
  "bg-supplementary-brick-500",
  "bg-supplementary-pink-500",
  "bg-supplementary-sand-700",
  "bg-supplementary-turquoise-500",
  "bg-supplementary-lime-600",
]

const COLDEFS = [
  {
    key: "name",
    header: "Boat",
    width: "20%",
    render: (data) =>
      renderBoatCell(
        data,
        SUPPLEMENTARY_COLORS[data.id % SUPPLEMENTARY_COLORS.length]
      ),
    sortable: true,
  },
  {
    key: "contactName",
    header: "Captain",
    width: "20%",
    sortable: true,
  },
  {
    key: "boatType",
    header: "Type",
    width: "20%",
    sortable: true,
  },
  {
    key: "lengthOverall",
    header: "LOA",
    width: "15%",
    sortable: true,
  },
  {
    key: "beam",
    header: "Beam",
    width: "15%",
    sortable: true,
  },
  {
    key: "draw",
    header: "Draft",
    width: "15%",
    sortable: true,
  },
  {
    key: "height",
    header: "Height",
    width: "15%",
    sortable: true,
  },
  {
    key: "insuranceStatus",
    header: "Ins. Status",
    width: "15%",
    render: (data) =>
      renderInsuranceOrRegistrationStatusIcon(data.insuranceStatus),
  },
  {
    key: "registrationStatus",
    header: "Reg. Status",
    width: "15%",
    render: (data) =>
      renderInsuranceOrRegistrationStatusIcon(data.registrationStatus),
  },
  {
    key: "registrationNumber",
    header: "Reg. Number",
    width: "15%",
  },
]
const renderInsuranceOrRegistrationStatusIcon = (status) => {
  switch (status) {
    case "active":
      return (
        <div className="w-min">
          <Tooltip text="Active" placement="left" variant="dark">
            <i className="icon icon-check text-lg text-teal-600" />
          </Tooltip>
        </div>
      )
    case "missing":
      return (
        <div className="w-min">
          <Tooltip text="Missing" placement="left" variant="dark">
            <i className="icon icon-question-circle-btl text-lg font-bold text-gray-500" />
          </Tooltip>
        </div>
      )
    case "expired":
      return (
        <div className="w-min">
          <Tooltip text="Expired" placement="left" variant="dark">
            <i className="icon icon-exclamation-circle text-lg font-semibold text-red-600" />
          </Tooltip>
        </div>
      )
    default:
      return null
  }
}

const renderBoatCell = (contactBoat, boatPhotoColor) => (
  <div className="flex items-center space-x-2">
    <div
      className={`flex items-center justify-center rounded-full ${boatPhotoColor} text-xl font-semibold text-white`}
    >
      {contactBoat.thumbnailUrl ? (
        <img
          src={contactBoat.thumbnailUrl}
          alt={`${contactBoat.name} thumbnail`}
          className="h-9 w-9 rounded-full object-cover"
        />
      ) : (
        <span className="flex h-9 w-9 items-center justify-center">
          {contactBoat.name && capitalize(contactBoat.name[0])}
        </span>
      )}
    </div>
    <span>{contactBoat.name}</span>
  </div>
)

const ContactBoatsTable = ({
  contactBoats,
  numberOfPages,
  onColumnSort,
  onPageChange,
  page,
}) => {
  const { isLargeScreen } = useWindowSize()
  const { contractGroups, contactBoatSortDirection, contactBoatSortKey } =
    useContext(ContactsContext)
  const [showModal, setShowModal] = useState(false)
  const [selectedContact, setSelectedContact] = useState(null)
  const [selectedContactBoat, setSelectedContactBoat] = useState(null)

  const marinaSlug = getCurrentMarinaSlug()

  const rowConfig = {
    actions: (data) => {
      return [
        {
          action: "New Reservation",
          variant: "link",
          href: `/manage/${marinaSlug}/reservations/new/contact/${data.contactEncodedId}?contact_boat_id=${data.encodedId}`,
        },
        {
          action: "New Contract",
          onClick: () => {
            setSelectedContact(data.contactEncodedId)
            setSelectedContactBoat(data.encodedId)
            setShowModal(true)
          },
        },
      ]
    },
    onClick: (data) =>
      (window.location.href = `/manage/${marinaSlug}/contacts/${data.contactEncodedId}/contact_boats/${data.encodedId}/edit`),
  }

  const sortConfig = {
    sortDirection: contactBoatSortDirection,
    sortKey: contactBoatSortKey,
    onColumnSort: onColumnSort,
  }

  return (
    <>
      <DataTable
        name="contacts.boats"
        colDefs={COLDEFS}
        rowData={contactBoats}
        autoColumnWidth={!isLargeScreen}
        pagination
        page={page}
        numberOfPages={numberOfPages}
        onPageChange={onPageChange}
        rowConfig={rowConfig}
        sortConfig={sortConfig}
      />
      <ContractGroupModal
        contractGroups={contractGroups}
        isOpen={showModal}
        selectedContact={selectedContact}
        selectedContactBoat={selectedContactBoat}
        setIsOpen={setShowModal}
        setSelectedContact={setSelectedContact}
        setSelectedContactBoat={setSelectedContactBoat}
      />
    </>
  )
}

ContactBoatsTable.propTypes = {
  contactBoats: PropTypes.arrayOf(
    PropTypes.shape({
      beam: PropTypes.string,
      boatType: PropTypes.string,
      contactEncodedId: PropTypes.string,
      contactName: PropTypes.string,
      draw: PropTypes.string,
      encodedId: PropTypes.string,
      height: PropTypes.string,
      id: PropTypes.number,
      insuranceStatus: PropTypes.string,
      lengthOverall: PropTypes.string,
      name: PropTypes.string,
      registrationStatus: PropTypes.string,
    })
  ),
  numberOfPages: PropTypes.number,
  onColumnSort: PropTypes.func,
  onPageChange: PropTypes.func,
  page: PropTypes.number,
}

export default ContactBoatsTable
