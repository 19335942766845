import React, { useContext, useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useQuery } from "react-query"
import FilterChips from "src/main/Inquiries/FilterChips"
import Filters from "src/main/Inquiries/Filters"
import InquiriesTable from "src/main/Inquiries/InquiriesTable"
import SubmissionFormModal from "src/main/Inquiries/SubmissionFormModal"
import { ManageInquiriesContext } from "src/main/Inquiries/index"

import Button from "src/components/Button"
import Loader from "src/components/Loader"

import { queryInquiries } from "src/api/Inquiries"

import useDebounce from "src/hooks/use_debounce"

import {
  camelCaseToSnakecase,
  snakecaseToCamelCase,
} from "src/utils/string_helpers"
import { updateUrlQueryParams } from "src/utils/url/parsing/marina"

const Inquiries = () => {
  const {
    inquiries,
    currentInquiryPage,
    setCurrentInquiryPage,
    setFilters,
    filters,
    inquiryStatuses,
    inquiryTransactionTypes,
    MIN_LOA,
    MAX_LOA,
    queryableContractGroups,
  } = useContext(ManageInquiriesContext)
  const urlParams = new URLSearchParams(window.location.search)
  const {
    search = urlParams.get("search") || "",
    statuses = urlParams.getAll("statuses") || [],
    categories = urlParams.getAll("categories") || [],
    sortKey = urlParams.get("sort_key")
      ? snakecaseToCamelCase(urlParams.get("sort_key"))
      : "",
    sortDirection = urlParams.get("sort_direction"),
    loaMin = urlParams.get("loa_min") || "",
    loaMax = urlParams.get("loa_max") || "",
    contractGroups = urlParams.getAll("contract_groups") || [],
  } = filters
  const [isSubmissionFormModalOpen, setIsSubmissionFormModalOpen] =
    useState(false)

  const [debouncedQuery] = useDebounce(setFilters)

  const mapFiltersToApply = (filtersToApply, potentialFilters) => {
    const mapToFilterIncluded = (accumulatedFilters, filter) => {
      accumulatedFilters[filter] = filtersToApply.includes(filter)
      return accumulatedFilters
    }

    return potentialFilters.reduce(mapToFilterIncluded, {})
  }

  const methods = useForm({
    defaultValues: {
      inquirySearch: search || "",
      statuses: statuses.length
        ? mapFiltersToApply(statuses, inquiryStatuses)
        : {},
      categories: categories.length
        ? mapFiltersToApply(categories, inquiryTransactionTypes)
        : {},
      contractGroups: contractGroups.length
        ? mapFiltersToApply(
            contractGroups,
            queryableContractGroups.map((group) => group.encodedId)
          )
        : {},
      loaMin: loaMin || MIN_LOA,
      loaMax: loaMax || MAX_LOA,
      loaRange: [parseInt(loaMin || MIN_LOA), parseInt(loaMax || MAX_LOA)],
      filterQueryableContractGroups: "",
    },
  })

  const updateParams = (params) => {
    debouncedQuery(params)
    setCurrentInquiryPage(params.page)
    updateUrlQueryParams({
      search: params.search,
      active_tab: "leads",
      sort_key: params.sortKey ? camelCaseToSnakecase(params.sortKey) : null,
      sort_direction: params.sortDirection,
      statuses: params.statuses.join(","),
      categories: params.categories.join(","),
      loa_min: params.loaMin,
      loa_max: params.loaMax,
      page: params.page,
      contract_groups: params.contractGroups.join(","),
    })
  }

  const transactionTypeMapping = {
    lt_wet_storage: "Long Term Wet Storage",
    dry_storage: "Dry Storage",
    winter_storage: "Winter Storage",
    maintenance_repairs: "Maintenance / Repairs",
    boat_rental: "Boat Rental",
    ship_store: "Ship Store",
    fuel_sales: "Fuel Sales",
    st_wet_storage: "Short Term Wet Storage",
    other: "Other",
  }

  const {
    watch,
    formState: { errors },
    trigger,
    clearErrors,
  } = methods

  const {
    isLoading: inquiryQueryLoading,
    data: inquiryData,
    isError: contactsQueryIsError,
  } = useQuery(
    [
      "inquirySearch",
      currentInquiryPage,
      search,
      statuses,
      categories,
      sortKey,
      sortDirection,
      loaMin,
      loaMax,
      contractGroups,
    ],
    () =>
      queryInquiries({
        search,
        page: currentInquiryPage,
        statuses,
        categories,
        loaMin,
        loaMax,
        sortDirection,
        sortKey,
        contractGroups,
      }),
    {
      enabled:
        !errors.loaMin &&
        !errors.loaMax &&
        parseInt(watch("loaMin")) <= parseInt(watch("loaMax")) &&
        parseInt(watch("loaMin")) >= MIN_LOA &&
        parseInt(watch("loaMax")) <= MAX_LOA,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      initialData: inquiries,
      keepPreviousData: true,
    }
  )

  useEffect(() => {
    trigger("loaMin")
    trigger("loaMax")

    if (!errors.loaMin) {
      clearErrors("loaMin")
    }
    if (!errors.loaMax) {
      clearErrors("loaMax")
    }
    //   eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaMax, loaMin])

  const handleSort = (key) => {
    let newSortDirection = null
    let newSortKey = key
    if (sortKey && snakecaseToCamelCase(sortKey) === key) {
      if (sortDirection === "asc") {
        newSortDirection = "desc"
      } else {
        newSortKey = null
      }
    } else {
      newSortDirection = "asc"
    }

    updateParams({
      ...filters,
      sortKey: newSortKey ? camelCaseToSnakecase(newSortKey) : newSortKey,
      sortDirection: newSortDirection,
      page: 1,
    })
  }

  const handlePageChange = (selectedPage) => {
    updateParams({
      ...filters,
      page: selectedPage.selected,
    })
  }

  const renderTable = () => {
    if (inquiryQueryLoading) {
      return <Loader name="Leads" />
    }

    if (contactsQueryIsError) {
      return (
        <div className="text-muted p-5 text-center">
          <h3 className="mb-5 text-lg font-semibold">Error loading leads</h3>
        </div>
      )
    }

    if (inquiryData?.inquiries?.length) {
      return (
        <InquiriesTable
          inquiries={inquiryData.inquiries}
          page={currentInquiryPage}
          onPageChange={handlePageChange}
          onColumnSort={handleSort}
          numberOfPages={inquiryData.totalPages}
        />
      )
    }

    return (
      <div className="text-muted p-5 text-center">
        <h3 className="mb-5 text-lg font-semibold">No leads found</h3>
      </div>
    )
  }

  const renderFilters = () => (
    <>
      <div className="flex items-center justify-between">
        <Filters
          updateParams={updateParams}
          transactionTypeMapping={transactionTypeMapping}
        />
        <Button
          variant="secondary"
          onClick={() => setIsSubmissionFormModalOpen(true)}
        >
          Customer Submission Forms
        </Button>
      </div>
      <FilterChips
        updateParams={updateParams}
        transactionTypeMapping={transactionTypeMapping}
      />
    </>
  )

  return (
    <FormProvider {...methods}>
      <div className="h-[75vh] bg-white">
        {renderFilters()}
        <div className="z-0 mt-4 overflow-auto pb-12">{renderTable()}</div>
      </div>
      <SubmissionFormModal
        setIsOpen={setIsSubmissionFormModalOpen}
        isOpen={isSubmissionFormModalOpen}
      />
    </FormProvider>
  )
}

export default Inquiries
