export const TABS = {
  itinerary: "itinerary",
  rate: "rate",
  installments: "installments",
  addItems: "items",
  documents: "documents",
  contactAndBoat: "contact_and_boat",
  paymentMethod: "payment_methods",
  additionalSettings: "additional_settings",
  templatesDocuments: "templates_documents",
}

export const VALIDATED_FIELDS_BY_TAB = {
  itinerary: ["name", "startDate", "billingCycle", "endDate", "rate.dueDay"],
  installments: ["contractInstallments"],
  rate: [
    "rate.amount",
    "rate.taxRate",
    "rate.pricingStructure",
    "rate.longTermStorageProductId",
  ],
  contactAndBoat: ["contact_id", "contact_boat_id"],
  addItems: ["posItemsData"],
  documents: [],
  paymentMethod: ["acceptedPaymentMethods"],
  additionalSettings: [],
  templatesDocuments: ["selectedTemplateId"],
}

export const INITIAL_TAB_ORDER = [
  TABS.itinerary,
  TABS.rate,
  TABS.paymentMethod,
  TABS.installments,
  TABS.addItems,
  TABS.documents,
]

export const INITIAL_TAB_ORDER_WITH_TEMPLATES = [
  TABS.itinerary,
  TABS.rate,
  TABS.paymentMethod,
  TABS.installments,
  TABS.addItems,
  TABS.templatesDocuments,
  TABS.additionalSettings,
]

export const PAYMENT_METHODS = {
  bank: "us_bank_account",
  card: "card",
}
