import PropTypes from "prop-types"
import React, { useState } from "react"
import { Boatus, SafeHarbor } from "src/main/Account/Settings/MembershipIcons"
import MembershipModal from "src/main/Account/Settings/MembershipModal"
import RemoveMembershipModal from "src/main/Account/Settings/RemoveMembershipModal"

import Button from "src/components/Button"
import HeadingPageTitle from "src/components/Heading/HeadingPageTitle"

const Memberships = ({ user, setUser }) => {
  const [selectedMembership, setSelectedMembership] = useState(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)

  const handleUpdateMembership = (membership) => {
    const isBoatus = membership === "BoatUS"
    const membershipData = {
      title: membership,
      id: isBoatus ? user.boatusId : user.brewerId,
      firstName: user.brewerFirstName,
      lastName: isBoatus ? user.boatusLastName : user.brewerLastName,
      zip: user.boatusZip,
    }

    setSelectedMembership(membershipData)
    setIsModalOpen(true)
  }

  const handleRemoveMembership = (membership) => {
    const isBoatus = membership === "BoatUS"
    const membershipData = {
      title: membership,
      id: isBoatus ? user.boatusId : user.brewerId,
      firstName: user.brewerFirstName,
      lastName: isBoatus ? user.boatusLastName : user.brewerLastName,
      zip: user.boatusZip,
    }

    setSelectedMembership(membershipData)
    setIsRemoveModalOpen(true)
  }

  const renderMembership = (title, id, name) => {
    if (id) {
      return (
        <div className="card col-span-12 rounded-lg bg-white shadow-md md:col-span-6 xl:col-span-4">
          <div className="flex items-center justify-between">
            <h2 className="m-0 text-xl font-semibold">{title}</h2>
            <div className="flex space-x-1">
              <Button
                variant="ghost-tertiary"
                iconOnly
                icon="icon-edit-square-md text-xl"
                small
                onClick={() => handleUpdateMembership(title)}
              />
              <Button
                variant="ghost-tertiary"
                iconOnly
                icon="icon-sf-trashcan text-lg"
                small
                onClick={() => handleRemoveMembership(title)}
              />
            </div>
          </div>
          <div className="mt-18 flex items-end justify-between">
            <div className="flex flex-col space-y-1">
              <span className="text-2xl font-semibold">{id}</span>
              <span className="text-gray-600">{name}</span>
            </div>
            <div>{title === "BoatUS" ? <Boatus /> : <SafeHarbor />}</div>
          </div>
        </div>
      )
    } else {
      return (
        <div className="card col-span-12 rounded-lg bg-white shadow-md md:col-span-6 xl:col-span-4">
          <div className="flex flex-col justify-center p-4 text-center">
            <h2 className="m-0 text-xl font-semibold">{title}</h2>
            <div className="pb-4 pt-2">
              <p className="text-gray-600">No membership added</p>
            </div>
            <div>
              <Button
                variant="secondary"
                onClick={() => handleUpdateMembership(title)}
              >
                Add Membership
              </Button>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <div>
      <div className="mb-8 mt-1">
        <HeadingPageTitle>Memberships</HeadingPageTitle>
      </div>
      <div className="grid grid-cols-12 gap-8">
        {renderMembership(
          "BoatUS",
          user.boatusId,
          `${user.boatusLastName} ${user.boatusZip}`
        )}
        {renderMembership(
          "Safe Harbor",
          user.brewerId,
          `${user.brewerFirstName} ${user.brewerLastName}`
        )}
      </div>
      <MembershipModal
        isOpen={isModalOpen}
        setIsOpen={setIsModalOpen}
        membership={selectedMembership}
        setUser={setUser}
      />
      <RemoveMembershipModal
        isOpen={isRemoveModalOpen}
        setIsOpen={setIsRemoveModalOpen}
        setUser={setUser}
        membership={selectedMembership}
      />
    </div>
  )
}

Memberships.propTypes = {
  user: PropTypes.object.isRequired,
  setUser: PropTypes.func.isRequired,
}

export default Memberships
