import format from "date-fns/format"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { useMutation, useQueryClient } from "react-query"

import Card from "src/components/Card"
import OverflowMenu from "src/components/OverflowMenu"

import {
  archiveContractTemplate,
  restoreContractTemplate,
} from "src/api/Contracts"

import { useToast } from "src/hooks/use_toast"

import { getCurrentMarinaSlug } from "src/utils/url/parsing/marina"

import { TemplatesContext } from "../TemplatesTab"
import { ManageContractsContext } from "../index"

const TemplateCard = ({ template }) => {
  const { templateStatusView } = useContext(ManageContractsContext)
  const activeStatus = templateStatusView === "active"
  const marinaSlug = getCurrentMarinaSlug()
  const showToast = useToast()
  const queryClient = useQueryClient()

  const {
    setCurrentTemplate,
    setIsModalOpen,
    canCreateNewContractGroup,
    canUpdateContractGroup,
  } = useContext(TemplatesContext)

  const handleDuplicateClick = () => {
    setCurrentTemplate(template)
    setIsModalOpen(true)
  }

  const handleArchiveClick = () => {
    archiveTemplate(template.id)
  }

  const { mutate: archiveTemplate } = useMutation(
    () => {
      return archiveContractTemplate(template.id)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "contractTemplates",
          marinaSlug,
          templateStatusView,
        ])
        showToast(`${template.name} successfully archived`, {
          type: "success",
        })
      },
      onError: (error) => {
        showToast(error.message, { type: "error" })
      },
    }
  )

  const { mutate: restoreTemplate, isLoading } = useMutation(
    () => {
      return restoreContractTemplate(template.id)
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "contractTemplates",
          marinaSlug,
          templateStatusView,
        ])
        showToast(`${template.name} successfully restored`, {
          type: "success",
        })
      },
      onError: (error) => {
        showToast(error.message, { type: "error" })
      },
    }
  )

  const renderOverflowMenu = () => (
    <OverflowMenu variant="tableRow">
      <OverflowMenu.Item label="Duplicate" onClick={handleDuplicateClick} />
      <OverflowMenu.Item
        label="Archive"
        onClick={handleArchiveClick}
        tooltipText="Any contract group using this template will continue to function normally"
        tooltipVariant="dark"
      />
    </OverflowMenu>
  )

  const cta = {
    href: template.editUrl,
    isLoading: isLoading,
    label: activeStatus ? "Edit Template" : "Restore",
    onClick: !activeStatus ? restoreTemplate : null,
    variant: "secondary",
  }

  const badge = {
    text: "Incomplete template",
    color: "yellow",
    icon: "exclamation-circle text-yellow-900 font-semibold",
    tooltipText:
      "This template is incomplete. Make sure your template includes at least one document and at least one boater field (signature, initial, etc.)",
    tooltipPlacement: "top",
    tooltipVariant: "dark",
  }

  const renderImage = () => (
    <div className="h-72 overflow-hidden rounded-t bg-gray-300 p-6 sm:h-64 md:h-48">
      <img
        className="w-full object-contain"
        src={template.previewImageUrl}
        alt={template.name}
      />
    </div>
  )

  return (
    <Card
      badges={!template.complete && [badge]}
      cta={canUpdateContractGroup ? cta : null}
      renderImage={renderImage}
    >
      <div className="flex w-full justify-between rounded-b bg-white p-4">
        <div className="flex flex-col">
          <span className="w-48 overflow-hidden truncate font-semibold xl:w-32">
            {template.name}
          </span>
          <span className="text-muted">
            Modified {format(new Date(template.updatedAt), "M/dd/yyyy")}
          </span>
        </div>
        {canCreateNewContractGroup && activeStatus && (
          <div className="flex justify-end">{renderOverflowMenu()}</div>
        )}
      </div>
    </Card>
  )
}

TemplateCard.propTypes = {
  template: PropTypes.shape({
    editUrl: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    previewImageUrl: PropTypes.string,
    updatedAt: PropTypes.string,
    complete: PropTypes.bool.isRequired,
  }),
}

export default TemplateCard
