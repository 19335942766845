import Decimal from "decimal.js"

export const validateDiscountAmount = (selectedAmountType) => (amount) => {
  if (!amount && amount !== 0) {
    return "Discount amount is required."
  }
  const numericAmount = new Decimal(amount)
  if (numericAmount.lte(0)) {
    return "Discount cannot be less than or equal to 0."
  }
  if (selectedAmountType === "percent" && numericAmount.gt(100)) {
    return "Discount cannot be greater than 100%."
  }
  if (selectedAmountType === "percent" && !numericAmount.mod(1).eq(0)) {
    return "Discount percent must be a whole number."
  }
}

export const validateName = (name) => {
  if (!(name ?? "").trim()) {
    return "Name is required."
  }
}
