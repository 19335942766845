import PropTypes from "prop-types"
import React, { createContext, useCallback, useEffect, useState } from "react"

import Tabs from "src/components/Tabs"

import { useTracker } from "src/hooks/use_tracker"

import { updateUrlQueryParams } from "src/utils/url/parsing/marina"

import ContractGroupsTab from "./ContractGroupsTab"
import ContractGroupsTabLegacy from "./ContractGroupsTabLegacy"
import ContractsTab from "./ContractsTab"
import TemplatesTab from "./TemplatesTab"

export const ManageContractsContext = createContext(null)
const refreshRate = 300 * 1000 // 5 minutes

const ManageContracts = ({
  archivedGroupsPagePath,
  canCreateNewContractGroup,
  canUpdateContractGroup,
  contractSigningUpdatesEnabled,
  defaultPageLimit,
  hasLegacyDocuments,
  filterOptions,
  manageDocumentsPath,
  marinaName,
  newContractGroupPath,
  templates,
  templatesPath,
}) => {
  const urlParams = new URLSearchParams(window.location.search)
  const activeTab = urlParams.get("active_tab")
  const newGroup = urlParams.get("newGroup")
  const status = urlParams.get("status")
  const page = urlParams.get("page")
  const sortField = urlParams.get("sort_field")
  const sortDirection = urlParams.get("sort_direction")
  const [currentTab, setCurrentTab] = useState(0)
  const [currentGroupPage, setCurrentGroupPage] = useState(
    page && activeTab === "groups" ? parseInt(page) : 1
  )
  const [currentContractPage, setCurrentContractPage] = useState(
    page && activeTab === "contracts" ? parseInt(page) : 1
  )
  const [groupStatusView, setGroupStatusView] = useState(
    status && activeTab === "groups" ? status : "active"
  )
  const [templateStatusView, setTemplateStatusView] = useState(
    status && activeTab === "templates" ? status : "active"
  )
  const [groupSortField, setGroupSortField] = useState(
    sortField && activeTab === "groups" ? sortField : "updated_at"
  )
  const [groupSortDirection, setGroupSortDirection] = useState(
    sortDirection && activeTab === "groups" ? sortDirection : "DESC"
  )

  const defaultContractFilters = {
    contractGroups: [],
    page: 1,
    searchTerm: "",
    sortDirection: "DESC",
    sortField: "created_at",
    statuses: [],
  }

  const getInitialFilterState = () => {
    const urlParams = new URLSearchParams(window.location.search)

    return {
      contractGroups:
        urlParams.getAll("contract_groups") ||
        defaultContractFilters.contractGroups,
      page: urlParams.get("page")
        ? parseInt(urlParams.get("page"))
        : defaultContractFilters.page,
      searchTerm:
        urlParams.get("search_term") || defaultContractFilters.searchTerm,
      sortDirection:
        urlParams.get("sort_direction") || defaultContractFilters.sortDirection,
      sortField:
        urlParams.get("sort_field") || defaultContractFilters.sortField,
      statuses: urlParams.getAll("statuses") || defaultContractFilters.statuses,
    }
  }

  const [contractFilters, setContractFilters] = useState(
    getInitialFilterState()
  )

  const tracker = useTracker()
  const tabName = useCallback(
    (tab) => {
      if (contractSigningUpdatesEnabled) {
        switch (tab) {
          case 0:
            return "templates"
          case 1:
            return "groups"
          case 2:
            return "contracts"
          default:
            return "groups"
        }
      } else {
        switch (tab) {
          case 0:
            return "groups"
          case 1:
            return "contracts"
          default:
            return "groups"
        }
      }
    },
    [contractSigningUpdatesEnabled]
  )

  const handleTabChange = (tab) => {
    setCurrentTab(tab)
    updateUrlQueryParams({ active_tab: tabName(tab) })
    tracker.trackEvent(`contracts:${tabName(tab)}_tab_pressed`)
  }

  useEffect(() => {
    if (contractSigningUpdatesEnabled) {
      if (activeTab === "templates") {
        setCurrentTab(0)
        updateUrlQueryParams({
          active_tab: tabName(0),
          status: templateStatusView,
        })
      } else if (activeTab === "contracts") {
        setCurrentTab(2)
        updateUrlQueryParams({
          active_tab: tabName(2),
          page: currentContractPage,
          search_term: contractFilters.searchTerm,
          sort_field: contractFilters.sortField,
          sort_direction: contractFilters.sortDirection,
          statuses: contractFilters.statuses,
          contract_groups: contractFilters.contractGroups,
        })
      } else {
        setCurrentTab(1)
        updateUrlQueryParams({
          active_tab: tabName(1),
          status: groupStatusView,
          sort_field: groupSortField,
          sort_direction: groupSortDirection,
        })
      }
    } else {
      if (activeTab === "contracts") {
        setCurrentTab(1)
        updateUrlQueryParams({
          active_tab: tabName(2),
          page: currentContractPage,
          search_term: contractFilters.searchTerm,
          sort_field: contractFilters.sortField,
          sort_direction: contractFilters.sortDirection,
          statuses: contractFilters.statuses,
          contract_groups: contractFilters.contractGroups,
        })
      } else {
        setCurrentTab(0)
        updateUrlQueryParams({
          active_tab: tabName(0),
          page: currentGroupPage,
        })
      }
    }
  }, [
    activeTab,
    contractSigningUpdatesEnabled,
    currentContractPage,
    currentGroupPage,
    groupSortDirection,
    groupSortField,
    groupStatusView,
    tabName,
    templateStatusView,
  ])

  return (
    <ManageContractsContext.Provider
      value={{
        archivedGroupsPagePath,
        canCreateNewContractGroup,
        canUpdateContractGroup,
        contractFilters,
        contractSigningUpdatesEnabled,
        currentContractPage,
        currentGroupPage,
        defaultContractFilters,
        defaultPageLimit,
        groupSortDirection,
        groupSortField,
        groupStatusView,
        hasLegacyDocuments,
        manageDocumentsPath,
        marinaName,
        newContractGroupPath,
        newGroup,
        refreshRate,
        setContractFilters,
        setCurrentContractPage,
        setCurrentGroupPage,
        setGroupSortDirection,
        setGroupSortField,
        setGroupStatusView,
        setTemplateStatusView,
        templates,
        templatesPath,
        templateStatusView,
      }}
    >
      <div className="h-[75vh] bg-white px-0 pt-8 lg:px-6 lg:pt-8">
        <Tabs
          selectedIndex={currentTab}
          onChange={(tab) => handleTabChange(tab)}
        >
          <div className="flex w-full flex-col justify-between lg:flex-row">
            <div className="order-2 flex justify-center border-b lg:order-1 lg:border-none">
              <Tabs.TabList>
                {contractSigningUpdatesEnabled && (
                  <Tabs.Tab title="Templates" />
                )}
                <Tabs.Tab title="Groups" />
                <Tabs.Tab title="Contracts" />
              </Tabs.TabList>
            </div>
          </div>
          <hr className="mt-0" />
          <Tabs.Panels>
            {contractSigningUpdatesEnabled && (
              <Tabs.Panel>
                <TemplatesTab />
              </Tabs.Panel>
            )}
            <Tabs.Panel>
              {contractSigningUpdatesEnabled ? (
                <ContractGroupsTab />
              ) : (
                <ContractGroupsTabLegacy />
              )}
            </Tabs.Panel>
            <Tabs.Panel>
              <ContractsTab filterOptions={filterOptions} />
            </Tabs.Panel>
          </Tabs.Panels>
        </Tabs>
      </div>
    </ManageContractsContext.Provider>
  )
}

ManageContracts.propTypes = {
  archivedGroupsPagePath: PropTypes.string.isRequired,
  filterOptions: PropTypes.shape({
    contractGroupOptions: PropTypes.array,
    sortOptions: PropTypes.array,
    statusOptions: PropTypes.array,
  }).isRequired,
  canCreateNewContractGroup: PropTypes.bool.isRequired,
  canUpdateContractGroup: PropTypes.bool.isRequired,
  contractSigningUpdatesEnabled: PropTypes.bool.isRequired,
  defaultPageLimit: PropTypes.number.isRequired,
  hasLegacyDocuments: PropTypes.bool.isRequired,
  manageDocumentsPath: PropTypes.string.isRequired,
  marinaName: PropTypes.string.isRequired,
  newContractGroupPath: PropTypes.string.isRequired,
  templates: PropTypes.array.isRequired,
  templatesPath: PropTypes.string.isRequired,
}

export default ManageContracts
