import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useState } from "react"

import Button from "src/components/Button"
import Table from "src/components/Table/index"
import Tooltip from "src/components/Tooltip"

import { formattedCentsToDollars } from "src/utils/UnitConversion"

import { hasDisputedInvoice } from "../helpers"
import ItemRow from "./ItemRow"
import { itemDatesAndSubtext } from "./helpers"

const RecurringItemRow = ({
  items,
  onViewingItem,
  onRemovingItem,
  onEditingRecurringProductSale,
  reservationCheckOutDate,
  editable = true,
}) => {
  const [expanded, setExpanded] = useState(false)

  const {
    id,
    product_sale: {
      name,
      recurring_product_sale: {
        start_date: startDate,
        end_date: endDate,
        product: { category },
      },
    },
    child_txns: childTxns,
  } = items[0]

  const monthToMonth = endDate === null

  const [dates, datesSubtext] = itemDatesAndSubtext({
    startDate,
    endDate,
    monthToMonth,
  })

  const balance = items.reduce((acc, item) => acc + item.balance, 0)
  const returned =
    childTxns && childTxns.length !== 0 && balance <= 0 && !monthToMonth

  const eligibleToEdit =
    category === "long_term_storage" ||
    !items[0].product_sale.recurring_product_sale.end_date

  const renderAngleIcon = () =>
    expanded ? (
      <i className="icon icon-angle-up mr-2 mt-0.5 text-xs" />
    ) : (
      <i className="icon icon-angle-down mr-2 mt-0.5 text-xs" />
    )

  const renderItemRowWithoutName = (item) => {
    const itemWithoutName = {
      ...item,
      product_sale: { ...item.product_sale, name: "" },
    }

    return (
      <ItemRow
        key={item.id}
        item={itemWithoutName}
        onViewingItem={onViewingItem}
        onRemovingItem={onRemovingItem}
        reservationCheckOutDate={reservationCheckOutDate}
      />
    )
  }

  const handleEditClick = (event) => {
    event.stopPropagation()
    onEditingRecurringProductSale(
      items[0].product_sale.recurring_product_sale,
      items[items.length - 1].product_sale.service_end_date
    )
  }

  const recurringItemHasDisputedInvoice = items.some((item) =>
    hasDisputedInvoice(item.invoices)
  )

  const renderEditButton = () => {
    return recurringItemHasDisputedInvoice ? (
      <Tooltip
        text="This item is under dispute. It can't be edited until the dispute is resolved."
        placement="top"
        variant="dark"
      >
        <Button disabled={true} onClick={handleEditClick}>
          Edit
        </Button>
      </Tooltip>
    ) : (
      <Button onClick={handleEditClick} disabled={!editable}>
        Edit
      </Button>
    )
  }

  return (
    <>
      <Table.Row
        key={id}
        variant="parent"
        onClick={() => setExpanded(!expanded)}
      >
        <Table.Cell>
          <div className="flex">
            {renderAngleIcon()}
            <span
              className={classNames("overflow-hidden truncate", {
                "line-through": returned,
              })}
            >
              {name}
            </span>
          </div>
        </Table.Cell>
        <Table.Cell
          title={dates}
          subtitle={datesSubtext}
          variant={returned ? "strikethrough" : "default"}
        />
        <Table.Cell title="-" />
        <Table.Cell title="-" />
        <Table.Cell title={formattedCentsToDollars(balance)} />
        <Table.Cell>{eligibleToEdit && renderEditButton()}</Table.Cell>
      </Table.Row>
      {expanded && items.map(renderItemRowWithoutName)}
    </>
  )
}

RecurringItemRow.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      balance: PropTypes.number.isRequired,
      product_sale: PropTypes.shape({
        name: PropTypes.string.isRequired,
        recurring_product_sale: PropTypes.shape({
          start_date: PropTypes.string.isRequired,
          end_date: PropTypes.string,
          product: PropTypes.shape({
            category: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired,
        service_end_date: PropTypes.string.isRequired,
      }).isRequired,
      invoices: PropTypes.array,
      child_txns: PropTypes.array,
    })
  ).isRequired,
  onViewingItem: PropTypes.func.isRequired,
  onRemovingItem: PropTypes.func.isRequired,
  onEditingRecurringProductSale: PropTypes.func.isRequired,
  reservationCheckOutDate: PropTypes.string,
  editable: PropTypes.bool,
}

export default RecurringItemRow
