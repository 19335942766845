import React, { useContext } from "react"

import Button from "src/components/Button"
import MaybeRestrictedTooltip from "src/components/Tooltip/MaybeRestrictedTooltip"

import EmptyStateImage from "../EmptyStateImage"
import { POSContext } from "../Root"
import { QuickKeyPanelContext } from "../index"

const QuickKeyEmptyState = () => {
  const { setShowNewLayoutModal } = useContext(QuickKeyPanelContext)
  const { marinaAccess } = useContext(POSContext)
  const cannotUseLayouts = marinaAccess.layoutLimit === 0

  return (
    <div className="mt-8 flex flex-col items-center">
      <EmptyStateImage />
      <p className="text-lg">Add Quick Keys to speed things up.</p>
      <MaybeRestrictedTooltip
        text={
          cannotUseLayouts
            ? "The ability to create layouts and quick keys is available within the POS module.\nContact your Dockwa representative or our support team to learn more."
            : null
        }
        restricted={cannotUseLayouts}
        allowedRoles={["Admin", "Finance"]}
      >
        <Button
          variant="secondary"
          disabled={cannotUseLayouts}
          onClick={() => setShowNewLayoutModal(true)}
        >
          Create a New Layout
        </Button>
      </MaybeRestrictedTooltip>
    </div>
  )
}

export default QuickKeyEmptyState
