import PropTypes from "prop-types"
import React from "react"

import Form from "src/components/Form"

const reservationStartOption = {
  value: "reservation_check_in",
  display: "Due on reservation start",
}
const reservationEndOption = {
  value: "reservation_check_out",
  display: "Due on reservation end",
}
const getAddToNextPaymentOption = ({ disabled = false } = {}) => ({
  value: "add_to_next_payment",
  display: "Add to next payment",
  disabled,
})
const customDueDateOption = {
  value: "custom_due_date",
  display: "Custom due date",
}
const schedulePaymentOption = {
  value: "custom_due_date",
  display: "Schedule payment",
}
const dueOnSignatureOption = {
  value: "due_on_signature",
  display: "Due on signature",
}
const installmentsOption = {
  value: "add_to_installments",
  display: "Add to installments",
}

const getPaymentScheduleOptions = ({
  hasScheduledInvoice,
  hasReservation,
  dateType,
  priceType,
  isMonthToMonth,
}) => {
  const isPerDay = priceType === "per_day"
  const perDayOptions = isPerDay
    ? [reservationStartOption, reservationEndOption]
    : []
  if (hasReservation) {
    if (dateType === "wholeReservation" && isPerDay) {
      return perDayOptions
    }
    if (hasScheduledInvoice) {
      return [
        ...perDayOptions,
        getAddToNextPaymentOption(),
        schedulePaymentOption,
      ]
    } else {
      return [
        ...perDayOptions,
        getAddToNextPaymentOption({ disabled: true }),
        schedulePaymentOption,
      ]
    }
  } else if (isMonthToMonth) {
    return [customDueDateOption, dueOnSignatureOption]
  } else {
    return [customDueDateOption, dueOnSignatureOption, installmentsOption]
  }
}

const PaymentScheduleDropdown = ({
  register,
  disabled,
  errors,
  label,
  hasScheduledInvoice,
  hasReservation,
  priceType,
  dateType,
  isMonthToMonth,
}) => {
  return (
    <div
      className={`flex ${
        priceType === "per_day" ? "w-full" : "w-1/2"
      } flex-col`}
    >
      <Form.Label htmlFor="payment-schedule">{label}</Form.Label>
      <Form.Select
        id="payment-schedule"
        {...register("payment_schedule")}
        disabled={disabled}
        hasErrors={Boolean(errors.payment_schedule)}
      >
        {getPaymentScheduleOptions({
          hasScheduledInvoice,
          hasReservation,
          dateType,
          priceType,
          isMonthToMonth,
        }).map((opt, i) => (
          <option
            key={`${opt.value}-${i}`}
            value={opt.value}
            disabled={opt.disabled}
          >
            {opt.display}
          </option>
        ))}
      </Form.Select>
      {errors.payment_schedule && (
        <Form.Error>{errors.payment_schedule.message}</Form.Error>
      )}
    </div>
  )
}

PaymentScheduleDropdown.propTypes = {
  register: PropTypes.func.isRequired,
  errors: PropTypes.shape({
    payment_schedule: PropTypes.shape({
      message: PropTypes.string,
    }),
    custom_due_date: PropTypes.shape({
      message: PropTypes.string,
    }),
  }).isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  hasScheduledInvoice: PropTypes.bool,
  hasReservation: PropTypes.bool.isRequired,
  priceType: PropTypes.oneOf([
    "per_day",
    "per_quantity",
    "percent_of_reservation_sale",
  ]),
  dateType: PropTypes.oneOf(["wholeReservation", "custom"]),
  isMonthToMonth: PropTypes.bool.isRequired,
}

PaymentScheduleDropdown.defaultProps = {
  label: "Payment options",
  disabled: false,
  hasScheduledInvoice: false,
}

export default PaymentScheduleDropdown
