import PropTypes from "prop-types"
import React, { useContext, useState } from "react"

import Button from "src/components/Button"
import { DangerousDeprecatedOldReactAdapterComponent } from "src/components/__dangerous__"

import { ReservationDatesContext } from "../ReservationDatesContext"
import { SharedBillingContext } from "../SharedBillingContext"
import { hasDisputedInvoice, sortItems } from "../helpers"
import AddItemModal from "./AddItemModal"
import EditInstallmentStay from "./EditInstallmentStay"
import EditRecurringProductSaleModal from "./EditRecurringProductSaleModal"
import ItemDetailsModal from "./ItemDetailsModal"
import ItemsTable from "./ItemsTable"
import RemoveItemModal from "./RemoveItemModal"
import {
  getEffectiveBalance,
  getItemsForStorageRecurringProductSale,
  getReservationLinkedItems,
  sortByLatestPostedAt,
} from "./helpers"

const Items = ({
  items,
  ledgerId,
  contractQuoteId,
  reservationId,
  paymentMethods,
  refreshItems,
  refreshPayments,
  isLoading,
  isError,
  isMonthToMonth,
  hasScheduledInvoice,
  pricingUrl,
  monthlyRateOptions,
  contactBoat,
  ratePreservationEnabled,
  hasPreservedRates,
  editable = true,
}) => {
  const {
    items: { data: fullItemsData },
  } = useContext(SharedBillingContext)
  const { reservationCheckInDate, reservationCheckOutDate } = useContext(
    ReservationDatesContext
  )

  const [isAdding, setIsAdding] = useState(false)
  const [viewingItem, setViewingItem] = useState(null)
  const [removingItem, setRemovingItem] = useState(null)
  const [editingRecurringProductSale, setEditingRecurringProductSale] =
    useState(null)
  const [
    recurringProductSaleDefaultEndDate,
    setRecurringProductSaleDefaultEndDate,
  ] = useState(null)

  const defaultPaymentMethodId = paymentMethods.default?.id

  const onItemAdded = () => {
    refreshItems()
    refreshPayments()
    setIsAdding(false)
  }

  const onItemRemoved = () => {
    refreshItems()
    refreshPayments()
    onRemoveItemClose()
  }

  // This function is curried by the item rows and used when a user clicks
  // "View details" in an item's overflow menu
  const onViewingItem = (item) => () => {
    if (!fullItemsData?.length) {
      return // sanity check
    }
    const relatedItems = fullItemsData.filter(
      (i) =>
        i.product_sale.product_id === item.product_sale.product_id &&
        i.product_sale.reservation_dates === item.product_sale.reservation_dates
    )
    // since we've collapsed down the rows of reservation dates items in the Items table,
    // we want to display all of them in the Item Details Modal if we're clicking
    // on a reservation dates item
    setViewingItem(relatedItems)
  }

  // This function is curried by the item rows and used when a user clicks
  // "Remove" in an item's overflow menu
  const onRemovingItem = (item) => () => {
    setRemovingItem(item)
  }

  const onViewItemClose = () => {
    setViewingItem(null)
  }

  const onRemoveItemClose = () => {
    setRemovingItem(null)
  }

  const onEditingRecurringProductSale = (
    recurringProductSale,
    recurringProductSaleDefaultEndDate
  ) => {
    setRecurringProductSaleDefaultEndDate(recurringProductSaleDefaultEndDate)
    setEditingRecurringProductSale(recurringProductSale)
  }

  const onEditingRecurringProductSaleClose = (reload = true) => {
    setEditingRecurringProductSale(null)
    if (reload === true) {
      refreshItems()
      refreshPayments()
    }
  }

  const addStatus = (item) => ({
    ...item,
    status: getEffectiveBalance(item) === 0 ? "Settled" : "Open",
  })

  const itemsWithStatus = items ? sortItems(items.map(addStatus)) : undefined
  const reservationSale = items?.find(
    (item) => item.product_sale.reservation_sale && item.net_amount !== 0
  )

  const renderLegacyHeaderProductSaleEditButton = () => {
    if (items.find((item) => hasDisputedInvoice(item.invoices))) {
      return
    }
    let editComponent = null
    const recurringProductSaleItems =
      getItemsForStorageRecurringProductSale(items)

    if (!isMonthToMonth) {
      editComponent = (
        <EditInstallmentStay
          isLoading={isLoading}
          installmentProductSaleTxn={items
            .sort(sortByLatestPostedAt)
            .find((item) => item.product_sale.reservation_sale)}
          paymentMethods={paymentMethods}
          dateAffectedItems={getReservationLinkedItems(items)}
          onClose={onEditingRecurringProductSaleClose}
          reservationId={reservationId}
          contractQuoteId={contractQuoteId}
          ratePreservationEnabled={ratePreservationEnabled}
          hasPreservedRates={hasPreservedRates}
        />
      )
    } else if (recurringProductSaleItems) {
      const recurringProductSale =
        recurringProductSaleItems[0].product_sale.recurring_product_sale
      const defaultEndDate =
        recurringProductSaleItems[recurringProductSaleItems.length - 1]
          .product_sale.service_end_date
      editComponent = (
        <Button
          variant="secondary"
          onClick={() =>
            onEditingRecurringProductSale(recurringProductSale, defaultEndDate)
          }
        >
          <i className="icon icon-edit-square mr-2" />
          Edit
        </Button>
      )
    }
    if (editComponent) {
      return (
        <DangerousDeprecatedOldReactAdapterComponent
          targetId="edit-product-sale-button-mbm"
          weAcknowledgeThisTechniqueIsDangerous
        >
          {editComponent}
        </DangerousDeprecatedOldReactAdapterComponent>
      )
    }
  }

  return (
    <div className="pb-5">
      <div className="mb-8 grid grid-cols-8">
        <h4 className="col-start-1 grid text-base font-semibold">Items</h4>
        <div className="col-start-8 grid">
          <Button
            disabled={isLoading || isError || !editable}
            onClick={() => setIsAdding(true)}
          >
            Add item
          </Button>
        </div>
      </div>
      <ItemsTable
        items={itemsWithStatus}
        isLoading={isLoading}
        isError={isError}
        onViewingItem={onViewingItem}
        onRemovingItem={onRemovingItem}
        onEditingRecurringProductSale={onEditingRecurringProductSale}
        contractQuoteId={contractQuoteId}
        reservationCheckOutDate={reservationCheckOutDate}
        editable={editable}
      />
      {isAdding && (
        <AddItemModal
          ledgerId={ledgerId}
          contractQuoteId={contractQuoteId}
          reservationId={reservationId}
          paymentMethods={paymentMethods}
          onItemAdded={onItemAdded}
          isOpen
          onClose={() => setIsAdding(false)}
          isMonthToMonth={isMonthToMonth}
          hasScheduledInvoice={hasScheduledInvoice}
          reservationCheckInDate={reservationCheckInDate}
          reservationCheckOutDate={reservationCheckOutDate}
          reservationSale={reservationSale}
        />
      )}
      {Boolean(viewingItem) && (
        <ItemDetailsModal
          isOpen
          onClose={onViewItemClose}
          items={viewingItem}
        />
      )}
      {Boolean(removingItem) && (
        <RemoveItemModal
          ledgerId={ledgerId}
          reservationId={reservationId}
          paymentMethodId={defaultPaymentMethodId}
          onItemRemoved={onItemRemoved}
          isOpen
          onClose={onRemoveItemClose}
          item={removingItem}
        />
      )}
      {items?.length > 0 && renderLegacyHeaderProductSaleEditButton()}
      {Boolean(editingRecurringProductSale) && (
        <EditRecurringProductSaleModal
          recurringProductSale={editingRecurringProductSale}
          pricingUrl={pricingUrl}
          monthlyRateOptions={monthlyRateOptions}
          contactBoat={contactBoat}
          onClose={onEditingRecurringProductSaleClose}
          defaultEndDate={recurringProductSaleDefaultEndDate}
        />
      )}
    </div>
  )
}

Items.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      balance: PropTypes.number.isRequired,
    })
  ),
  ledgerId: PropTypes.string.isRequired,
  contractQuoteId: PropTypes.number,
  reservationId: PropTypes.number,
  paymentMethods: PropTypes.shape({
    default: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }).isRequired,
  refreshItems: PropTypes.func.isRequired,
  refreshPayments: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  isMonthToMonth: PropTypes.bool.isRequired,
  hasScheduledInvoice: PropTypes.bool,
  pricingUrl: PropTypes.string.isRequired,
  monthlyRateOptions: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  contactBoat: PropTypes.shape({
    lengthOverall: PropTypes.number.isRequired,
    beam: PropTypes.number.isRequired,
    squareFeet: PropTypes.number.isRequired,
  }),
  ratePreservationEnabled: PropTypes.bool.isRequired,
  hasPreservedRates: PropTypes.bool.isRequired,
  editable: PropTypes.bool,
}

export default Items
