import PropTypes from "prop-types"
import React from "react"
import { Controller } from "react-hook-form"
import { cardDescription } from "src/main/Billing/Items/helpers"

import Form from "src/components/Form"

const PaymentMethodDropdown = ({
  name,
  onlineMethods,
  control,
  registerOptions,
  errors,
  disabled,
}) => {
  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={registerOptions}
        render={({ field: { onChange, value } }) => (
          <Form.Select.Custom
            formLabel="Payment method"
            onChange={onChange}
            value={value}
            hasErrors={Boolean(errors)}
            disabled={disabled}
          >
            {disabled ? (
              <Form.Select.RichOption value="" hideCheck>
                -
              </Form.Select.RichOption>
            ) : null}
            {onlineMethods.map((card) => (
              <Form.Select.RichOption key={card.id} value={card.id} hideCheck>
                {cardDescription({ card })}
              </Form.Select.RichOption>
            ))}
            <Form.Select.RichOption value="manual" hideCheck>
              Manual
            </Form.Select.RichOption>
          </Form.Select.Custom>
        )}
      />
      {errors && <Form.Error>{errors.message}</Form.Error>}
    </>
  )
}

PaymentMethodDropdown.propTypes = {
  name: PropTypes.string.isRequired,
  onlineMethods: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ),
  control: PropTypes.object.isRequired,
  registerOptions: PropTypes.object,
  errors: PropTypes.shape({
    message: PropTypes.string.isRequired,
  }),
  disabled: PropTypes.bool,
}

export default PaymentMethodDropdown
