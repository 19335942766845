import classNames from "classnames"
import PropTypes from "prop-types"
import React, { forwardRef } from "react"

const FormTextarea = forwardRef(
  (
    { disabled, hasErrors, id, name, onChange, placeholder, rows, value },
    ref
  ) => {
    return (
      <textarea
        {...{ disabled, placeholder, rows, onChange, value, name, ref }}
        data-design-system="FormTextarea"
        className={classNames(
          "h-auto w-full rounded border px-2 py-3 outline-none focus:border-blue-600",
          { "border-red-600": hasErrors }
        )}
        id={id}
      />
    )
  }
)

FormTextarea.displayName = "FormTextarea"

FormTextarea.propTypes = {
  disabled: PropTypes.bool,
  hasErrors: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

FormTextarea.defaultProps = {
  rows: 4,
}

export default FormTextarea
