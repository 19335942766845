import PropTypes from "prop-types"
import React, { useState } from "react"

import Button from "src/components/Button"

const alertBannerStyles = {
  info: {
    banner: "border-blue-900",
    icon: "icon-exclamation-circle text-blue-900",
    backgroundColor: "bg-blue-100",
  },
  warning: {
    banner: "border-yellow-900",
    icon: "icon-exclamation-circle text-yellow-900",
    backgroundColor: "bg-yellow-100",
  },
  error: {
    banner: "border-red-900",
    icon: "icon-exclamation-circle text-red-900",
    backgroundColor: "bg-red-100",
  },
  success: {
    banner: "border-teal-900",
    icon: "icon-circle-check text-teal-900",
    backgroundColor: "bg-teal-100",
  },
  neutral: {
    banner: "border-gray-600",
    icon: null,
    backgroundColor: "bg-gray-100",
  },
  announcement: {
    banner: "border-teal-900",
    icon: "icon-megaphone text-teal-900",
    backgroundColor: "bg-teal-100",
  },
}

const AlertBanner = ({ children, closeable, cta, title, type }) => {
  const { banner, icon, backgroundColor } = alertBannerStyles[type]
  const [visible, setVisible] = useState(true)

  const renderCta = () => {
    return (
      <Button
        variant={cta.type}
        onClick={cta.onClick}
        isLoading={cta.isLoading}
      >
        {cta.text}
      </Button>
    )
  }

  if (visible) {
    return (
      <div
        data-design-system="AlertBanner"
        role="alert"
        className={`border-b border-l-4 border-r border-t p-4 ${banner} ${backgroundColor} flex justify-between gap-4 rounded-sm shadow-md`}
      >
        <div className="flex items-center justify-start gap-4">
          {icon && (
            <i
              data-testid="alert-banner-icon"
              className={`icon ${icon} flex items-center justify-center`}
            />
          )}
          <div>
            {title && (
              <h4 className="mb-1 mt-0 text-base font-semibold text-gray-900">
                {title}
              </h4>
            )}
            {children && <div className="text-gray-900">{children}</div>}
          </div>
        </div>
        <div className="flex items-center justify-end gap-4">
          {cta && renderCta()}
          {closeable && (
            <button
              className="icon icon-sf-x bg-transparent px-0 py-1 text-xxs text-gray-600"
              onClick={() => setVisible(false)}
              data-testid="alert-banner-close-x-btn"
            />
          )}
        </div>
      </div>
    )
  }
}

AlertBanner.propTypes = {
  children: PropTypes.node,
  closeable: PropTypes.bool,
  cta: PropTypes.shape({
    type: PropTypes.oneOf(["primary", "secondary"]).isRequired,
    text: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    isLoading: PropTypes.bool,
  }),
  title: PropTypes.string,
  type: PropTypes.oneOf([
    "info",
    "warning",
    "error",
    "success",
    "neutral",
    "announcement",
  ]),
}

AlertBanner.defaultProps = {
  closeable: false,
  type: "warning",
}

export default AlertBanner
