import format from "date-fns/format"
import isToday from "date-fns/isToday"
import isYesterday from "date-fns/isYesterday"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { useQuery, useQueryClient } from "react-query"

import Badge from "src/components/Badge"
import Tooltip from "src/components/Tooltip"

import { queryContractQuoteStatus } from "src/api/Contracts"

import Table from "../../../components/Table"
import { centsToDollars } from "../../Billing/Items/helpers"
import ContractActions from "./ContractActions"
import { ContractGroupContext } from "./index"

const ContractTableRow = ({
  contract,
  contractIds,
  menuDisabled,
  setMenuDisabled,
  selectedRows,
  toggleRowSelection,
  tab,
}) => {
  const { group, currentTab } = useContext(ContractGroupContext)
  const [currentStatus, setCurrentStatus] = useState(contract.status)
  const [overflowingContacts, setOverflowingContacts] = useState([])
  const [overflowingBoats, setOverflowingBoats] = useState([])
  const queryClient = useQueryClient()

  useQuery(
    ["contractQuoteStatus", contract.encodedId, contract.status],
    () =>
      queryContractQuoteStatus({
        groupId: group.encodedId,
        quoteId: contract.encodedId,
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: currentStatus === "Processing",
      refetchInterval: 5000,
      onSuccess: (data) => {
        setCurrentStatus(data.status)
        queryClient.invalidateQueries([
          "contractGroups",
          group.encodedId,
          "contractQuotes",
        ])
      },
    }
  )

  useEffect(() => {
    const contactCells = document.querySelectorAll(".contract-contact")
    const boatCells = document.querySelectorAll(".contract-boat")
    if (contactCells) {
      contactCells.forEach((cell) => {
        if (cell.scrollWidth > cell.clientWidth) {
          setOverflowingContacts([...overflowingContacts, cell.innerHTML])
        }
      })
    }

    if (boatCells) {
      boatCells.forEach((cell) => {
        if (cell.scrollWidth > cell.clientWidth) {
          setOverflowingBoats([...overflowingBoats, cell.innerHTML])
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const contactIsOverflowing = (contact) => {
    return overflowingContacts.includes(contact)
  }

  const boatIsOverflowing = (boat) => {
    return overflowingBoats.includes(boat)
  }

  const dateFormat = () => {
    const date = new Date(contract.statusUpdatedAt)
    if (isToday(date)) {
      return "Today"
    } else if (isYesterday(date)) {
      return "Yesterday"
    } else {
      return format(date, "MM/dd/yyyy")
    }
  }

  const statusToColor = () => {
    switch (currentStatus) {
      case "Unsent":
      case "Sent":
        return "yellow"
      case "Boater Declined":
      case "Voided":
        return "gray"
      case "Failed":
      case "Undeliverable":
      case "Canceled":
        return "red"
      case "Completed In House":
      case "Signed":
        return "teal"
      default:
        return "gray"
    }
  }

  const statusToIcon = () => {
    switch (currentStatus) {
      case "Unsent":
      case "Sent":
        return "contract-open-envelope"
      case "Boater Declined":
      case "Voided":
      case "Failed":
      case "Undeliverable":
      case "Canceled":
        return "contract-circle-x"
      case "Completed In House":
      case "Signed":
        return "contract-circle-check"
    }
  }

  const renderContact = () => (
    <a
      className="inline-flex items-center font-semibold"
      href={contract.contactUrl}
      target="_blank"
      rel="noreferrer"
    >
      <span className="contract-contact w-40 overflow-hidden truncate text-start">
        {contract.customerName}
      </span>
    </a>
  )

  const renderBoat = () => (
    <a
      className="font-semibold"
      href={contract.contactBoatUrl}
      target="_blank"
      rel="noreferrer"
    >
      <span className="contract-boat w-40 overflow-hidden truncate text-start">
        {contract.boatName}
      </span>
    </a>
  )

  const selectableStatus =
    currentStatus !== "Processing" &&
    currentStatus !== "Failed" &&
    currentStatus !== "Undeliverable"

  const selectable =
    currentTab !== "completed" && selectableStatus && contractIds.length > 0

  const renderStatusBadge = () => {
    if (contract.failureReason && contract.status === "Failed") {
      return (
        <div className="w-min">
          <Tooltip
            text={contract.failureReason}
            placement="top"
            variant="dark"
            maxWidth="400px"
          >
            <Badge
              color={statusToColor()}
              text={currentStatus}
              icon={statusToIcon()}
            />
          </Tooltip>
        </div>
      )
    } else {
      return (
        <div>
          <Badge
            color={statusToColor()}
            text={currentStatus}
            icon={statusToIcon()}
          />
        </div>
      )
    }
  }

  return (
    <Table.Row
      key={contract.encodedId}
      selectable={selectable}
      isSelected={selectedRows.includes(contract.encodedId)}
      onSelect={() => toggleRowSelection(contract.encodedId)}
    >
      {!selectableStatus && contractIds.length > 0 ? (
        <Table.Cell title=" " />
      ) : null}
      <Table.Cell>
        <div className="flex flex-col space-y-4 py-4">
          {contactIsOverflowing(contract.customerName) ? (
            <Tooltip
              text={contract.customerName}
              placement="top"
              maxWidth="400px"
              variant="dark"
            >
              {renderContact()}
            </Tooltip>
          ) : (
            renderContact()
          )}
          {boatIsOverflowing(contract.boatName) ? (
            <Tooltip
              text={contract.boatName}
              placement="top"
              maxWidth="400px"
              variant="dark"
            >
              {renderBoat()}
            </Tooltip>
          ) : (
            renderBoat()
          )}
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="flex flex-col space-y-4 py-4">
          <span>{contract.rateText}</span>
          {!contract.monthToMonth && (
            <div>${centsToDollars(contract.total)} total</div>
          )}
        </div>
      </Table.Cell>
      <Table.Cell>
        <div className="flex flex-col space-y-4 py-4">
          {renderStatusBadge()}
          {contract.statusUpdatedAt && <div>{dateFormat()}</div>}
        </div>
      </Table.Cell>
      <Table.Cell align="right">
        <div className="w-min">
          <ContractActions
            statusPending={currentStatus === "Processing"}
            contract={contract}
            menuDisabled={menuDisabled}
            setMenuDisabled={setMenuDisabled}
            tab={tab}
            setCurrentStatus={setCurrentStatus}
          />
        </div>
      </Table.Cell>
    </Table.Row>
  )
}

ContractTableRow.propTypes = {
  contract: PropTypes.shape({
    boatName: PropTypes.string,
    contactBoatUrl: PropTypes.string,
    contactUrl: PropTypes.string,
    customerName: PropTypes.string,
    encodedId: PropTypes.string,
    monthToMonth: PropTypes.bool,
    pending: PropTypes.bool,
    rateText: PropTypes.string,
    status: PropTypes.string,
    failureReason: PropTypes.string,
    statusUpdatedAt: PropTypes.string,
    total: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  contractIds: PropTypes.array,
  menuDisabled: PropTypes.bool,
  setMenuDisabled: PropTypes.func,
  tab: PropTypes.string,
  selectedRows: PropTypes.array,
  toggleRowSelection: PropTypes.func,
}

export default ContractTableRow
