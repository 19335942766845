import Decimal from "decimal.js"
import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useFormContext } from "react-hook-form"

import Divider from "src/components/Divider"
import LoadingOverlay from "src/components/LoadingOverlay"

import { formattedCentsToDollars } from "src/utils/UnitConversion"

import PaymentMethodDropdown from "../../../PaymentMethodDropdown"
import RefundPaymentMethodDropdown from "../../../RefundPaymentMethodDropdown"
import { useGetRefundablePaymentMethods } from "../../../hooks"
import DistributionOptionsSection from "./DistributionOptionsSection"

const LoadingPanel = ({ isLoading, children }) => {
  if (isLoading) {
    return <LoadingOverlay>{children}</LoadingOverlay>
  }
  return children
}
LoadingPanel.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

const PriceChangeSection = ({
  paymentMethods,
  priceDifference,
  reservationId,
  isRefundDue,
  isLoading = false,
}) => {
  const {
    formState: { errors, defaultValues },
    control,
    setValue,
  } = useFormContext()
  const difference = new Decimal(priceDifference)

  // it's possible to have a lower resulting price, but a refund
  // is not due if there haven't been enough payments on the
  // product to need one
  const isLowerPrice = difference.isNeg()

  // Fetching Refund Methods
  const {
    data: { refundablePaymentMethods },
    isFetching: isLoadingRefundablePaymentMethods,
  } = useGetRefundablePaymentMethods({
    reservationId,
    options: {
      select: (data) => {
        return {
          refundablePaymentMethods: data,
          isMultiplePaymentRequired: data.length > 1,
        }
      },
      placeholderData: [],
      enabled: isRefundDue,
    },
  })

  const refundMethods = refundablePaymentMethods.filter(
    (paymentMethod) => paymentMethod.refundable_amount > 0
  )
  const firstRefundMethod = refundMethods[0]?.payment_method_id

  useEffect(() => {
    if (!isLowerPrice) {
      setValue("distributionOption", "custom_due_date")
      setValue("paymentMethodId", defaultValues.paymentMethodId)
      setValue("refundPaymentMethodId", "")
    } else {
      if (isRefundDue) {
        setValue("distributionOption", "custom_due_date")
        setValue("refundPaymentMethodId", firstRefundMethod ?? "")
        setValue("paymentMethodId", "")
      } else {
        setValue("distributionOption", "evenly")
      }
    }
  }, [
    isLowerPrice,
    isRefundDue,
    setValue,
    defaultValues.paymentMethodId,
    firstRefundMethod,
  ])

  return (
    <LoadingPanel isLoading={isLoading}>
      <Divider />
      <div className="relative flex flex-col gap-4">
        <div className="flex flex-row items-center gap-2">
          <i className="icon icon-md-info mb-1 text-lg font-semibold text-yellow-600" />
          <span>
            Edits will result in a{" "}
            {formattedCentsToDollars(difference.toNumber())} price{" "}
            {isLowerPrice ? "decrease" : "increase"}. Select{" "}
            {isRefundDue ? "refund" : "payment"} options below.
          </span>
        </div>
        <div className="flex flex-row gap-4">
          <DistributionOptionsSection
            isLowerPrice={isLowerPrice}
            isRefundDue={isRefundDue}
          />
        </div>
        {!isLowerPrice ? (
          <div className="w-1/2">
            <PaymentMethodDropdown
              name="paymentMethodId"
              onlineMethods={paymentMethods.cards}
              control={control}
              errors={errors.paymentMethodId}
            />
          </div>
        ) : null}
        {isRefundDue && (
          <div className="w-1/2">
            <RefundPaymentMethodDropdown
              isLoading={isLoadingRefundablePaymentMethods}
              id="refund-payment-method"
              control={control}
              name="refundPaymentMethodId"
              errors={errors?.payment_method_id}
              paymentMethods={refundMethods}
            />
          </div>
        )}
      </div>
    </LoadingPanel>
  )
}

PriceChangeSection.propTypes = {
  priceDifference: PropTypes.number.isRequired,
  reservationId: PropTypes.number.isRequired,
  paymentMethods: PropTypes.shape({
    cards: PropTypes.array.isRequired,
    default: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }),
  }).isRequired,
  isLoading: PropTypes.bool,
  isRefundDue: PropTypes.bool.isRequired,
}

export default PriceChangeSection
