import pluralize from "pluralize"
import PropTypes from "prop-types"
import React, { useContext } from "react"

import { formattedCentsToDollars } from "src/utils/UnitConversion"
import { snakeCaseToHumanize } from "src/utils/string_helpers"

import { PAYMENT_METHODS } from "../constants"
import { ContractGroupContext } from "./index"

const Tab = ({ children, tabName }) => (
  <>
    <hr className="mx-6 my-0 hidden border md:block" />
    <div className="flex justify-between p-6">
      <div className="text-base font-semibold">{tabName}</div>
      <div className="flex flex-col items-end space-y-3">
        {children || (tabName !== "Additional Settings" && <span>None</span>)}
      </div>
    </div>
  </>
)

Tab.propTypes = {
  children: PropTypes.node,
  tabName: PropTypes.string.isRequired,
}

export const InstallmentsTab = () => {
  const { group } = useContext(ContractGroupContext)
  const {
    rate: { contractInstallments },
  } = group
  return (
    <Tab tabName="Installments">
      {contractInstallments.length > 0 ? (
        <div className="flex flex-col items-end space-y-3">
          <div className="badge badge-blue">
            {contractInstallments.length}{" "}
            {pluralize("installment", contractInstallments.length)}
          </div>
        </div>
      ) : (
        <span>Not Set</span>
      )}
    </Tab>
  )
}

const PAYMENT_METHOD_DISPLAY = {
  [PAYMENT_METHODS.bank]: "Bank Account",
  [PAYMENT_METHODS.card]: "Cards",
}
export const PaymentMethodTab = () => {
  const { group } = useContext(ContractGroupContext)
  const { acceptedPaymentMethods, allowDirectPayment } = group
  return (
    <Tab tabName="Payment Methods">
      {acceptedPaymentMethods.length > 0 ? (
        <div className="flex flex-col items-end space-y-3">
          <span>
            {acceptedPaymentMethods
              .map((method) => PAYMENT_METHOD_DISPLAY[method])
              .join(", ")}
            {allowDirectPayment ? ", Offline Payment" : null}
          </span>
        </div>
      ) : (
        <span>Not Set</span>
      )}
    </Tab>
  )
}

export const AddItemsTab = () => {
  const { group } = useContext(ContractGroupContext)
  const { posItemsData } = group
  return (
    <Tab tabName="Items">
      {posItemsData && posItemsData.length > 0 && (
        <div className="badge badge-blue">
          {posItemsData.length} {pluralize("Item", posItemsData.length)}
        </div>
      )}
    </Tab>
  )
}

export const DocumentsTab = () => {
  const { attachments, contractSigningUpdatesEnabled, group } =
    useContext(ContractGroupContext)
  return (
    <Tab
      tabName={
        contractSigningUpdatesEnabled && group.withTemplate
          ? "Additional Settings"
          : "Documents"
      }
    >
      {attachments && attachments.length > 0 && (
        <span>
          {attachments.length} {pluralize("document", attachments.length)}
        </span>
      )}
    </Tab>
  )
}

export const TemplatesTab = () => {
  const { group } = useContext(ContractGroupContext)

  return (
    <Tab tabName="Templates">
      {group.docusealTemplateName && <span>{group.docusealTemplateName}</span>}
    </Tab>
  )
}

export const ItineraryTab = () => {
  const { group } = useContext(ContractGroupContext)

  const { billingCycle, startDate, endDate } = group
  return (
    <Tab tabName="Itinerary">
      <div className="flex flex-col items-end space-y-3">
        <div className="space-x-2">
          {billingCycle ? (
            <div className="badge badge-blue">
              {snakeCaseToHumanize(billingCycle)}
            </div>
          ) : null}
        </div>
        {startDate ? (
          <div>
            <span className="text-muted">from</span> {startDate}
            {billingCycle !== "month_to_month" && endDate && (
              <span>
                <span className="text-muted"> to</span> {endDate}
              </span>
            )}
          </div>
        ) : null}
      </div>
    </Tab>
  )
}

export const RateTab = () => {
  const { group, namedRates } = useContext(ContractGroupContext)
  const { rate, namedRateId } = group

  const pricingStructureText = () => {
    if (rate?.pricingStructure === "per_season") {
      return "Per Full Stay"
    } else if (rate?.pricingStructure === "by_foot_per_season") {
      return "By Foot Per Full Stay"
    } else if (rate?.pricingStructure === "sqft_per_season") {
      return "Sqft Per Full Stay"
    } else {
      return snakeCaseToHumanize(rate?.pricingStructure)
    }
  }

  return rate?.pricingStructure ? (
    <Tab tabName="Rate">
      <div className="flex flex-col items-end space-y-3">
        <div>
          {formattedCentsToDollars(rate.amount)}{" "}
          <span className="text-muted">{pricingStructureText()}</span>
        </div>
        {namedRateId && namedRateId !== "custom" && (
          <div>
            <span className="badge badge-blue">
              {namedRates.find((rate) => rate.id === namedRateId).name}
            </span>
          </div>
        )}
      </div>
    </Tab>
  ) : null
}
