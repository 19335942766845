import PropTypes from "prop-types"
import React from "react"
import { useForm } from "react-hook-form"
import AmountInputField from "src/main/Contracts/ContractsForm/shared/AmountInputField"
import AmountTypeSelector from "src/main/Contracts/ContractsForm/shared/AmountTypeSelector"

import Button from "src/components/Button"
import Form from "src/components/Form"
import Modal from "src/components/Modal"
import Table from "src/components/Table"

import { validateDiscountAmount, validateName } from "./validators"

const DiscountsModal = ({
  onSave,
  isOpen,
  onClose,
  selectedDiscount,
  hidePlaceholders = false,
}) => {
  const {
    reset,
    register,
    handleSubmit,
    watch,
    formState: { errors },
    clearErrors,
  } = useForm({
    defaultValues: selectedDiscount ?? {
      name: "",
      amountType: "percent",
      amount: "",
    },
  })
  const selectedAmountType = watch("amountType")

  const onAddClick = (data) => {
    onSave(data)
    onClose()
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      afterLeave={reset}
      size="mediumFixed"
    >
      <Modal.Header>
        <div className="text-lg font-semibold">Add discount</div>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Table autoColumnWidth fullHeight>
            <Table.Head>
              <Table.Head.Row>
                <Table.Head.Cell columnWidth="60%">Name</Table.Head.Cell>
                <Table.Head.Cell>Amount</Table.Head.Cell>
              </Table.Head.Row>
            </Table.Head>
            <Table.Body>
              <Table.Row>
                <Table.Cell>
                  <Form.TextField
                    {...register("name", {
                      validate: validateName,
                    })}
                    placeholder={
                      hidePlaceholders ? "" : "e.g. Pay early discount"
                    }
                    hasErrors={Boolean(errors.name)}
                  />
                </Table.Cell>
                <Table.Cell>
                  <div className="flex flex-row gap-4">
                    <div className="w-4/6">
                      <AmountTypeSelector
                        id="type-selector"
                        registeredField={register("amountType", {
                          onChange: () => {
                            if (errors.amount) {
                              clearErrors("amount")
                            }
                          },
                        })}
                      />
                    </div>
                    <AmountInputField
                      id="amount-field"
                      registeredField={register("amount", {
                        validate: validateDiscountAmount(selectedAmountType),
                      })}
                      amountType={selectedAmountType}
                      hasErrors={Boolean(errors.amount)}
                    />
                  </div>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        {Object.values(errors).map((error) => {
          return (
            <div key={error.message} className="flex justify-end">
              <Form.Error>{error.message}</Form.Error>
            </div>
          )
        })}
        <div className="mt-2 flex flex-row justify-end gap-4">
          <Button variant="tertiary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit(onAddClick)}>
            {selectedDiscount ? "Save" : "Add"}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}

DiscountsModal.propTypes = {
  onSave: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  hidePlaceholders: PropTypes.bool,
  selectedDiscount: PropTypes.shape({
    name: PropTypes.string.isRequired,
    amountType: PropTypes.oneOf(["percent", "dollars"]).isRequired,
    amount: PropTypes.string.isRequired,
  }),
}

export default DiscountsModal
