import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"
import { getPricePerUnitPrecision } from "src/main/Billing/Items/helpers"

import Form from "src/components/Form"

import { validatePrecision } from "../../validators"

const QUANTITY_PRECISION = 5
const TAX_PERCENT_PRECISION = 4
const PERCENTAGE_BASED_PRICE_RATE_PRECISION = 2

const validatePricePerUnit = (precision) => {
  return validatePrecision(getPricePerUnitPrecision(precision))
}

const validatePercentageBasedPriceRate = (value) => {
  if (value > 100 || value <= 0) {
    return "Percentage must be greater than 0 and less than 100."
  } else {
    return validatePrecision(PERCENTAGE_BASED_PRICE_RATE_PRECISION)(value)
  }
}

const validateQuantity = (quantity) => {
  const numericQuantity = Number(quantity)
  if (numericQuantity <= 0) {
    return "Quantity must be greater than 0."
  } else if (numericQuantity >= 1000000) {
    return "Quantity must be less than 1,000,000."
  }
  return validatePrecision(QUANTITY_PRECISION)(quantity)
}

const validateTaxPercent = (value) => {
  if (value > 100 || value < 0) {
    return "Tax must be between 0 and 100."
  } else {
    return validatePrecision(TAX_PERCENT_PRECISION)(value)
  }
}

const renderUnitPrice = (errors, precision, register, priceType) => {
  const label = priceType === "per_day" ? "Price per day" : "Unit price"
  return (
    <div className="flex flex-col">
      <Form.Label htmlFor="price-per-unit">{label}</Form.Label>
      <Form.IconTextField
        id="price-per-unit"
        position="left"
        icon="$"
        {...register("txn.product_sale_attributes.price_per_unit", {
          required: `${label} is required.`,
          validate: validatePricePerUnit(precision),
        })}
        type="number"
        hasErrors={Boolean(errors.txn?.product_sale_attributes?.price_per_unit)}
      />
      {errors.txn?.product_sale_attributes?.price_per_unit && (
        <Form.Error>
          {errors.txn.product_sale_attributes.price_per_unit.message}
        </Form.Error>
      )}
    </div>
  )
}

const renderQuantityAsPercentCharge = (errors, precision, register) => {
  return (
    <div className="flex w-6/12 flex-col">
      <Form.Label htmlFor="quantity">Price (percent of storage)</Form.Label>
      <Form.IconTextField
        id="quantity"
        position="right"
        icon="%"
        {...register("txn.product_sale_attributes.quantity", {
          required: "Price is required.",
          validate: validatePercentageBasedPriceRate,
        })}
        type="number"
        hasErrors={Boolean(errors.txn?.product_sale_attributes?.quantity)}
      />
      {errors.txn?.product_sale_attributes?.quantity && (
        <Form.Error>
          {errors.txn.product_sale_attributes.quantity.message}
        </Form.Error>
      )}
    </div>
  )
}

const renderQuantity = (errors, register) => {
  return (
    <div className="flex flex-col">
      <Form.Label htmlFor="quantity">Quantity</Form.Label>
      <Form.TextField
        id="quantity"
        position="left"
        {...register("txn.product_sale_attributes.quantity", {
          required: "Quantity is required.",
          validate: validateQuantity,
        })}
        type="number"
        hasErrors={Boolean(errors.txn?.product_sale_attributes?.quantity)}
      />
      {errors.txn?.product_sale_attributes?.quantity && (
        <Form.Error>
          {errors.txn.product_sale_attributes.quantity.message}
        </Form.Error>
      )}
    </div>
  )
}

const renderTax = (errors, register, isPercentOfReservationSale) => {
  return (
    <div
      className={classNames("flex flex-col", {
        "w-6/12": isPercentOfReservationSale,
      })}
    >
      <Form.Label htmlFor="tax-amount">Tax</Form.Label>
      <Form.IconTextField
        id="tax-amount"
        position="right"
        icon="%"
        {...register("txn.product_sale_attributes.tax_percent", {
          required: "Tax is required.",
          validate: validateTaxPercent,
        })}
        type="number"
        hasErrors={Boolean(errors.txn?.product_sale_attributes?.tax_percent)}
      />
      {errors.txn?.product_sale_attributes?.tax_percent && (
        <Form.Error>
          {errors.txn.product_sale_attributes.tax_percent.message}
        </Form.Error>
      )}
    </div>
  )
}

const renderDateType = (errors, register) => {
  return (
    <div className="flex w-5/12 flex-col">
      <Form.Label htmlFor="date-type">Dates</Form.Label>
      <Form.Select
        id="date-type"
        {...register("dateType")}
        hasErrors={Boolean(errors?.dateType)}
      >
        <option key="wholeReservation" value="wholeReservation">
          Whole reservation
        </option>
        <option key="custom" value="custom">
          Custom
        </option>
      </Form.Select>
    </div>
  )
}

export const ItemPricingFields = ({
  errors,
  precision,
  register,
  showQuantity,
  priceType,
}) => {
  const isPerDay = priceType === "per_day"
  const isPercentOfReservationSale = priceType === "percent_of_reservation_sale"

  return (
    <div className={`mt-5 flex flex-row gap-4`}>
      {isPercentOfReservationSale
        ? renderQuantityAsPercentCharge(errors, precision, register)
        : renderUnitPrice(errors, precision, register, priceType)}
      {showQuantity && renderQuantity(errors, register)}
      {renderTax(errors, register, isPercentOfReservationSale)}
      {isPerDay ? renderDateType(errors, register) : null}
    </div>
  )
}

ItemPricingFields.propTypes = {
  errors: PropTypes.object,
  precision: PropTypes.string,
  priceType: PropTypes.oneOf([
    "per_day",
    "per_quantity",
    "percent_of_reservation_sale",
  ]),
  register: PropTypes.func.isRequired,
  showQuantity: PropTypes.bool.isRequired,
}

export default ItemPricingFields
