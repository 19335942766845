import PropTypes from "prop-types"
import React, { useContext } from "react"

import BlankStateCard from "src/components/BlankStateCard"

import { TemplatesContext } from "../TemplatesTab"
import { ContractTemplateBlankState } from "./BlankStateImages"

const TemplatesBlankStateCard = ({ activeStatus }) => {
  const { setIsModalOpen } = useContext(TemplatesContext)

  const renderBody = () => {
    if (activeStatus) {
      return (
        <>
          <div className="flex items-center space-x-1">
            <button
              type="button"
              className="text-link font-semibold"
              onClick={() => setIsModalOpen(true)}
            >
              Create a new template
            </button>{" "}
            <span>to upload your documents</span>
          </div>
          <span>and designate areas for signatures</span>
        </>
      )
    } else {
      return (
        <div className="flex flex-col items-center space-x-1">
          <span>
            Archiving simply moves the template out of the active view.
          </span>
          <span>
            If they are connected to contact groups, they will continue to
            function as usual.
          </span>
        </div>
      )
    }
  }

  return (
    <div className="flex h-112 items-center justify-center pt-2">
      <BlankStateCard
        image={<ContractTemplateBlankState />}
        heading={`No templates ${activeStatus ? "created" : "archived"} yet`}
      >
        <div className="text-muted flex flex-col">{renderBody()}</div>
      </BlankStateCard>
    </div>
  )
}

TemplatesBlankStateCard.propTypes = {
  activeStatus: PropTypes.bool.isRequired,
}

export default TemplatesBlankStateCard
