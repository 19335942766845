import Decimal from "decimal.js"

export const formatIncomingDiscountData = ({ name, amountType, amount }) => {
  if (amountType === "percent") {
    return {
      name,
      percentage: new Decimal(amount).div(100).toString(),
    }
  }
  return {
    name,
    amount: new Decimal(amount).mul(100).toString(),
  }
}

export const parseSelectedDiscount = (discount) => {
  if (!discount) {
    return
  }
  const { name, percentage, amount } = discount
  if (percentage) {
    return {
      name,
      amountType: "percent",
      amount: new Decimal(percentage).mul(100).toString(),
    }
  }
  return {
    name,
    amountType: "dollars",
    amount: new Decimal(amount).div(100).toString(),
  }
}

export const parseSavedDiscount = (discount) => {
  const { name, percent_amount: percentAmount, amount } = discount
  if (percentAmount) {
    return {
      name,
      percentage: percentAmount,
    }
  }
  return {
    name,
    amount: amount,
  }
}

export const getAmountDisplay = (field) => {
  const percentage = field.percentage
  if (percentage) {
    return `${new Decimal(percentage).mul(100).toString()}%`
  }
  return `$${new Decimal(field.amount).div(100).toFixed(2)}`
}
